<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Asesor Academico</h4>
          <BackButton text="Listar Asesores Academicos" />
        </div>
        <div class="card-body">
          <div
            v-if="empleado && empleado.persona"
            class="empleado-details-container"
          >
            <div class="row">
              <div class="col">
                <p>
                  <strong>Nombres:</strong>
                  {{ empleado.persona.nombres_completos_persona }}
                </p>
                <p><strong>Area:</strong> {{ empleado.area_empleados }}</p>
                <p><strong>Cargo:</strong> {{ empleado.cargo }}</p>
                <p>
                  <strong>Codigo Empleado:</strong>
                  {{ empleado.codigo_empleado + formatCode(empleado.id) }}
                </p>
                <p>
                  <strong>Celular:</strong>
                  {{ empleado.persona.celular_persona }}
                </p>
                <p>
                  <strong>Correo:</strong> {{ empleado.persona.email_persona }}
                </p>
                <p>
                  <strong>N° de Cuenta:</strong>
                  {{ empleado.numero_cuenta_bancaria_empleado }}
                </p>
                <p>
                  <strong>Sistema de Pension:</strong>
                  {{ empleado.sistema_pensiones_empleado }}
                </p>
                <p><strong>AFP:</strong> {{ empleado.afp_empleado }}</p>
                <p>
                  <strong>Departamento:</strong>
                  {{ empleado.persona.departamento_persona }}
                </p>
                <p>
                  <strong>Provincia:</strong>
                  {{ empleado.persona.provincia_persona }}
                </p>
              </div>
              <div class="col">
                <p>
                  <strong>Distrito:</strong>
                  {{ empleado.persona.distrito_persona }}
                </p>
                <p>
                  <strong>Dirección:</strong>
                  {{ empleado.persona.direccion_persona }}
                </p>
                <p>
                  <strong>Centro de Estudios:</strong>
                  {{ empleado.centro_estudios_empleado }}
                </p>
                <p>
                  <strong>Especialidad:</strong>
                  {{ empleado.profesion_empleado }}
                </p>
                <p>
                  <strong>Inicio de Contrato:</strong>
                  {{ empleado.inicio_contrato_empleado }}
                </p>
                <p>
                  <strong>Fin de Contrato:</strong>
                  {{ empleado.fin_contrato_empleado }}
                </p>
                <p>
                  <strong>Jornada Laboral:</strong>
                  {{ empleado.jornada_laboral_empleado }}
                </p>
                <p>
                  <strong>Modalidad de Contrato:</strong>
                  {{ empleado.modalidad_contrato_empleado }}
                </p>
                <p><strong>Salario:</strong> {{ empleado.salario }}</p>
                <p><strong>Status:</strong> {{ empleado.estado_emplreado }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
              ref="itemtabla"
            >
              <caption>
                Tabla de Pendientes
              </caption>

              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Codigo Tarea</th>
                  <th scope="col">Tarea</th>
                  <th scope="col">Estado de Tarea</th>
                  <th scope="col">Fecha Programada</th>
                  <th scope="col">Rol</th>
                  <th scope="col">Proyecto</th>
                  <th scope="col">Codigo Proyecto</th>
                  <th scope="col">Descuento</th>
                  <!-- Nueva columna para el descuento -->
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="tarea in tareas" :key="tarea.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="
                          verDetalle(tarea.empleados_proyectos.proyecto.id)
                        "
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    <router-link
                      v-if="tarea.id"
                      :to="`/proyectos/${tarea.empleados_proyectos.proyecto.id}`"
                    >
                      {{ formatCodeTarea(tarea.id) }}
                    </router-link>
                    <span v-else>Sin tarea</span>
                  </td>
                  <td>{{ tarea.asignacion_tareas_proyectos }}</td>
                  <td>{{ tarea.estado_tareas_proyecto }}</td>
                  <td>{{ tarea.fecha_fin_programado }}</td>
                  <td>{{ tarea.empleados_proyectos.rol }}</td>
                  <td>
                    {{
                      tarea.empleados_proyectos.proyecto.titulo_investigacion
                    }}
                  </td>
                  <td>
                    <router-link
                      v-if="tarea.id"
                      :to="`/proyectos/${tarea.empleados_proyectos.proyecto.id}`"
                    >
                      {{
                        formatCodeProyecto(
                          tarea.empleados_proyectos.proyecto.id
                        )
                      }}
                    </router-link>
                    <span v-else>Sin Proyecto</span>
                  </td>
                  <td>
                    {{ getDescuento(tarea) }}
                    <!-- Muestra el descuento -->
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="card-body">

          <!-- Tabla de resultados -->
          <div class="table-responsive">
            <caption>
              Tabla de Rendimiento
            </caption>
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
            >
              <thead>
                <tr>
                  <th>Título de Investigación</th>
                  <th>Nombre Grupal</th>
                  <th>Asignación Tareas Proyectos</th>
                  <th>Equivalente</th>
                  <th>Descuento (%)</th>
                  <th>Sumatoria Equivalente</th>
                  <th>Sumatoria Descuento</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="proyecto in proyectosFiltrados" :key="proyecto.id">
                  <td>{{ proyecto.titulo_investigacion }}</td>
                  <td>{{ proyecto.nombre_grupal }}</td>

                  <!-- Asignación de Tareas -->
                  <td>
                    <ul>
                      <li
                        v-for="tarea in proyecto.empleadoProyecto[0]
                          .tareaProyecto"
                        :key="tarea.id"
                      >
                        {{ tarea.asignacion_tareas_proyectos }}
                      </li>
                    </ul>
                  </td>

                  <!-- Valor Final -->
                  <td>
                    <ul>
                      <li
                        v-for="tarea in proyecto.empleadoProyecto[0]
                          .tareaProyecto"
                        :key="tarea.id"
                      >
                        {{ tarea.valor_final !== null ? tarea.valor_final : 0 }}
                      </li>
                    </ul>
                  </td>

                  <!-- Descuento (%) -->
                  <td>
                    <ul>
                      <li
                        v-for="tarea in proyecto.empleadoProyecto[0]
                          .tareaProyecto"
                        :key="tarea.id"
                      >
                        {{
                          tarea.rendimiento_trabajador.length > 0
                            ? (
                                tarea.rendimiento_trabajador[0].descuento * 100
                              ).toFixed(2)
                            : 0
                        }}%
                      </li>
                    </ul>
                  </td>

                  <!-- Sumatoria de Valor Final para cada fila de proyecto -->
                  <td>{{ calcularSumatoriaValorFinal(proyecto) }}%</td>

                  <!-- Sumatoria de Descuento (%) para cada fila de proyecto -->
                  <td>{{ calcularSumatoriaDescuento(proyecto) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpleadoProyectoService from "../../services/EmpleadoProyectoService";
import EmpleadoService from "../../services/EmpleadoService";
import RendimientoTrabajadoresService from "../../services/RendimientoTrabajadoresService";
import TareaProyectoService from "../../services/TareaProyectoService";
import BackButton from "./BackButton.vue";
import $ from "jquery";
import Swal from "sweetalert2";
import ProyectoService from "../../services/ProyectoService";

export default {
  name: "Empleado",
  components: {
    BackButton,
  },
  data() {
    return {
      nombre: "Empleado",
      cargando: false,
      empleado: null,
      tareas: [],
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      empleadoProyectos: [],
      filteredEmpleadoProyectos: [],
      rendimientosTrabajadores: [],
      proyectosFiltrados: [],
      proyectos: [],
      searchQuery: "",
    };
  },
  computed: {
    proyectosConTareas() {
      return this.proyectosFiltrados.filter(
        (proyecto) =>
          proyecto.empleadoProyecto &&
          proyecto.empleadoProyecto[0] &&
          proyecto.empleadoProyecto[0].tareaProyecto
      );
    },
  },

  created() {
    const user = JSON.parse(localStorage.getItem("user"));

    // Verificar si el usuario es Administrador o el ID coincide
    if (
      user &&
      (user.empleado.area_empleados === "Administrador" ||
        user.empleado.cargo === "Jefe de Investigación" ||
        this.$route.params.id == user.empleado.id)
    ) {
      this.fetchEmpleadoProyectos();
      this.fetchRendimientoTrabajador();
      this.fetchDetailsEmpleado();
      this.fetchTareas();
      this.filtrandoProyectos();
    } else {
      // Redirigir a otro componente si no cumple la condición
      this.$router.push({ name: "AreaAcademica" });
    }
  },

  methods: {
    async fetchDetailsEmpleado() {
      try {
        const empleadoId = this.$route.params.id;
        this.cargando = true;
        const response = await EmpleadoService.getEmpleado(empleadoId);
        this.empleado = response.data;
      } catch (error) {
        console.error("Error fetching empleados:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Empleados.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchRendimientoTrabajador() {
      try {
        const id_empleado = this.$route.params.id;
        const responseRendimiento =
          await RendimientoTrabajadoresService.getRendimientoTrabajadores();

        this.rendimientos = responseRendimiento.data.data.filter(
          (rendimiento) => {
            // Verifica que las propiedades existen antes de acceder a ellas
            return (
              rendimiento.tareaProyecto &&
              rendimiento.tareaProyecto.empleados_proyectos &&
              rendimiento.tareaProyecto.empleados_proyectos.id_empleados ==
                id_empleado
            );
          }
        );
      } catch (error) {
        console.log("error en cargar rendimientos", error);
      }
    },

    async fetchTareas() {
      const id_asesor = this.$route.params.id;
      const responseTareas = await TareaProyectoService.getTareaProyectos();

      // Asegúrate de que responseTareas.data.data existe y es un array
      if (Array.isArray(responseTareas.data.data)) {
        this.tareas = responseTareas.data.data.filter(
          (tareas) => tareas.empleados_proyectos.id_empleados == id_asesor // Verifica que empleado no sea null
        );

        this.$nextTick(() => {
          this.initializeDataTable(); // Inicializa la tabla después de que las cotizaciones se han actualizado
        });
      } else {
        console.error(
          "No se encontraron tareas o la estructura de datos es incorrecta."
        );
      }
    },
    async fetchEmpleadoProyectos() {
      try {
        const id_asesor = this.$route.params.id;
        const empleadoProyectoResponse =
          await EmpleadoProyectoService.getEmpleadosProyectos();

        this.filteredEmpleadoProyectos =
          empleadoProyectoResponse.data.data.filter(
            (empleadoProyecto) => empleadoProyecto.id_empleados == id_asesor
          );
      } catch (error) {
        console.error("Error fetching empleados proyecto:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los empleados de proyecto.",
        });
      }
    },
    initializeDataTable() {
      if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
        $(this.$refs.itemtabla).DataTable().destroy();
      }
      this.dataTable = $(this.$refs.itemtabla).DataTable({
        order: [[0, "desc"]],
        pageLength: 20, // Mostrar 20 filas por página
      });
    },
    refreshData() {
      this.$router.go();
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    closeModal() {
      this.showCreateModal = false;
      this.showEditModal = false;
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          EmpleadoService.deleteEmpleado($id);
          this.refreshData();
        }
      });
    },
    getDescuento(tarea) {
      const rendimiento = this.rendimientosTrabajadores.find(
        (rendimiento) =>
          rendimiento.id_empleados === tarea.empleados_proyectos.id_empleados &&
          rendimiento.id_tareas_proyectos === tarea.id
      );

      if (rendimiento) {
        // Retorna el descuento formateado como porcentaje
        return this.formatDescuento(parseFloat(rendimiento.descuento));
      }
      return "Sin descuento"; // Si no hay rendimiento, retorna este texto
    },
    formatCode(code) {
      const formattedCode = code.toString().padStart(6, "0");
      return `${formattedCode}`;
    },

    formatDescuento(descuento) {
      if (descuento) {
        return `${(descuento * 100).toFixed(0)}%`;
      }
      return "Sin descuento";
    },
    formatCodeTarea(codeTarea) {
      const formattedCode = codeTarea.toString().padStart(6, "0");
      return `TAR${formattedCode}`;
    },
    formatCodeProyecto(codeProyecto) {
      const formattedCode = codeProyecto.toString().padStart(6, "0");
      return `PROY${formattedCode}`;
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima", // Especifica la zona horaria de Lima
      };
      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },
    verDetalle(id) {
      this.$router.push({
        name: "DetallesProyecto",
        params: { id },
      });
    },
    async filtrandoProyectos() {
      try {
        const id_asesor = this.$route.params.id;
        const proyectos = await ProyectoService.getProyectos();
        console.log("Proyectos: ", proyectos);

        const filtrandoProyecto = proyectos.data.data
          .map((proyecto) => {
            const empleadoFiltrado = proyecto.empleadoProyecto.find(
              (empleado) => empleado.id_empleados == id_asesor
            );

            return empleadoFiltrado
              ? { ...proyecto, empleadoProyecto: [empleadoFiltrado] }
              : null;
          })
          .filter((proyecto) => proyecto !== null);
        this.proyectosFiltrados = filtrandoProyecto;
        console.log(this.proyectosFiltrados);
      } catch (error) {
        console.log(error);
      }
    },
    calcularSumatoriaValorFinal(proyecto) {
      return proyecto.empleadoProyecto[0].tareaProyecto.reduce(
        (subtotal, tarea) => {
          return subtotal + (Number(tarea.valor_final) || 0);
        },
        0
      );
    },

    // Calcula la sumatoria de 'Descuento (%)' para un proyecto específico
    calcularSumatoriaDescuento(proyecto) {
      return proyecto.empleadoProyecto[0].tareaProyecto
        .reduce((subtotal, tarea) => {
          const descuento =
            tarea.rendimiento_trabajador.length > 0
              ? tarea.rendimiento_trabajador[0].descuento * 100
              : 0;
          return subtotal + descuento;
        }, 0)
        .toFixed(2);
    },
  },
};
</script>


<style scoped>
.tabla-tamano {
  font-size: 0.9rem; /* Ajusta el tamaño de fuente según sea necesario */
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
