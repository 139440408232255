<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Ventas</h4>
        </div>
        <!-- Listado -->
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
              ref="itemtabla"
            >
              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Precio Total</th>
                  <th scope="col">Número de Cuotas</th>
                  <th scope="col">Plazo</th>
                  <th scope="col">Servicio</th>
                  <th scope="col">Asesor de Venta</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <template v-if="ventas && ventas.length > 0">
                  <tr v-for="venta in ventas" :key="venta.id">
                    <td>
                      <div
                        class="btn-group"
                        role="group"
                        aria-label="botones-crud"
                      >
                        <button
                          @click="verDetalle(venta.id)"
                          class="btn btn-success"
                        >
                          <i class="far fa-eye" aria-hidden="true"></i>
                        </button>
                        <button
                          @click="confirmarEliminacion(venta.id)"
                          class="btn btn-danger"
                        >
                          <i class="far fa-trash-alt" aria-hidden="true"></i>
                        </button>
                      </div>
                    </td>
                    <td>{{ formatCodeVenta(venta.id) }}</td>
                    <td>
                      {{
                        venta.cliente?.persona?.nombres_completos_persona || "-"
                      }}
                    </td>
                    <td>{{ "S/." + venta.cotizacion.precio_asignado }}</td>
                    <td>{{ venta.cotizacion.numero_cuotas }}</td>
                    <td>{{ venta.cotizacion.plazo }}</td>
                    <td>{{ venta.cotizacion.servicio }}</td>
                    <td>
                      {{ venta.cotizacion?.empleado?.persona.nombres_completos_persona || "-" }}
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="8" class="text-center">
                      No hay ventas para mostrar
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

  
  <script>
import VentasService from "../../services/VentasService";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "Venta",
  data() {
    return {
      cargando: false,
      ventas: [], // Inicializar ventas
      selectedVenta: {},
      newVenta: {},
    };
  },
  created() {
    this.fetchVentas();
  },
  methods: {
    async fetchVentas() {
      try {
        this.cargando = true;

        // Obtener el usuario del localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // Llamar al servicio para obtener las ventas
        const response = await VentasService.getVentas();

        // Verificar si el usuario es "Jefa Comercial" o "Administrador"
        if (
          user.empleado.cargo === "Jefa Comercial" ||
          user.empleado.area_empleados === "Administrador"
        ) {
          // Si es "Jefa Comercial" o "Administrador", cargar todas las ventas
          this.ventas = response.data.data;
        } else {
          // Si no, filtrar las ventas por id_empleado
          this.ventas = response.data.data.filter(
            (venta) => venta.cotizacion.id_empleados === user.empleado.id
          );
        }

        // Inicializar DataTable después de que Vue haya actualizado el DOM
        this.$nextTick(() => {
          const table = $(this.$refs.itemtabla);

          // Verificar si ya existe una instancia de DataTable y destruirla si es así
          if ($.fn.dataTable.isDataTable(table)) {
            table.DataTable().destroy();
          }

          // Solo inicializa DataTables si hay datos
          if (this.ventas.length > 0) {
            table.DataTable({
              order: [[0, "desc"]],
              pageLength: 20,
            });
          }
        });
      } catch (error) {
        console.error("Error fetching ventas:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las Ventas.",
        });
      } finally {
        this.cargando = false;
      }
    },

    formatCodeVenta(codeVenta) {
      const formattedCode = codeVenta.toString().padStart(6, "0");
      return `VENT${formattedCode}`;
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesVentas", params: { id } });
    },
    confirmarEliminacion(id) {
      Swal.fire({
        title: "¿Estás seguro de eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          VentasService.deleteVenta(id);
          this.refreshData();
        }
      });
    },
    refreshData() {
      this.$router.go();
    },
  },
};
</script>
  
  <style lang="">
</style>
  