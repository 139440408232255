<template>
  <p :class="getBackgroundClass(text)">
    {{ text }}
  </p>
</template>
  
  <script>
export default {
  name: "ColoredText",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    getBackgroundClass(text) {
      switch (text?.trim()) {
        case "Sin iniciar":
          return "bg-iniciar";
        case "En proceso":
          return "bg-proceso";
        case "Observado":
          return "bg-observado";
        case "Terminado":
          return "bg-terminado";
        default:
          return "bg-default";
      }
    },
  },
};
</script>
  
  <style scoped>
.bg-iniciar {
  background-color: #ffd1d1;
}
.bg-proceso {
  background-color: #b2e0b2;
}
.bg-observado {
  background-color: #d1e0ff;
}
.bg-terminado {
  background-color: #fff4b2;
}
</style>

  