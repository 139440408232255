import apiClient from '../axios';

export default {

 getProyectos(){
    return apiClient.get('/proyectos')
 },

async getProyecto(id) {
   return apiClient.get(`/proyectos/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createProyecto(proyectoData) {
   return apiClient.post('/proyectos', proyectoData);
},

//Editar
updateProyecto(id, proyectoData) {
   return apiClient.patch(`/proyectos/${id}`, proyectoData);
},
//eliminar
deleteProyecto(id) {
   return apiClient.get(`/proyectos/${id}/eliminar`);
}
};