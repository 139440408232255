<template>
  <div>
    <div class="cointainer">
      <div class="card">
        <div class="card-header">
          <h4>Proyectos</h4>
        </div>

        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano"
              ref="itemtabla"
            >
              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Nombre Grupal</th>
                  <th scope="col">Título de Investigación</th>
                  <th scope="col">Fecha Recepción Contrato</th>
                  <th scope="col">Estado Proyecto</th>
                  <th scope="col">Asesor</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="proyecto in proyectos" :key="proyecto.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(proyecto.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditModal(proyecto)"
                        class="btn btn-info"
                        v-if="isUserAuthorized"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(proyecto.id)"
                        class="btn btn-danger"
                        v-if="isUserAuthorized"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ formatCodeProyecto(proyecto.id) }}</td>
                  <td>{{ proyecto.nombre_grupal }}</td>
                  <td>{{ proyecto.titulo_investigacion }}</td>
                  <td>{{ formatDate(proyecto.fecha_recepcion_contrato) }}</td>
                  <td>{{ proyecto.estado_proyecto }}</td>
                  <td>
                    <span v-if="proyecto.asesor">
                      {{
                        proyecto.asesor.empleado.persona
                          .nombres_completos_persona
                      }}
                    </span>
                    <span v-else> Sin Asesor </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <!-- Modal para editar proyecto -->
        <Modal :isVisible="showEditProyectoModal" @close="closeModal">
          <div>
            <h4>Modificar Datos del Proyecto</h4>
            <form @submit.prevent="updateProyecto">
              <div class="row">
                <div class="col">
                  <!-- Nombre Grupal -->
                  <div>
                    <label for="nombre_grupal" class="form-label"
                      >Nombre Grupal:</label
                    >
                    <input
                      v-model="selectedProyecto.nombre_grupal"
                      type="text"
                      id="nombre_grupal"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Título de Investigación -->
                  <div>
                    <label for="titulo_investigacion" class="form-label"
                      >Título de Investigación:</label
                    >
                    <input
                      v-model="selectedProyecto.titulo_investigacion"
                      type="text"
                      id="titulo_investigacion"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Fecha de Recepción del Contrato -->
                  <div>
                    <label for="fecha_recepcion_contrato" class="form-label"
                      >Fecha de Recepción del Contrato:</label
                    >
                    <input
                      v-model="selectedProyecto.fecha_recepcion_contrato"
                      type="date"
                      id="fecha_recepcion_contrato"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Estado del Proyecto -->
                  <div>
                    <label for="estado_proyecto" class="form-label"
                      >Estado del Proyecto:</label
                    >
                    <select
                      v-model="selectedProyecto.estado_proyecto"
                      id="estado_proyecto"
                      class="form-control"
                      required
                    >
                      <option value="">Seleccione una opción:</option>
                      <option value="Observado">Observado</option>
                      <option value="Espera de pre">Espera de pre</option>
                      <option value="Elaboracion de Temas">
                        Elaboración de Temas
                      </option>
                      <option value="Espera de Eleccion de Titulo">
                        Espera de Elección de Título
                      </option>
                      <option value="Iniciado">Iniciado</option>
                      <option value="Terminado">Terminado</option>
                      <option value="Desistido">Desistido</option>
                    </select>
                  </div>

                  <!-- Botón para actualizar -->
                  <button type="submit" class="btn btn-primary mt-3">
                    Actualizar Proyecto
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>

<script>
import ProyectoService from "../../services/ProyectoService";
import $ from "jquery";
import Modal from "./Modal.vue";
import Swal from "sweetalert2";
import EmpleadoProyectoService from "../../services/EmpleadoProyectoService";

export default {
  name: "Proyecto",
  components: {
    Modal,
  },
  data() {
    return {
      showEditProyectoModal: false, // Controla la visibilidad del modal de edición
      selectedProyecto: {
        nombre_grupal: "",
        titulo_investigacion: "",
        fecha_recepcion_contrato: "",
        estado_proyecto: "",
      },
      proyectos: [],
      asesorTarea: [],
    };
  },
  computed: {
    ultimoAsesor() {
      if (this.asesorTarea.length > 0) {
        return this.asesorTarea[this.asesorTarea.length - 1]; // Devuelve el último asesor
      }
      return null; // Devuelve null si no hay asesores
    },
    isUserAuthorized() {
      const user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        (user.empleado.area_empleados === "Administrador" ||
          user.empleado.cargo === "Jefe de Investigación")
      );
    },
  },
  created() {
    this.fetchProyectos();
  },
  methods: {
    openEditModal(proyecto) {
      this.selectedProyecto = { ...proyecto }; // Copia los datos del proyecto seleccionado
      this.showEditProyectoModal = true; // Muestra el modal de edición
    },

    closeModal() {
      this.showEditProyectoModal = false; // Cierra el modal de edición
      this.resetProyecto(); // Limpia los datos del formulario
    },

    resetProyecto() {
      this.selectedProyecto = {
        nombre_grupal: "",
        titulo_investigacion: "",
        fecha_recepcion_contrato: "",
        estado_proyecto: "",
      };
    },

    async updateProyecto() {
      try {
        // Suponiendo que tienes un servicio para actualizar el proyecto
        await ProyectoService.updateProyecto(
          this.selectedProyecto.id,
          this.selectedProyecto
        );
        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "El proyecto ha sido actualizado correctamente.",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error actualizando proyecto:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar el proyecto.",
        });
      }
    },
    refreshData() {
      this.$router.go();
    },

    async fetchProyectos() {
      try {
        const user = JSON.parse(localStorage.getItem("user"));

        // Obtener empleados asociados a los proyectos
        const responseEmpleado =
          await EmpleadoProyectoService.getEmpleadosProyectos();
        console.log("Empleados del proyecto:", responseEmpleado.data.data);

        // Verificar si el usuario tiene acceso completo
        if (
          user &&
          (user.empleado.area_empleados === "Administrador" ||
            user.empleado.cargo === "Jefe de Investigación")
        ) {
          // Obtener proyectos
          const response = await ProyectoService.getProyectos();
          this.proyectos = response.data.data; // Asegúrate de que 'this.proyectos' es un array

          // Asegurarse de que 'this.proyectos' es un array antes de iterar
          if (Array.isArray(this.proyectos)) {
            // Iteramos sobre cada proyecto para buscar los asesores asociados
            this.proyectos.forEach((proyecto) => {
              const asesoresProyecto = responseEmpleado.data.data.filter(
                (asesor) =>
                  asesor.id_proyectos === proyecto.id && // Comparamos con el id del proyecto actual
                  asesor.rol.trim().toLowerCase() === "asesor" // Limpiamos espacios y hacemos case insensitive
              );

              // Almacenar solo el asesor con el mayor id
              if (asesoresProyecto.length > 0) {
                proyecto.asesor = asesoresProyecto.reduce((max, asesor) => {
                  return (max.id || 0) < asesor.id ? asesor : max;
                }, {});
              } else {
                proyecto.asesor = null; // No hay asesores
              }
            });

            console.log("Proyectos con asesores:", this.proyectos);
          } else {
            console.error("Error: 'this.proyectos' no es un array.");
          }
        } else {
          // Filtrar proyectos para usuarios sin acceso completo
          const proyectosFiltrados = responseEmpleado.data.data
            .filter((asesor) => asesor.id_empleados === user.empleado.id)
            .map((asesor) => asesor.id_proyectos);

          // Obtener solo los proyectos filtrados
          const response = await ProyectoService.getProyectos();
          this.proyectos = response.data.data.filter((proyecto) =>
            proyectosFiltrados.includes(proyecto.id)
          );

          // Asignar asesores a los proyectos filtrados
          this.proyectos.forEach((proyecto) => {
            const asesoresProyecto = responseEmpleado.data.data.filter(
              (asesor) =>
                asesor.id_proyectos === proyecto.id &&
                asesor.rol.trim().toLowerCase() === "asesor"
            );

            if (asesoresProyecto.length > 0) {
              proyecto.asesor = asesoresProyecto.reduce((max, asesor) => {
                return (max.id || 0) < asesor.id ? asesor : max;
              }, {});
            } else {
              proyecto.asesor = null; // No hay asesores
            }
          });

          console.log("Proyectos filtrados:", this.proyectos);
        }

        // Inicializar DataTable después de cargar y procesar los datos
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          $(this.$refs.itemtabla).DataTable();
        });
      } catch (error) {
        console.error(
          "Error fetching proyectos o empleados del proyecto:",
          error
        );
      }
    },

    verDetalle(id) {
      this.$router.push({ name: "DetallesProyecto", params: { id } });
    },
    formatCodeProyecto(id_proyecto) {
      const formattedCode = id_proyecto.toString().padStart(6, "0");
      return `PROY${formattedCode}`;
    },
    formatDate(dateString) {
      const options = { day: "2-digit", month: "2-digit", year: "numeric" };
      return new Date(dateString).toLocaleDateString("es-ES", options);
    },
    

    confirmarEliminacion(id) {
      Swal.fire({
        title: "¿Estás seguro?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await ProyectoService.deleteProyecto(id);
            this.$swal(
              "¡Eliminado!",
              "El proyecto ha sido eliminado.",
              "success"
            );
            this.fetchProyectos(); // Actualiza la lista de proyectos
          } catch (error) {
            console.error("Error eliminando proyecto:", error);
            this.$swal("Error", "No se pudo eliminar el proyecto.", "error");
          }
        }
      });
    },
  },
};
</script>

<style scoped>
/* Agrega tus estilos aquí */
</style>
