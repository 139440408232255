<script setup>
import { onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";

const store = useStore();
onBeforeMount(() => {
  store.state.hideConfigButton = true;
  store.state.showNavbar = false;
  store.state.showSidenav = false;
  store.state.showFooter = false;
});
onBeforeUnmount(() => {
  store.state.hideConfigButton = false;
  store.state.showNavbar = true;
  store.state.showSidenav = true;
  store.state.showFooter = true;
});
</script>

<template>
  <div
    class="d-flex justify-content-center align-items-center"
    style="height: 100vh; background-color: #f7f7f7"
  >
    <div class="card shadow-lg" style="max-width: 400px; width: 100%">
      <div class="card-header text-center">
        <h3>Iniciar Sesión</h3>
      </div>
      <div class="card-body">
        <form @submit.prevent="handleLogin">
          <div class="mb-3">
            <label for="email" class="form-label">Correo Electrónico</label>
            <input
              type="email"
              class="form-control"
              id="email"
              v-model="form.email"
              required
              placeholder="Ingrese su correo"
            />
          </div>
          <div class="mb-3">
            <label for="password" class="form-label">Contraseña</label>
            <input
              type="password"
              class="form-control"
              id="password"
              v-model="form.password"
              required
              placeholder="Ingrese su contraseña"
            />
          </div>
          <button type="submit" class="btn btn-primary w-100">
            Iniciar Sesión
          </button>
        </form>
        <!-- <div class="mt-3 text-center">
          <a href="#" @click="RecuperarPassword()">Recuperar Contraseña</a>
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
import AuthService from "../../services/AuthService";

export default {
  name: "Login",
  data() {
    return {
      marca: this.$route.params.marca,
      form: {
        email: "",
        password: "",
      },
      cargando: false,
    };
  },
  methods: {
    async handleLogin() {
      try {
        this.cargando = true;
        const response = await AuthService.login(this.form);

        // Asegúrate de que la propiedad 'user' y 'token' existan en la respuesta
        if (!response || !response.user || !response.token) {
          throw new Error("La respuesta no contiene datos de usuario o token");
        }

        const user = response.user; // Aquí está tu usuario
        const token = response.token; // Y aquí está tu token
        localStorage.setItem("authToken", token); // Almacena el token
        localStorage.setItem("user", JSON.stringify(user)); // Almacena los datos del usuario
        this.$store.commit("setUser", user); // Guarda el usuario en el store de Vuex
        this.$router.push({ name: "Home" }); // Redirige al inicio
      } catch (error) {
        console.error("Error al iniciar sesión:", error); // Agrega esta línea para depuración
        const errorMessage =
          error.response?.data?.message || "Error desconocido"; // Captura el mensaje de error
        this.$swal({
          icon: "error",
          title: "Error",
          text: errorMessage,
        });
      } finally {
        this.cargando = false; // Restablece el estado de carga
      }
    },
    RecuperarPassword() {},
  },
};
</script>

<style>
body {
  background-color: #f7f7f7;
}
</style>
