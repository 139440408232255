import apiClient from '../axios';

export default {

 getEmpleados(){
    return apiClient.get('/empleados')
 },

async getEmpleado(id) {
   return apiClient.get(`/empleados/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createEmpleado(empleadoData) {
   return apiClient.post('/empleados', empleadoData);
},

//Editar
updateEmpleado(id, empleadoData) {
   return apiClient.put(`/empleados/${id}`, empleadoData);
},
//eliminar
deleteEmpleado(id) {
   return apiClient.get(`/empleados/${id}/eliminar`);
}
};