<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h4>Detalles de la Cotizacion</h4>
          <BackButton text="Atras" />
        </div>

        <div class="card-body">
          <div v-if="cotizacion" class="lead-details-container">
            <p>
              <strong>Código:</strong> {{ formatCodeCotizacion(cotizacion.id) }}
            </p>
            <div>
              <div v-if="ventasFiltradas.length > 0">
                <p>
                  <strong>Nombre del Cliente:</strong
                  >{{ personaFiltrada.nombres_completos_persona }}
                </p>
              </div>
              <div v-else>
                <p>
                  <strong>Nombre del Lead:</strong
                  >{{ personaFiltrada.nombres_completos_persona }}
                </p>
              </div>
            </div>
            <p>
              <strong>Servicio:</strong>
              {{ cotizacion.servicio }}
            </p>
            <p>
              <strong>Precio Real:</strong>
              {{ "S/." + cotizacion.precio_real || "No disponible" }}
            </p>
            <p>
              <strong>Precio asignado:</strong>
              {{ "S/." + cotizacion.precio_asignado || "No disponible" }}
            </p>
            <p>
              <strong>Descuento:</strong>
              {{
                cotizacion.precio_real > 0
                  ? (
                      (1 -
                        cotizacion.precio_asignado / cotizacion.precio_real) *
                      100
                    ).toFixed(2) + "%"
                  : "No disponible"
              }}
            </p>

            <p>
              <strong>Numero de Cuotas:</strong>
              {{ cotizacion.numero_cuotas || "No disponible" }}
            </p>
            <p>
              <strong>Plazo:</strong>
              {{ cotizacion.plazo || "No disponible" }}
            </p>
            <p>
              <strong>Primera Fecha de Pago:</strong>
              {{ cotizacion.primera_fecha_pago || "No disponible" }}
            </p>
            <p>
              <strong>Fecha Proyectada de Cierre:</strong>
              {{ cotizacion.fecha_proyectada_cierre || "No disponible" }}
            </p>
            <p>
              <strong>Estado de envio:</strong>
              {{ cotizacion.estado_envio_cotizacion || "No disponible" }}
            </p>
            <div>
              <div v-if="ventasFiltradas.length > 0">
                <p>
                  <strong>Venta Asociada: </strong
                  ><router-link
                    v-if="ventasFiltradas[0].id"
                    :to="`/ventas/${ventasFiltradas[0].id}`"
                  >
                    {{ formatVenta(ventasFiltradas[0].id) }}
                  </router-link>
                </p>
              </div>
              <div v-else>
                <button
                  @click="openCreateModalVenta(cotizacion)"
                  class="btn btn-info"
                >
                  Crear Venta
                </button>
              </div>
            </div>

            <button @click="openEditModal(cotizacion)" class="btn btn-info">
              Editar Datos de Cotizacion
            </button>
          </div>

          <div v-else>
            <p>Cargando detalles de la Cotizacion...</p>
          </div>

          <!-- Tabla de pagos -->
          <div class="table-responsive">
            <table
              ref="itemtabla"
              class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
              v-if="cotizacion && cronogramasFiltrados.length > 0"
            >
              <caption>
                Seguimiento de pagos
              </caption>
              <thead class="text-center">
                <tr>
                  <th></th>
                  <th>Cuota</th>
                  <th>Fecha Programada</th>
                  <th>Porcentaje Programado</th>
                  <th>Monto a Pagar</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr
                  v-for="cronograma in cronogramasFiltrados"
                  :key="cronograma.id"
                >
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="openPayModal(cronograma)"
                        class="btn btn-success"
                      >
                        <i class="fas fa-receipt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ cronograma.cuota }}</td>
                  <td>{{ cronograma.fecha_programada }}</td>
                  <td>{{ cronograma.porcentaje_asignado + "%" }}</td>
                  <td>{{ cronograma.monto_pagar }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center">
              No hay cronogramas disponibles para esta cotización.
            </p>
          </div>
          <!-- Mensaje cuando no hay seguimientos -->
          <p v-if="cronogramasFiltrados">No hay seguimientos disponibles.</p>
        </div>

        <!-- Actualizar Cuota -->
        <Modal :isVisible="showPayCronogramaModal" @close="closeModal">
          <div>
            <h4>Actualizar Cuota</h4>
          </div>
          <form @submit.prevent="registrarPago">
            <h5>Cuota Numero {{ newCronograma.cuota }}</h5>
            <div class="row">
              <div class="col">
                <!-- Fecha Programada -->
                <div>
                  <label for="fecha_programada" class="form-label"
                    >Fecha Programada:</label
                  >
                  <input
                    v-model="newCronograma.fecha_programada"
                    type="date"
                    id="fecha_programada"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <!-- Porcentaje programado -->
                  <div class="col">
                    <label for="porcentaje_asignado" class="form-label"
                      >Porcentaje programado:</label
                    >
                    <div class="input-group">
                      <input
                        v-model="newCronograma.porcentaje_asignado"
                        type="text"
                        id="porcentaje_asignado"
                        class="form-control"
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <!-- Monto a pagar -->
                  <div class="col">
                    <label for="monto_pagar" class="form-label"
                      >Monto a pagar:</label
                    >
                    <input
                      v-model="newCronograma.monto_pagar"
                      type="text"
                      id="monto_pagar"
                      class="form-control"
                      readonly
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- Botón de Actualizar Cuota -->
            <div class="mt-4">
              <button type="submit" class="btn btn-primary">
                Actualizar Cuota
              </button>
            </div>
          </form>
        </Modal>

        <!-- Editar Cotizacion -->
        <Modal :isVisible="showEditCotizacionModal" @close="closeModal">
          <div>
            <h4>Modificar Datos de la Cotización</h4>
            <form @submit.prevent="updateCotizacion">
              <div class="row">
                <div class="col">
                  <!-- Precio Real -->
                  <div>
                    <label for="precio_real" class="form-label"
                      >Precio Real:</label
                    >
                    <input
                      v-model.number="selectedCotizacion.precio_real"
                      type="text"
                      id="precio_real"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Precio Asignado -->
                  <div>
                    <label for="precio_asignado" class="form-label"
                      >Precio Asignado:</label
                    >
                    <input
                      v-model.number="selectedCotizacion.precio_asignado"
                      type="text"
                      id="precio_asignado"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Estado de Envío -->
                  <div>
                    <label for="estado_envio_cotizacion" class="form-label"
                      >Estado de Envío:</label
                    >
                    <select
                      v-model="selectedCotizacion.estado_envio_cotizacion"
                      id="estado_envio_cotizacion"
                      class="form-control"
                      required
                    >
                      <option value="">Seleccione una opción:</option>
                      <option value="No enviado">No enviado</option>
                      <option value="Enviado por WhatsApp">
                        Enviado por WhatsApp
                      </option>
                      <option value="Enviado por Email">
                        Enviado por Email
                      </option>
                    </select>
                  </div>

                  <!-- Fecha de Envío -->
                  <div>
                    <label for="fecha_envio" class="form-label"
                      >Fecha de Envío:</label
                    >
                    <input
                      v-model="selectedCotizacion.fecha_envio"
                      type="date"
                      id="fecha_envio"
                      class="form-control"
                      required
                    />
                  </div>

                  <button type="submit" class="btn btn-primary mt-3">
                    Actualizar Cotización
                  </button>
                </div>
              </div>
            </form>
          </div>
        </Modal>

        <!-- Crear Venta -->
        <!-- Modal Crear/Editar Venta -->
        <Modal :isVisible="showCreateVentaModal" @close="closeModal">
          <div>
            <h4>Crear Venta</h4>
            <form @submit.prevent="createVenta">
              <div class="row">
                <h5>Datos del Cliente</h5>
                <div class="col">
                  <!-- Nombres Completos -->
                  <div>
                    <label for="nombres_completos_persona" class="form-label"
                      >Nombres Completos:</label
                    >
                    <input
                      v-model="personaFiltrada.nombres_completos_persona"
                      type="text"
                      id="nombres_completos_persona"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Celular -->
                  <div>
                    <label for="celular_persona" class="form-label"
                      >Celular:</label
                    >
                    <input
                      v-model="personaFiltrada.celular_persona"
                      type="text"
                      id="celular_persona"
                      class="form-control"
                      required
                      maxlength="9"
                    />
                  </div>

                  <!-- Departamento -->
                  <div>
                    <label for="departamento_persona" class="form-label"
                      >Departamento:</label
                    >
                    <input
                      v-model="personaFiltrada.departamento_persona"
                      type="text"
                      id="departamento_persona"
                      class="form-control"
                      required
                    />
                  </div>

                  <!-- Provincia -->
                  <div>
                    <label for="provincia_persona" class="form-label"
                      >Provincia:</label
                    >
                    <input
                      v-model="personaFiltrada.provincia_persona"
                      type="text"
                      id="provincia_persona"
                      class="form-control"
                    />
                  </div>

                  <!-- Distrito -->
                  <div>
                    <label for="distrito_persona" class="form-label"
                      >Distrito:</label
                    >
                    <input
                      v-model="personaFiltrada.distrito_persona"
                      type="text"
                      id="distrito_persona"
                      class="form-control"
                    />
                  </div>

                  <!-- Dirección -->
                  <div>
                    <label for="direccion_persona" class="form-label"
                      >Dirección:</label
                    >
                    <input
                      v-model="personaFiltrada.direccion_persona"
                      type="text"
                      id="direccion_persona"
                      class="form-control"
                    />
                  </div>
                  <!-- DNI -->
                  <div>
                    <label for="dni_persona" class="form-label">DNI:</label>
                    <input
                      v-model="personaFiltrada.dni_persona"
                      type="text"
                      id="dni_persona"
                      class="form-control"
                      required
                      maxlength="8"
                    />
                  </div>
                </div>
                <div class="col">
                  <!-- Email -->
                  <div>
                    <label for="email_persona" class="form-label">Email:</label>
                    <input
                      v-model="personaFiltrada.email_persona"
                      type="email"
                      id="email_persona"
                      class="form-control"
                      required
                    />
                  </div>
                  <!-- Número Celular de Referencia -->
                  <div>
                    <label for="numero_celular_referencia" class="form-label"
                      >Número Celular de Referencia:</label
                    >
                    <input
                      v-model="newCliente.numero_celular_referencia"
                      type="text"
                      id="numero_celular_referencia"
                      class="form-control"
                      maxlength="9"
                      required
                    />
                  </div>

                  <!-- Centro de Estudios del Cliente -->
                  <div>
                    <label for="centro_estudios_cliente" class="form-label"
                      >Centro de Estudios:</label
                    >
                    <input
                      v-model="newCliente.centro_estudios_cliente"
                      type="text"
                      id="centro_estudios_cliente"
                      class="form-control"
                      maxlength="100"
                      required
                    />
                  </div>

                  <!-- Área -->
                  <div>
                    <label for="area" class="form-label">Área:</label>
                    <select
                      v-model="newCliente.area"
                      id="area"
                      class="form-control"
                      required
                    >
                      <option value="" disabled>Seleccione un área</option>
                      <option value="Empresariales">Empresariales</option>
                      <option value="Biomedicas">Biomédicas</option>
                      <option value="Sociales">Sociales</option>
                      <option value="Ingenierías">Ingenierías</option>
                      <option value="Soporte">Soporte</option>
                      <option value="Estadística">Estadística</option>
                    </select>
                  </div>

                  <!-- Nivel -->
                  <div>
                    <label for="nivel" class="form-label">Nivel:</label>
                    <select
                      v-model="newCliente.nivel"
                      id="nivel"
                      class="form-control"
                      required
                    >
                      <option value="" disabled>Seleccione un nivel</option>
                      <option value="Estudiante">Estudiante</option>
                      <option value="Pregrado">Pregrado</option>
                      <option value="Postgrado">Postgrado</option>
                      <option value="Doctorado">Doctorado</option>
                      <option value="Técnico">Técnico</option>
                    </select>
                  </div>

                  <!-- Especialidad -->
                  <div>
                    <label for="especialidad" class="form-label"
                      >Especialidad:</label
                    >
                    <input
                      v-model="newCliente.especialidad"
                      type="text"
                      id="especialidad"
                      class="form-control"
                      maxlength="50"
                      required
                    />
                  </div>
                  <!-- Cumpleaños -->
                  <div>
                    <label for="cumpleanos" class="form-label"
                      >Cumpleaños:</label
                    >
                    <input
                      v-model="personaFiltrada.cumpleanos"
                      type="date"
                      id="cumpleanos"
                      class="form-control"
                  
                    />
                  </div>
                </div>
                <!-- Botón para guardar -->
                <button type="submit" class="btn btn-primary mt-3">
                  Crear Venta
                </button>
              </div>
            </form>
          </div>
        </Modal>
      </div>
    </div>
  </div>
</template>
  
  <script>
import BackButton from "./BackButton.vue";
import CronogramaService from "../../services/CronogramaService";
import CotizacionService from "../../services/CotizacionService";
import Modal from "./Modal.vue";
import $ from "jquery";
import Swal from "sweetalert2";
import VentasService from "../../services/VentasService";
import SeguimientoService from "../../services/SeguimientoService";
import PersonaService from "../../services/PersonaService";
import ClienteService from "../../services/ClienteService";

export default {
  name: "DetallesCotizacion",
  showModal: false,
  components: {
    BackButton,
    Modal,
    //EstadoColor,
  },
  data() {
    return {
      cotizacion: null,
      showPayCronogramaModal: false,
      showEditCotizacionModal: false,
      showCreateVentaModal: false,
      id_persona: null,
      seguimientosFiltrados: [],
      personaFiltrada: {
        nombres_completos_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
        provincia_persona: "",
        distrito_persona: "",
        dni_persona: "",
        direccion_persona: "",
        cumpleanos:""
      },
      newCliente: {
        id_personas: null,
        numero_celular_referencia: "",
        centro_estudios_cliente: "",
        area: "",
        nivel: "",
        especialidad: "",
      },
      cronogramasFiltrados: [],
      cronogramas: [],
      ventasFiltradas: {},
      newVenta: {
        id_cotizaciones: null,
        id_clientes: null,
      },
      newCotizacion: {},
      newCronograma: {
        id_cotizaciones: null,
        cuota: null,
        fecha_programada: "",
        fecha_real: "",
        monto_pagar: null,
        monto_pagado: null,
        porcentaje_asignado: null,
        porcentaje_real: null,
        estado_cronograma: "",
        forma_pago: "",
        numero_operacion: "",
      },
    };
  },
  watch: {
    "newCronograma.porcentaje_asignado": function (newValue) {
      this.updateMontoPagar(newValue);
    },
  },
  created() {
    this.fetchDetalles().then(() => {
      // Solo si fetchDetalles no es cancelada, se ejecutan las demás funciones
      if (this.cotizacion && this.cronogramas) {
        this.fetchCronogramas();
        this.fetchVentas();
        this.fetchSeguimiento();
      }
    });
  },

  methods: {
    async fetchDetalles() {
      try {
        const cotizacionId = this.$route.params.id;
        this.cargando = true;

        // Obtener el usuario del localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // Llamar al servicio para obtener la cotización
        const responseCotizacion = await CotizacionService.getCotizacion(
          cotizacionId
        );
        this.cotizacion = responseCotizacion.data;

        // Verificar si el usuario es "Jefa Comercial", "Administrador" o si es el empleado asignado
        if (
          user.empleado.cargo === "Jefa Comercial" ||
          user.empleado.area_empleados === "Administrador" ||
          responseCotizacion.data.empleado.id === user.empleado.id
        ) {
          // Si cumple la condición, cargar el cronograma
          const responseCronograma = await CronogramaService.getCronogramas(
            cotizacionId
          );
          this.cronogramas = responseCronograma.data;
        } else {
          // Si no cumple, mostrar una alerta y evitar cargar otros detalles
          this.$swal({
            icon: "warning",
            title: "Acceso restringido",
            text: "No tienes permisos para ver los detalles de esta cotización.",
          });
          this.cargando = false; // Detener la carga
          this.$router.push({ name: "Cotizaciones" });
          return; // Cancelar la ejecución de las demás funciones
        }
      } catch (error) {
        console.error("Error fetching details:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los detalles.",
        });
      } finally {
        this.cargando = false;
      }
    },

    async fetchCronogramas() {
      try {
        const cotizacionId = this.$route.params.id;
        this.cargando = true;

        const cronogramaResponse = await CronogramaService.getCronogramas();
        const cronogramas = cronogramaResponse.data.data;

        // Filtrar los cronogramas según el ID de la cotización
        this.cronogramasFiltrados = cronogramas.filter(
          (cronograma) => cronograma.id_cotizaciones == cotizacionId
        );

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "asc"]],
            pageLength: 10,
          });
        });
      } catch (error) {
        console.error("Error fetching cronogramas:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Cronogramas.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchVentas() {
      try {
        const cotizacionId = this.$route.params.id;
        this.cargando = true;

        const ventasResponse = await VentasService.getVentas();
        const ventas = ventasResponse.data.data;

        this.ventasFiltradas = ventas.filter(
          (venta) => venta.id_cotizaciones == cotizacionId
        );
        console.log(this.ventasFiltradas);
      } catch (error) {
        console.error("Error fetching ventas:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las Ventas.",
        });
      }
    },
    async fetchSeguimiento() {
      try {
        const cotizacionId = this.$route.params.id;
        const seguimientosResponse = await SeguimientoService.getSeguimiento();
        const seguimientos = seguimientosResponse.data.data;

        // Filtrar seguimientos por el ID de cotización
        this.seguimientosFiltrados = seguimientos.filter(
          (seguimiento) => seguimiento.id_cotizaciones == cotizacionId
        );

        if (this.seguimientosFiltrados.length > 0) {
          this.id_persona = this.seguimientosFiltrados[0].lead.id_personas;
          console.log(this.id_persona);

          // Hacer la petición para obtener la persona
          const personaResponse = await PersonaService.getPersona(
            this.id_persona
          );

          // Asignar solo los datos de la persona (dentro de 'data') a personaFiltrada
          this.personaFiltrada = personaResponse.data;
          console.log(this.personaFiltrada);
        }
      } catch (error) {
        console.error("Error fetching seguimientos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Seguimientos.",
        });
      }
    },

    async registrarPago() {
      try {
        await CronogramaService.updateCronograma(
          this.selectedCronograma.id,
          this.newCronograma
        );

        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Cotizacion actualizada!!",
        });
        this.closeModal();
        this.fetchCronogramas();
      } catch (error) {
        console.log(error);
      }
    },
    async updateCotizacion() {
      try {
        // Actualizar la cotización
        await CotizacionService.updateCotizacion(
          this.selectedCotizacion.id,
          this.selectedCotizacion
        );

        // Actualizar los cronogramas filtrados
        const cronogramaUpdates = this.cronogramasFiltrados.map(
          (cronograma) => {
            // Calcular el nuevo monto a pagar
            const nuevoMontoPagar =
              (this.selectedCotizacion.precio_asignado *
                cronograma.porcentaje_asignado) /
              100;

            // Retornar la promesa de actualización del cronograma
            return CronogramaService.updateCronograma(cronograma.id, {
              monto_pagar: nuevoMontoPagar,
            });
          }
        );

        // Esperar a que todas las actualizaciones de cronogramas se completen
        await Promise.all(cronogramaUpdates);

        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });

        this.showEditCotizacionModal = false;
        this.refreshData(); // Para refrescar los datos después de la actualización
      } catch (error) {
        console.error("Error actualizando cotización:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar la cotización.",
        });
      }
    },

    async createVenta() {
      try {
        const idCotizacion = this.$route.params.id;
        this.newVenta.id_cotizaciones = idCotizacion;

        const responsePersona = await PersonaService.updatePersona(
          this.id_persona,
          this.personaFiltrada
        );
        console.log(responsePersona);

        this.newCliente.id_personas = this.id_persona;
        console.log(this.newCliente);

        const responseCliente = await ClienteService.createCliente(
          this.newCliente
        );
        const clienteCreate = responseCliente.data.data;
        console.log(responseCliente);
        console.log(clienteCreate);

        this.newVenta.id_clientes = clienteCreate.id;

        const responseVenta = await VentasService.createVenta(this.newVenta);
        console.log(responseVenta);
        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Venta Creada!!",
        });
        this.showCreateVentaModal = false;
        this.refreshData();
      } catch (error) {
        console.error("Error creando venta:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al crear la venta.",
        });
      }
    },
    updateMontoPagar(porcentaje) {
      this.newCronograma.monto_pagar =
        (this.cotizacion.precio_asignado * porcentaje) / 100;
    },

    openEditModal(cotizacion) {
      this.selectedCotizacion = { ...cotizacion }; // Crear una copia del objeto cotizacion
      this.showEditCotizacionModal = true;
    },

    openCreateModalVenta() {
      this.showCreateVentaModal = true;

      // Verificar si ya existe información en personaFiltrada, de lo contrario inicializar vacío
      this.personaFiltrada = {
        nombres_completos_persona:
          this.personaFiltrada?.nombres_completos_persona || "",
        celular_persona: this.personaFiltrada?.celular_persona || "",
        email_persona: this.personaFiltrada?.email_persona || "",
        departamento_persona: this.personaFiltrada?.departamento_persona || "",
        provincia_persona: this.personaFiltrada?.provincia_persona || "",
        distrito_persona: this.personaFiltrada?.distrito_persona || "",
        dni_persona: this.personaFiltrada?.dni_persona || "",
        direccion_persona: this.personaFiltrada?.direccion_persona || "",
      };
    },

    refreshData() {
      this.$router.go();
    },

    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima", // Especifica la zona horaria de Lima
      };
      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },
    formatVenta(id_venta) {
      const formattedCode = id_venta.toString().padStart(6, "0");
      return `VENT${formattedCode}`;
    },

    formatCodeCotizacion(codeCotizacion) {
      const formattedCode = codeCotizacion.toString().padStart(6, "0");
      return `COT${formattedCode}`;
    },
    openPayModal(cronograma) {
      this.selectedCronograma = cronograma;
      if (cronograma) {
        this.newCronograma = {
          id_cotizaciones: cronograma.id_cotizaciones,
          cuota: cronograma.cuota,
          fecha_programada: cronograma.fecha_programada,
          fecha_real: cronograma.fecha_real,
          monto_pagar: cronograma.monto_pagar,
          monto_pagado: cronograma.monto_pagado,
          estado_cronograma: cronograma.estado_cronograma,
          forma_pago: cronograma.forma_pago,
          numero_operacion: cronograma.numero_operacion,
          porcentaje_asignado: cronograma.porcentaje_asignado,
        };
      } else {
        this.newCronograma = {
          id_cotizaciones: null,
          cuota: null,
          fecha_programada: "",
          fecha_real: "",
          monto_pagar: null,
          monto_pagado: null,
          estado_cronograma: "",
          forma_pago: "",
          numero_operacion: "",
          porcentaje_asignado: "",
        };
      }

      this.showPayCronogramaModal = true;
    },
    closeModal() {
      this.showPayCronogramaModal = false;
      this.showEditCotizacionModal = false;
      this.showCreateVentaModal = false;
      this.newCronograma = {
        id_cotizaciones: null,
        cuota: null,
        fecha_programada: "",
        fecha_real: "",
        monto_pagar: null,
        monto_pagado: null,
        estado_cronograma: "",
        numero_operacion: "",
      };
    },
  },
};
</script>
  
<style scoped>
.lead-details-container {
  background-color: white;
  width: 60%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
  