<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Asesor Comercial</h4>
          <BackButton text="Listar Empleados" />
        </div>
        <div class="card-body">
          <div
            v-if="empleado && empleado.persona"
            class="empleado-details-container"
          >
            <div class="row">
              <div class="col">
                <p>
                  <strong>Nombres:</strong>
                  {{ empleado.persona.nombres_completos_persona }}
                </p>
                <p><strong>Area:</strong> {{ empleado.area_empleados }}</p>
                <p><strong>Cargo:</strong> {{ empleado.cargo }}</p>
                <p>
                  <strong>Codigo Empleado:</strong>
                  {{ empleado.codigo_empleado }}
                </p>
                <p>
                  <strong>Celular:</strong>
                  {{ empleado.persona.celular_persona }}
                </p>
                <p>
                  <strong>Correo:</strong> {{ empleado.persona.email_persona }}
                </p>
                <p>
                  <strong>N° de Cuenta:</strong>
                  {{ empleado.numero_cuenta_bancaria_empleado }}
                </p>
                <p>
                  <strong>Sistema de Pension:</strong>
                  {{ empleado.sistema_pensiones_empleado }}
                </p>
                <p><strong>AFP:</strong> {{ empleado.afp_empleado }}</p>
                <p>
                  <strong>Departamento:</strong>
                  {{ empleado.persona.departamento_persona }}
                </p>
                <p>
                  <strong>Provincia:</strong>
                  {{ empleado.persona.provincia_persona }}
                </p>
              </div>
              <div class="col">
                <p>
                  <strong>Distrito:</strong>
                  {{ empleado.persona.distrito_persona }}
                </p>
                <p>
                  <strong>Dirección:</strong>
                  {{ empleado.persona.direccion_persona }}
                </p>
                <p>
                  <strong>Centro de Estudios:</strong>
                  {{ empleado.centro_estudios_empleado }}
                </p>
                <p>
                  <strong>Especialidad:</strong>
                  {{ empleado.profesion_empleado }}
                </p>
                <p>
                  <strong>Inicio de Contrato:</strong>
                  {{ empleado.inicio_contrato_empleado }}
                </p>
                <p>
                  <strong>Fin de Contrato:</strong>
                  {{ empleado.fin_contrato_empleado }}
                </p>
                <p>
                  <strong>Jornada Laboral:</strong>
                  {{ empleado.jornada_laboral_empleado }}
                </p>
                <p>
                  <strong>Modalidad de Contrato:</strong>
                  {{ empleado.modalidad_contrato_empleado }}
                </p>
                <p><strong>Salario:</strong> {{ empleado.salario }}</p>
                <p><strong>Status:</strong> {{ empleado.estado_emplreado }}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
              ref="itemtabla"
            >
            <caption>Cotizaciones</caption>
              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">CODIGO</th>
                  <th scope="col">Precio Real</th>
                  <th scope="col">Precio Asignado</th>
                  <th scope="col">Descuento</th>
                  <th scope="col">Fecha Envio</th>
                  <th scope="col">Estado Envio</th>
                  <th scope="col">Servicio</th>
                  <th scope="col">Asesor de Venta</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="cotizacion in cotizaciones" :key="cotizacion.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(cotizacion.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(cotizacion.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    <router-link
                      v-if="cotizacion.id"
                      :to="`/cotizaciones/${cotizacion.id}`"
                    >
                      {{ formatCodeCotizacion(cotizacion.id) }}
                    </router-link>
                    <span v-else>Sin cotización</span>
                  </td>
                  <td>{{ "S/." + cotizacion.precio_real }}</td>
                  <td>{{ "S/." + cotizacion.precio_asignado }}</td>
                  <td>
                    {{
                      (
                        (1 -
                          cotizacion.precio_asignado / cotizacion.precio_real) *
                        100
                      ).toFixed(2)
                    }}%
                  </td>
                  <td>{{ formatDate(cotizacion.fecha_envio) }}</td>
                  <td>{{ cotizacion.estado_envio_cotizacion }}</td>
                  <td>{{ cotizacion.servicio }}</td>
                  <td>
                    {{
                      cotizacion.empleado
                        ? cotizacion.empleado.persona.nombres_completos_persona
                        : "Sin asignar"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CotizacionService from "../../services/CotizacionService";
import EmpleadoService from "../../services/EmpleadoService";
import BackButton from "./BackButton.vue";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  name: "Empleado",
  components: {
    BackButton,
  },
  data() {
    return {
      nombre: "Empleado",
      cargando: false,
      empleado: null,
      cotizaciones: [],
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
    };
  },
  created() {
    const user = JSON.parse(localStorage.getItem("user"));

    // Verificar si el usuario es Administrador o el ID coincide
    if (
      user &&
      (user.empleado.area_empleados === "Administrador" ||
        user.empleado.cargo === "Jefa Comercial" ||
        this.$route.params.id == user.empleado.id)
    ) {
      this.fetchDetailsEmpleado();
      this.fetchCotizaciones();
    } else {
      // Redirigir a otro componente si no cumple la condición
      this.$router.push({ name: "AreaComercial" });
    }
  },

  methods: {
    async fetchDetailsEmpleado() {
      try {
        const empleadoId = this.$route.params.id;
        this.cargando = true;
        const response = await EmpleadoService.getEmpleado(empleadoId);
        this.empleado = response.data;
      } catch (error) {
        console.error("Error fetching empleados:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Empleados.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchCotizaciones() {
      const id_asesor = this.$route.params.id;

      const responseCotizacion = await CotizacionService.getCotizaciones();

      // Asegúrate de que responseCotizacion.data.data existe y es un array
      if (Array.isArray(responseCotizacion.data.data)) {
        this.cotizaciones = responseCotizacion.data.data.filter(
          (cotizacion) =>
            cotizacion.empleado && cotizacion.empleado.id == id_asesor // Verifica que empleado no sea null
        );

        this.$nextTick(() => {
          this.initializeDataTable(); // Inicializa la tabla después de que las cotizaciones se han actualizado
        });
      } else {
        console.error(
          "No se encontraron cotizaciones o la estructura de datos es incorrecta."
        );
      }
    },

    initializeDataTable() {
      if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
        $(this.$refs.itemtabla).DataTable().destroy();
      }
      this.dataTable = $(this.$refs.itemtabla).DataTable({
        order: [[0, "desc"]],
        pageLength: 20, // Mostrar 20 filas por página
      });
    },
    refreshData() {
      this.$router.go();
    },
    openCreateModal() {
      this.showCreateModal = true;
    },

    closeModal() {
      this.showCreateModal = false;
      this.showEditModal = false;
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          EmpleadoService.deleteEmpleado($id);
          this.refreshData();
        }
      });
    },
    formatCodeCotizacion(codeCotizacion) {
      const formattedCode = codeCotizacion.toString().padStart(6, "0");
      return `COT${formattedCode}`;
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima", // Especifica la zona horaria de Lima
      };
      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesCotizaciones", params: { id } });
    },
  },
};
</script>


<style scoped>
.tabla-tamano {
  font-size: 0.9rem; /* Ajusta el tamaño de fuente según sea necesario */
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
