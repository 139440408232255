<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Cotizaciones</h4>
        </div>
        <!-- Listado -->
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
              ref="itemtabla"
            >
              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">ID</th>
                  <th scope="col">Cliente</th>
                  <th scope="col">Precio Real</th>
                  <th scope="col">Precio Asignado</th>
                  <th scope="col">Descuento</th>
                  <th scope="col">Fecha Envio</th>
                  <th scope="col">Estado Envio</th>
                  <th scope="col">Servicio</th>
                  <th scope="col">Asesor de Venta</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="cotizacion in cotizaciones" :key="cotizacion.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(cotizacion.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditModal(cotizacion)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(cotizacion.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ formatCodeCotizacion(cotizacion.id) }}</td>
                  <td>{{ getClientePorCotizacion(cotizacion.id) }}</td>
                  <td>{{ "S/." + cotizacion.precio_real }}</td>
                  <td>{{ "S/." + cotizacion.precio_asignado }}</td>
                  <td>
                    {{
                      (
                        (1 -
                          cotizacion.precio_asignado / cotizacion.precio_real) *
                        100
                      ).toFixed(2)
                    }}%
                  </td>
                  <td>{{ formatDate(cotizacion.fecha_envio) }}</td>
                  <td>{{ cotizacion.estado_envio_cotizacion }}</td>
                  <td>{{ cotizacion.servicio }}</td>
                  <td>
                    {{
                      cotizacion.empleado
                        ? cotizacion.empleado.persona.nombres_completos_persona
                        : "Sin asignar"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para editar -->
    <Modal :isVisible="showEditCotizacionModal" @close="closeModal">
      <div>
        <h4>Modificar Datos de la Cotización</h4>
        <form @submit.prevent="updateCotizacion">
          <div class="row">
            <div class="col">
              <!-- Precio Real -->
              <div>
                <label for="precio_real" class="form-label">Precio Real:</label>
                <input
                  v-model.number="selectedCotizacion.precio_real"
                  type="text"
                  id="precio_real"
                  class="form-control"
                  required
                />
              </div>

              <!-- Precio Asignado -->
              <div>
                <label for="precio_asignado" class="form-label"
                  >Precio Asignado:</label
                >
                <input
                  v-model.number="selectedCotizacion.precio_asignado"
                  type="text"
                  id="precio_asignado"
                  class="form-control"
                  required
                />
              </div>

              <!-- Estado de Envío -->
              <div>
                <label for="estado_envio_cotizacion" class="form-label"
                  >Estado de Envío:</label
                >
                <select
                  v-model="selectedCotizacion.estado_envio_cotizacion"
                  id="estado_envio_cotizacion"
                  class="form-control"
                  required
                >
                  <option value="">Seleccione una opción:</option>
                  <option value="No enviado">No enviado</option>
                  <option value="Enviado por WhatsApp">
                    Enviado por WhatsApp
                  </option>
                  <option value="Enviado por Email">Enviado por Email</option>
                </select>
              </div>

              <!-- Fecha de Envío -->
              <div>
                <label for="fecha_envio" class="form-label"
                  >Fecha de Envío:</label
                >
                <input
                  v-model="selectedCotizacion.fecha_envio"
                  type="date"
                  id="fecha_envio"
                  class="form-control"
                  required
                />
              </div>

              <button type="submit" class="btn btn-primary mt-3">
                Actualizar Cotización
              </button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import CotizacionService from "../../services/CotizacionService";
import $ from "jquery";
import Modal from "./Modal.vue";
import Swal from "sweetalert2";
import SeguimientoService from "../../services/SeguimientoService";

export default {
  name: "Cotizacion",
  components: {
    Modal,
  },
  data() {
    return {
      cargando: false,
      showEditCotizacionModal: false,
      selectedCotizacion: {},
      cotizaciones: [],
      seguimientos: [],
      newCotizacion: {
        precio_real: null,
        precio_asignado: null,
        estado_envio_cotizacion: "",
        fecha_envio: "",
      },
    };
  },
  created() {
    this.fetchCotizaciones();
    this.fetchSeguimiento();
  },
  computed: {
    getClientePorCotizacion() {
      return (idCotizacion) => {
        const seguimiento = this.seguimientos.find(
          (seguimiento) => seguimiento.id_cotizaciones == idCotizacion
        );

        return seguimiento
          ? seguimiento.lead.persona.nombres_completos_persona
          : "Sin cliente asignado";
      };
    },
  },
  methods: {
    async fetchCotizaciones() {
      try {
        this.cargando = true;

        // Obtener el usuario del localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // Llamar al servicio para obtener las cotizaciones
        const response = await CotizacionService.getCotizaciones();

        // Verificar si el usuario es "Jefa Comercial" o "Administrador"
        if (
          user.empleado.cargo === "Jefa Comercial" ||
          user.empleado.area_empleados === "Administrador"
        ) {
          // Si es "Jefa Comercial" o "Administrador", cargar todas las cotizaciones
          this.cotizaciones = response.data.data;
        } else {
          // Si no, filtrar las cotizaciones por id_empleado  
          this.cotizaciones = response.data.data.filter(
            (cotizacion) => cotizacion.empleado.id === user.empleado.id
          );
        }

        // Inicializar DataTable después de que Vue haya actualizado el DOM
        
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 20,
          });
        });
      } catch (error) {
        console.error("Error fetching cotizaciones:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las Cotizaciones.",
        });
      } finally {
        this.cargando = false;
      }
    },

    async updateCotizacion() {
      try {
        await CotizacionService.updateCotizacion(
          this.selectedCotizacion.id,
          this.selectedCotizacion
        );
        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.showEditCotizacionModal = false;
        this.fetchCotizaciones();
      } catch (error) {
        console.error("Error actualizando cotización:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar la cotización.",
        });
      }
    },
    async fetchSeguimiento() {
      try {
        const responseSeguimiento = await SeguimientoService.getSeguimiento();
        this.seguimientos = responseSeguimiento.data.data;
      } catch (error) {
        console.error("Error carganto seguimientos:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar seguimientos.",
        });
      }
    },
    openEditModal(cotizacion) {
      this.selectedCotizacion = { ...cotizacion }; // Crear una copia del objeto cotizacion
      this.showEditCotizacionModal = true;
    },
    formatCodeCotizacion(codeCotizacion) {
      const formattedCode = codeCotizacion.toString().padStart(6, "0");
      return `COT${formattedCode}`;
    },
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima", // Especifica la zona horaria de Lima
      };
      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesCotizaciones", params: { id } });
    },
    refreshData() {
      this.$router.go();
    },
    closeModal() {
      this.showEditCotizacionModal = false;
    },
    confirmarEliminacion(id) {
      Swal.fire({
        title: "¿Estás seguro de eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          CotizacionService.deleteCotizacion(id);
          this.refreshData();
        }
      });
    },
  },
};
</script>

<style lang="">
</style>
