import apiClient from '../axios';

export default {

 getServicios(){
    return apiClient.get('/servicios')
 },

async getServicio(id) {
   return apiClient.get(`/servicios/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createServicio(servicioData) {
   return apiClient.post('/servicios', servicioData);
},

//Editar
updateServicio(id, servicioData) {
   return apiClient.patch(`/servicios/${id}`, servicioData);
},
//eliminar
deleteServicio(id) {
   return apiClient.get(`/servicios/${id}/eliminar`);
}
};