<template>
  <p :class="getBackgroundClass(text)">
    {{ text }}
  </p>
</template>
  
  <script>
export default {
  name: "ColoredText",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    getBackgroundClass(text) {
      switch (text?.trim().toUpperCase()) {
        case "WHATSAPP":
          return "bg-whatsapp";
        case "MESSENGER":
          return "bg-messenger";
        case "FACEBOOK":
          return "bg-facebook";
        case "TIKTOK":
          return "bg-tiktok";
        case "INSTAGRAM":
          return "bg-instagram";
        case "LLAMADA ENTRANTE":
          return "bg-llamada";
        case "BASE PROPIA":
          return "bg-base";
        case "GMAIL":
          return "bg-gmail";
        case "RECOMENDACIONES":
          return "bg-recomendaciones";
        case "TRATO DIRECTO":
          return "bg-directo";
        case "OTRO":
          return "bg-otro";
        case "Sin iniciar":
          return "bg-iniciar";
        case "En proceso":
          return "bg-proceso";
        case "Observado":
          return "bg-observado";
        case "Terminado":
          return "bg-terminado";
        default:
          return "bg-default";
      }
    },
  },
};
</script>
  
  <style scoped>
.bg-whatsapp {
  background-color: #b2f2b1;
}
.bg-messenger {
  background-color: #b3e5fc;
}
.bg-facebook {
  background-color: #a2c2e2;
}
.bg-tiktok {
  background-color: #d3d3d3;
}
.bg-instagram {
  background-color: #f8bbd0;
}
.bg-llamada {
  background-color: #b9fbc0;
}
.bg-base {
  background-color: #fdf5c3;
}
.bg-gmail {
  background-color: #ff9a8b;
}
.bg-recomendaciones {
  background-color: #e6c8f4;
}
.bg-directo {
  background-color: #f5f5dc;
}
.bg-otro {
  background-color: #e0e0e0;
}
.bg-iniciar {
  background-color: #FFD1D1;
}
.bg-proceso {
  background-color: #B2E0B2;
}
.bg-observado {
  background-color: #D1E0FF;
}
.bg-terminado {
    background-color: #FFF4B2;
}
</style>

  