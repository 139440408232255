import apiClient from '../axios';

export default {

 getTareaProyectos(){
    return apiClient.get('/tareas_proyectos')
 },

async getTareaProyecto(id) {
   return apiClient.get(`/tareas_proyectos/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createTareaProyecto(tareaProyectoData) {
   return apiClient.post('/tareas_proyectos', tareaProyectoData);
},

//Editar
updateTareaProyecto(id, tareaProyectoData) {
   return apiClient.put(`/tareas_proyectos/${id}`, tareaProyectoData);
},
//eliminar
deleteTareaProyecto(id) {
   return apiClient.get(`/tareas_proyectos/${id}/eliminar`);
}
};