import apiClient from '../axios';

export default {

 getVentas(){
    return apiClient.get('/ventas')
 },

async getVenta(id) {
   return apiClient.get(`/ventas/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createVenta(ventaData) {
   return apiClient.post('/ventas', ventaData);
},

//Editar
updateVenta(id, ventaData) {
   return apiClient.patch(`/ventas/${id}`, ventaData);
},
//eliminar
deleteVenta(id) {
   return apiClient.get(`/ventas/${id}/eliminar`);
}
};