import apiClient from '../axios';

export default {

 getAsignaciones(){
    return apiClient.get('/asignaciones')
 },

async getAsignacion(id) {
   return apiClient.get(`/asignaciones/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createAsignacion(asignacionData) {
   return apiClient.post('/asignaciones', asignacionData);
},

//Editar
updateAsignacion(id, asignacionData) {
   return apiClient.patch(`/asignaciones/${id}`, asignacionData);
},
//eliminar
deleteAsignacion(id) {
   return apiClient.get(`/asignaciones/${id}/eliminar`);
}
};