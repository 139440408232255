import apiClient from '../axios';

export default {

 getCotizaciones(){
    return apiClient.get('/cotizaciones')
 },

async getCotizacion(id) {
   return apiClient.get(`/cotizaciones/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createCotizacion(cotizacionData) {
   return apiClient.post('/cotizaciones', cotizacionData);
},

//Editar
updateCotizacion(id, cotizacionData) {
   return apiClient.patch(`/cotizaciones/${id}`, cotizacionData);
},
//eliminar
deleteCotizacion(id) {
   return apiClient.get(`/cotizaciones/${id}/eliminar`);
}
};