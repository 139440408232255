<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h4>Detalles de la Venta</h4>
          <BackButton text="Atrás" />
        </div>

        <div class="card-body">
          <div class="row">
            <div class="col">
              <div v-if="venta" class="lead-details-container">
                <p>
                  <strong>Código Venta:</strong> {{ formatCodeVenta(venta.id) }}
                </p>
                <p>
                  <strong>Código Cotización:</strong>

                  <router-link
                    v-if="venta.cotizacion && venta.cotizacion.id"
                    :to="`/cotizaciones/${venta.cotizacion.id}`"
                  >
                    {{ formatCodeCotizacion(venta.cotizacion.id) }}
                  </router-link>
                  <span v-else>Sin cotización</span>
                </p>
                <p>
                  <strong>Cliente:</strong>
                  {{ venta.cliente?.persona?.nombres_completos_persona || "-" }}
                </p>
                <p>
                  <strong>Costo Total:</strong>
                  {{ "S/." + venta.cotizacion.precio_asignado }}
                </p>
                <p>
                  <strong>Número de Cuotas:</strong>
                  {{ venta.cotizacion.numero_cuotas }}
                </p>
                <p>
                  <strong>Plazo:</strong>
                  {{ venta.cotizacion.plazo + " días" }}
                </p>
                <p>
                  <strong>Servicio:</strong>
                  {{ venta.cotizacion.servicio }}
                </p>
                <div>
                  <div v-if="proyectosFiltrados.length === 0">
                    <button @click="fetchEmpleados" class="btn btn-info">
                      Asignar Proyecto
                    </button>
                  </div>
                </div>
              </div>

              <div v-else>
                <p>Cargando detalles de la Venta...</p>
              </div>
            </div>

            <div
              class="col lead-details-container"
              v-if="proyectosFiltrados.length > 0"
            >
              <p>
                <strong>Nombre Grupal:</strong>
                {{ proyectosFiltrados[0].nombre_grupal }}
              </p>
              <p>
                <strong>Fecha de Recepción del Contrato:</strong>
                {{
                  proyectosFiltrados[0].fecha_recepcion_contrato ||
                  "No disponible"
                }}
              </p>
              <p>
                <strong>Título de Investigación:</strong>
                {{
                  proyectosFiltrados[0].titulo_investigacion || "No disponible"
                }}
              </p>
              <p>
                <strong>Estado del Proyecto:</strong>
                {{ proyectosFiltrados[0].estado_proyecto }}
              </p>
              <p>
                <strong>Avances:</strong>
                {{ proyectosFiltrados[0].avances || "No disponible" }}
              </p>
            </div>
          </div>
        </div>

        <!-- Tabla de pagos -->
        <div class="table-responsive">
          <table
            ref="itemtabla"
            class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
            v-if="venta && cronogramasFiltrados.length > 0"
          >
            <caption>
              Seguimiento de pagos
            </caption>
            <thead class="text-center">
              <tr>
                <th></th>
                <th>Cuota</th>
                <th>Fecha Programada</th>
                <th>Fecha Real</th>
                <th>Porcentaje a Pagar</th>
                <th>Porcentaje Pagado</th>
                <th>Monto a Pagar</th>
                <th>Monto Pagado</th>
                <th>Estado</th>
                <th>Forma de Pago</th>
                <th>Número de Operación</th>
              </tr>
            </thead>
            <tbody class="text-center">
              <tr
                v-for="cronograma in cronogramasFiltrados"
                :key="cronograma.id"
              >
                <td>
                  <div class="btn-group" role="group" aria-label="botones-crud">
                    <button
                      @click="openPayModal(cronograma)"
                      class="btn btn-success"
                    >
                      <i class="fas fa-money-bill-wave" aria-hidden="true"></i>
                    </button>
                  </div>
                </td>
                <td>{{ cronograma.cuota }}</td>
                <td>{{ cronograma.fecha_programada }}</td>
                <td>{{ cronograma.fecha_real || "No disponible" }}</td>
                <td>
                  {{ cronograma.porcentaje_asignado + "%" || "No Pagado" }}
                </td>
                <td>
                  {{
                    cronograma.porcentaje_real !== null &&
                    cronograma.porcentaje_real !== undefined
                      ? cronograma.porcentaje_real + "%"
                      : "No Pagado"
                  }}
                </td>
                <td>{{ cronograma.monto_pagar }}</td>
                <td>{{ cronograma.monto_pagado || "No pagado" }}</td>

                <td>
                  <EstadoCronogramaColor
                    :text="cronograma.estado_cronograma"
                    style="border-radius: 15px"
                  />
                </td>
                <td>{{ cronograma.forma_pago || "No disponible" }}</td>
                <td>{{ cronograma.numero_operacion || "No disponible" }}</td>
              </tr>
            </tbody>
          </table>

          <p v-else class="text-center">
            No hay cronogramas disponibles para esta cotización.
          </p>
        </div>

        <!-- Mensaje cuando no hay seguimientos -->
        <p v-if="cronogramasFiltrados.length === 0">
          No hay seguimientos disponibles.
        </p>
      </div>
    </div>

    <!-- Registrar Pago -->
    <Modal :isVisible="showPayCronogramaModal" @close="closeModal">
      <div>
        <h4>Registrar Pagos</h4>
      </div>
      <form @submit.prevent="registrarPago">
        <h5>Cuota Numero {{ newCronograma.cuota }}</h5>
        <div class="row">
          <!-- Fecha Programada -->
          <div>
            <label for="fecha_programada" class="form-label"
              >Fecha Programada:</label
            >
            <input
              v-model="newCronograma.fecha_programada"
              type="text"
              id="fecha_programada"
              class="form-control"
              readonly
            />
          </div>
          <div class="row">
            <!-- Monto a pagar -->

            <div class="col">
              <label for="monto_pagar" class="form-label">Monto a pagar:</label>
              <input
                v-model="newCronograma.monto_pagar"
                type="text"
                id="monto_pagar"
                class="form-control"
                readonly
              />
            </div>
            <!-- Porcentaje a pagar -->
            <div class="col">
              <label for="porcentaje_asignado" class="form-label"
                >Porcentaje a pagar:</label
              >
              <div class="input-group">
                <input
                  v-model="newCronograma.porcentaje_asignado"
                  type="text"
                  id="porcentaje_asignado"
                  class="form-control"
                  readonly
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Monto Pagado -->
            <div class="col">
              <label for="monto_pagado" class="form-label">Monto Pagado:</label>
              <input
                v-model.number="newCronograma.monto_pagado"
                @input="porcentajeReal"
                type="text"
                id="monto_pagado"
                class="form-control"
              />
            </div>
            <!-- Porcentaje pagado -->
            <div class="col">
              <label for="porcentaje_real" class="form-label"
                >Porcentaje pagado:</label
              >
              <div class="input-group">
                <input
                  v-model.number="newCronograma.porcentaje_real"
                  type="text"
                  id="porcentaje_real"
                  class="form-control"
                  readonly
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>

          <div class="row">
            <!-- Fecha Real -->
            <div class="col">
              <label for="fecha_real" class="form-label">Fecha Real:</label>
              <input
                v-model="newCronograma.fecha_real"
                type="date"
                id="fecha_real"
                class="form-control"
              />
            </div>
            <!-- Estado de Pago -->
            <div class="col">
              <label for="estado_cronograma" class="form-label"
                >Estado de pago:</label
              >
              <select
                v-model="newCronograma.estado_cronograma"
                id="estado_cronograma"
                class="form-control"
              >
                <option value="No Pagado">No Pagado</option>
                <option value="Pagado">Pagado</option>
                <option value="Prorroga">Prorroga</option>
                <option value="Fuera de Plazo">Fuera de Plazo</option>
                <option value="Desistido">Desistido</option>
              </select>
            </div>
          </div>
          <div class="row">
            <!-- Forma de Pago -->
            <div class="col">
              <label for="forma_pago" class="form-label">Forma de pago:</label>
              <select
                v-model="newCronograma.forma_pago"
                id="forma_pago"
                class="form-control"
              >
                <option value="Efectivo">Efectivo</option>
                <option value="Yape">Yape</option>
                <option value="BCP">BCP</option>
                <option value="Banco de la Nacion">Banco de la Nacion</option>
                <option value="Pago Link">Pago Link</option>
                <option value="Plin">Plin</option>
              </select>
            </div>
            <!-- Numero de Operacion -->
            <div class="col">
              <label for="numero_operacion" class="form-label"
                >Numero de Operacion:</label
              >
              <input
                v-model="newCronograma.numero_operacion"
                type="text"
                id="numero_operacion"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <!-- Botón de Registrar Pago -->
        <div class="mt-4">
          <button type="submit" class="btn btn-primary">Registrar Pago</button>
        </div>
      </form>
    </Modal>

    <!-- CrearProyecto -->
    <Modal :isVisible="showCreateProyectoModal" @close="closeModal">
      <div>
        <h4>Asignar Proyecto</h4>
        <form @submit.prevent="createProyecto">
          <div class="row">
            <!-- Nombre Grupal -->
            <div class="col">
              <label for="nombre_grupal" class="form-label"
                >Nombre Grupal:</label
              >
              <input
                v-model="newProyecto.nombre_grupal"
                type="text"
                id="nombre_grupal"
                class="form-control"
                required
              />
            </div>
          </div>

          <!-- Servicio -->
          <div class="col">
            <label for="id_servicio" class="form-label"
              >Servicio del Proyecto:</label
            >
            <select
              v-model="newProyecto.id_servicios"
              id="id_servicio"
              class="form-control"
              @change="filterAsignaciones"
              required
            >
              <option value="" disabled>Seleccione un servicio</option>
              <option
                v-for="servicio in servicios"
                :key="servicio.id"
                :value="servicio.id"
              >
                {{ servicio.nombre_servicio }}
              </option>
            </select>
          </div>

          <!-- Asignacion de Responsables -->
          <div class="row">
            <!-- Asignacion de Jefe de Proyecto -->
            <div class="col">
              <label for="jefe_proyecto" class="form-label"
                >Jefe de Proyecto:</label
              >
              <select
                id="jefe_proyecto"
                v-model="newEmpleadoProyecto.id_empleados"
                class="form-control"
              >
                <option value="">Seleccione...</option>
                <option
                  v-for="empleado in empleadosFiltrados"
                  :key="empleado.id"
                  :value="empleado.id"
                >
                  {{
                    empleado.persona.nombres_completos_persona +
                    " - " +
                    empleado.cargo
                  }}
                </option>
              </select>
            </div>
          </div>
          <!-- Botón para guardar -->
          <button type="submit" class="btn btn-primary mt-3">
            Asignar Proyecto
          </button>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import BackButton from "./BackButton.vue";
import VentasService from "../../services/VentasService";
import CronogramaService from "../../services/CronogramaService";
import EmpleadoProyectoService from "../../services/EmpleadoProyectoService";
import ProyectoService from "../../services/ProyectoService";
import Modal from "./Modal.vue";
import $ from "jquery";
import Swal from "sweetalert2";
import EmpleadoService from "../../services/EmpleadoService";
import ServiciosService from "../../services/ServiciosService";
import AsignacionService from "../../services/AsignacionService";
import EstadoCronogramaColor from "./EstadoCronogramaColor.vue";

export default {
  name: "DetallesVenta",
  components: {
    BackButton,
    Modal,
    EstadoCronogramaColor,
  },
  data() {
    return {
      venta: null,
      cargando: false,
      showPayCronogramaModal: false,
      showCreateProyectoModal: false,
      empleadosFiltrados: [],
      cronogramasFiltrados: [],
      cronogramas: [],
      selectedCronograma: null,
      proyectosFiltrados: [],
      servicios: [],
      asignaciones: [],
      filteredAsignaciones: [],
      newCronograma: {
        id_cotizaciones: null,
        cuota: null,
        fecha_programada: "",
        fecha_real: "",
        monto_pagar: null,
        monto_pagado: null,
        estado_cronograma: "",
        forma_pago: "",
        numero_operacion: "",
        porcentaje_asignado: 0.0,
        porcentaje_real: null,
      },
      newProyecto: {
        id_ventas: null,
        id_servicios: null,
        nombre_grupal: "",
      },
      newEmpleadoProyecto: {
        id_empleados: "",
        id_proyectos: "",
        rol: "Jefe de Asesores",
      },
    };
  },
  watch: {
    "newTareaProyecto.fecha_inicio": function () {
      this.updateFechaFinProgramado();
    },

    "newTareaProyecto.dias": function () {
      this.updateFechaFinProgramado();
    },
  },
  created() {
    this.verificarAccesoYFetchDetalles();
  },

  methods: {
    async fetchDetalles() {
      try {
        const ventaId = this.$route.params.id;
        this.cargando = true;

        // Obtener el usuario del localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // Llamar al servicio para obtener la venta
        const responseVenta = await VentasService.getVenta(ventaId);
        this.venta = responseVenta.data;

        // Verificar si el usuario es "Jefa Comercial", "Administrador" o si es el empleado asignado
        const empleadoVenta = responseVenta.data.cotizacion; // Asignar el empleado de la venta
        if (
          user.empleado.cargo === "Jefa Comercial" ||
          user.empleado.area_empleados === "Administrador" ||
          (empleadoVenta && empleadoVenta.id_empleados === user.empleado.id)
        ) {
          // Si cumple la condición, cargar el cronograma
          this.fetchCronogramas(this.venta.cotizacion.id);
        } else {
          // Si no cumple, mostrar una alerta y evitar cargar otros detalles
          this.$swal({
            icon: "warning",
            title: "Acceso restringido",
            text: "No tienes permisos para ver los detalles de esta venta.",
          });

          this.cargando = false; // Detener la carga
          this.$router.push({ name: 'Ventas' });
          return; // Cancelar la ejecución de las demás funciones
        }
      } catch (error) {
        console.error("Error fetching details:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los detalles.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async verificarAccesoYFetchDetalles() {
      try {
        // Llamar a fetchDetalles y verificar acceso
        await this.fetchDetalles();

        // Si fetchDetalles no detiene la ejecución, llamar a las otras funciones
        this.fetchProyectos();
        this.fetchServicios();
      } catch (error) {
        console.error("Error al verificar acceso o cargar detalles:", error);
        // No hacer nada si fetchDetalles muestra un error o acceso restringido
      }
    },

    async fetchCronogramas(cotizacionId) {
      try {
        const cronogramaResponse = await CronogramaService.getCronogramas();
        const cronogramas = cronogramaResponse.data.data;

        // Filtrar los cronogramas según el ID de la cotización
        this.cronogramasFiltrados = cronogramas.filter(
          (cronograma) => cronograma.id_cotizaciones === cotizacionId
        );

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "asc"]],
            pageLength: 10,
          });
        });
      } catch (error) {
        console.error("Error fetching cronogramas:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los cronogramas.",
        });
      }
    },
    async fetchServicios() {
      try {
        const responseServicios = await ServiciosService.getServicios();
        this.servicios = responseServicios.data.data;
      } catch (error) {
        console.error("Error fetching servicios:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los servicios.",
        });
      }
    },
    async fetchAsignaciones() {
      try {
        const responseAsignaciones = await AsignacionService.getAsignaciones();
        this.asignaciones = responseAsignaciones.data.data;
      } catch (error) {
        console.error("Error fetching asignaciones:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las asignaciones.",
        });
      }
    },
    filterAsignaciones() {
      this.filteredAsignaciones = this.asignaciones.filter(
        (asignacion) =>
          asignacion.id_servicios === this.newProyecto.id_servicios
      );
    },
    updateFechaFinProgramado() {
      if (this.newTareaProyecto.fecha_inicio && this.newTareaProyecto.dias) {
        const inicio = new Date(this.newTareaProyecto.fecha_inicio);
        inicio.setDate(inicio.getDate() + this.newTareaProyecto.dias);
        this.newTareaProyecto.fecha_fin_programado = inicio
          .toISOString()
          .split("T")[0]; // Formato YYYY-MM-DD
      } else {
        this.newTareaProyecto.fecha_fin_programado = null; // Limpia si no hay fecha o días
      }
    },
    async fetchProyectos() {
      try {
        const ventaId = this.$route.params.id;
        this.cargando = true;

        const proyectosResponse = await ProyectoService.getProyectos();
        const proyecto = proyectosResponse.data.data;

        this.proyectosFiltrados = proyecto.filter(
          (proyecto) => proyecto.id_ventas == ventaId
        );
        console.log(this.proyectosFiltrados);
      } catch (error) {
        console.error("Error fetching proyectos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los proyectos.",
        });
      }
    },
    async createProyecto() {
      try {
        // Obtener el ID de la venta desde la ruta
        const idVenta = Number(this.$route.params.id);
        this.newProyecto.id_ventas = idVenta;
        console.log(this.newProyecto);

        // Crear el proyecto
        const responseProyecto = await ProyectoService.createProyecto(
          this.newProyecto
        );
        const idProyecto = responseProyecto.data.data.id;

        // Asignar el ID del proyecto a los empleados del proyecto
        this.newEmpleadoProyecto.id_proyectos = idProyecto; // Cambiar id_empleados a id_proyectos
        console.log(this.newEmpleadoProyecto);

        await EmpleadoProyectoService.createEmpleadoProyecto(
          this.newEmpleadoProyecto
        );
        this.$swal({
          icon: "success",
          title: "¡Proyecto Creado!",
          text: "El proyecto ha sido creado con éxito.",
        });
        this.showCreateProyectoModal = false;
        this.refreshData();
      } catch (error) {
        console.error("Error creando proyecto:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al asignar el proyecto.",
        });
      }
    },

    async fetchEmpleados() {
      try {
        const response = await EmpleadoService.getEmpleados();
        this.empleadosFiltrados = response.data.data.filter(
          (empleado) => empleado.area_empleados === "Academico"
        );
        console.log(this.empleadosFiltrados);

        this.openCreateModalProyecto();
      } catch (error) {
        console.error("Error fetching empleados:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Empleados.",
        });
      } finally {
        this.cargando = false;
      }
    },
    porcentajeReal() {
      const precio = this.venta.cotizacion?.precio_asignado || 0;
      const pagado = this.newCronograma.monto_pagado || 0;

      // Evitar división por cero
      if (precio > 0) {
        const porcentaje = (pagado / precio) * 100;
        this.newCronograma.porcentaje_real = porcentaje.toFixed(2); // Limitar a dos decimales
      } else {
        this.newCronograma.porcentaje_real = 0; // Si el precio es 0, porcentaje es 0
      }
    },

    openPayModal(cronograma) {
      this.selectedCronograma = cronograma;
      if (cronograma) {
        this.newCronograma = {
          id_cotizaciones: cronograma.id_cotizaciones,
          cuota: cronograma.cuota,
          fecha_programada: cronograma.fecha_programada,
          fecha_real: cronograma.fecha_real,
          monto_pagar: cronograma.monto_pagar,
          monto_pagado: cronograma.monto_pagado,
          estado_cronograma: cronograma.estado_cronograma,
          forma_pago: cronograma.forma_pago,
          numero_operacion: cronograma.numero_operacion,
          porcentaje_asignado: cronograma.porcentaje_asignado,
          porcentaje_real: cronograma.porcentaje_real,
        };
      } else {
        this.newCronograma = {
          id_cotizaciones: null,
          cuota: null,
          fecha_programada: "",
          fecha_real: "",
          monto_pagar: null,
          monto_pagado: null,
          estado_cronograma: "",
          forma_pago: "",
          numero_operacion: "",
        };
      }

      this.showPayCronogramaModal = true;
    },
    async registrarPago() {
      try {
        await CronogramaService.updateCronograma(
          this.selectedCronograma.id,
          this.newCronograma
        );

        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Cotizacion actualizada!!",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.log(error);
      }
    },
    openCreateModalProyecto() {
      this.showCreateProyectoModal = true;
    },

    refreshData() {
      this.$router.go();
    },
    formatCodeCotizacion(codeCotizacion) {
      return `COT${codeCotizacion.toString().padStart(6, "0")}`;
    },
    formatCodeVenta(codeVenta) {
      return `VENT${codeVenta.toString().padStart(6, "0")}`;
    },
    formatProyecto(id_proyecto) {
      const formattedCode = id_proyecto.toString().padStart(6, "0");
      return `PROY${formattedCode}`;
    },
    closeModal() {
      this.showPayCronogramaModal = false;
      this.showCreateProyectoModal = false;
      this.newCronograma = {
        id_cotizaciones: null,
        cuota: null,
        fecha_programada: "",
        fecha_real: "",
        monto_pagar: null,
        monto_pagado: null,
        estado_cronograma: "",
        numero_operacion: "",
      };
      this.newProyecto = {
        id_ventas: null,
        nombre_grupal: "",
        fecha_recepcion_contrato: null,
        titulo_investigacion: "",
      };
    },
  },
  mounted() {
    this.fetchAsignaciones(); // Llamar a la función para obtener asignaciones al montar el componente
  },
};
</script>

<style scoped>
.lead-details-container {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
