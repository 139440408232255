<template>
    <div>
      <div class="container">
        <div class="card">
          <div class="card-header">
            <h4>Detalle Cliente</h4>
            <BackButton text="Listar Clientes"/>
          </div>
          <div class="card-body">
            <div v-if="cliente && cliente.persona " class="cliente-details-container">
                <div class="row">
                  <div class="col">
                    <p><strong>Nombre y Apellidos:</strong> {{cliente.persona.nombres_completos_persona}}</p>
                    <p><strong>DNI:</strong> {{cliente.persona.dni_persona}}</p>
                    <p><strong>Cumpleaños:</strong> {{cliente.persona.cumpleanos}}</p>
                    <p><strong>Celular:</strong> {{cliente.persona.celular_persona}}</p>
                    <p><strong>Correo:</strong> {{cliente.persona.email_persona}}</p>
                    <p><strong>Numero de Referencia:</strong> {{cliente.numero_celular_referencia}}</p>
                    <p><strong>Departamento:</strong> {{cliente.persona.departamento_persona}}</p>
                    <p><strong>Provincia:</strong> {{cliente.persona.provincia_persona}}</p>
                    <p><strong>Distrito:</strong> {{cliente.persona.distrito_persona}}</p>
                    <p><strong>Dirección:</strong> {{cliente.persona.direccion_persona}}</p>
                    <p><strong>Centro de Estudios:</strong> {{cliente.centro_estudios_cliente}}</p>
                    <p><strong>Área:</strong> {{cliente.area}}</p>
                    <p><strong>Nivel:</strong> {{cliente.nivel }}</p>
                    <p><strong>Especialidad:</strong> {{cliente.especialidad}}</p>
                  </div>
                </div>
            </div>
          </div>
        </div>
      </div>
      


    </div>
  </template>
  
  <script>
  import ClienteService from "../../services/ClienteService";
  import BackButton from "./BackButton.vue";
  import $ from "jquery";
  import Swal from "sweetalert2";
  
  export default {
    name: "Cliente",
    components: {
      BackButton,
    },
    data() {
      return {
        nombre: "Cliente",
        cargando: false,
        cliente: null,
        newPersona: {
        nombres_completos_persona: "",
        dni_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
        provincia_persona: "",
        distrito_persona: "",
        direccion_persona: "",
      },
      newCliente: {
        numero_celular_referencia: "",
        centro_estudios_cliente: "",
        area: "",
        nivel: "",
        especialidad: "",
      },
      };
    },
    created() {
      this.fetchDetailsCliente();
    },
    methods: {
      async fetchDetailsCliente() {
        try {
          const clienteId = this.$route.params.id;
          this.cargando = true;
          const response = await ClienteService.getCliente(clienteId);
          this.cliente = response.data;
          // Asigna los datos a la propiedad
          this.$nextTick(() => {
            if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
              $(this.$refs.itemtabla).DataTable().destroy();
            }
            this.dataTable = $(this.$refs.itemtabla).DataTable({
              order: [[0, "desc"]],
              pageLength: 20, // Mostrar 20 filas por página
            });
          });
        } catch (error) {
          console.error("Error fetching cliente:", error);
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al cargar los Clientes.",
          });
        } finally {
          this.cargando = false;
        }
      },
      refreshData() {
        this.$router.go();
      },
  
      openCreateModal() {
        this.showCreateModal = true;
      },
      openEditModal(cliente) {
        console.log(cliente);
      },
      closeModal() {
        this.showCreateModal = false;
        this.showEditModal = false;
      },

      confirmarEliminacion($id) {
        Swal.fire({
          title: "¿Estás seguro de eliminar?",
          text: "¡No podrás revertir esto!",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sí, eliminar",
          cancelButtonText: "Cancelar",
        }).then((result) => {
          if (result.is1onfirmed) {
            ClienteService.deleteCliente($id);
            this.refreshData();
          }
        });
      },
    },
  };
  </script>
  
  <style lang="">
  </style>
  