<template>
    <button @click="goBack" class="btn btn-secondary">
      {{ text }}
    </button>
</template>
  
<script>
    export default {
        name: 'BackButton',
        props: {
        text: {
            type: String,
            default: 'Volver'
        }
        },
        methods: {
        goBack() {
            this.$router.go(-1);
        }
        }
    };
</script>
  
<style scoped>

</style>
  