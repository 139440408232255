<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Clientes</h4>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              ref="itemtabla"
              class="table table-borderless table-hover tabla-tamano table-fluid"
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Nombre y Apellidos</th>
                  <th scope="col">DNI</th>
                  <th scope="col">Celular</th>
                  <th scope="col">Numero de Referencia</th>
                  <th scope="col">Correo</th>
                  <th scope="col">Departamento</th>
                  <th scope="col">Provincia</th>
                  <th scope="col">Distrito</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Centro de Estudios</th>
                  <th scope="col">Área</th>
                  <th scope="col">Nivel</th>
                  <th scope="col">Especialidad</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="cliente in clientes" :key="cliente.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(cliente.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditModal(cliente)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(cliente.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    {{ cliente.persona.nombres_completos_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.dni_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.celular_persona }}
                  </td>
                  <td>
                    {{ cliente.numero_celular_referencia }}
                  </td>
                  <td>
                    {{ cliente.persona.email_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.departamento_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.provincia_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.distrito_persona }}
                  </td>
                  <td>
                    {{ cliente.persona.direccion_persona }}
                  </td>
                  <td>
                    {{ cliente.centro_estudios_cliente }}
                  </td>
                  <td>
                    {{ cliente.area }}
                  </td>
                  <td>
                    {{ cliente.nivel }}
                  </td>
                  <td>
                    {{ cliente.especialidad }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Formulario para editar cliente y persona -->
    <Modal :isVisible="showEditModal" @close="closeModal">
      <div>
        <form @submit.prevent="updatePersonaCliente" class="row g-3">
          <div class="form-row row g-3">
            <!-- Datos de la tabla personas -->
            <div class="form-group col-md-4">
              <label for="nombre">Nombre y Apellidos</label>
              <input
                type="text"
                v-model="newPersona.nombres_completos_persona"
                class="form-control"
                id="nombre"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="dni">DNI</label>
              <input
                type="text"
                v-model="newPersona.dni_persona"
                class="form-control"
                id="dni"
                required
                maxlength="8"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="cumpleanos">Cumpleaños</label>
              <input
                type="date"
                v-model="newPersona.cumpleanos"
                class="form-control"
                id="cumpleanos"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="celular">Celular</label>
              <input
                type="text"
                v-model="newPersona.celular_persona"
                class="form-control"
                id="celular"
                required
                maxlength="15"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="correo">Correo</label>
              <input
                type="email"
                v-model="newPersona.email_persona"
                class="form-control"
                id="correo"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="departamento">Departamento</label>
              <input
                type="text"
                v-model="newPersona.departamento_persona"
                class="form-control"
                id="departamento"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="provincia">Provincia</label>
              <input
                type="text"
                v-model="newPersona.provincia_persona"
                class="form-control"
                id="provincia"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="distrito">Distrito</label>
              <input
                type="text"
                v-model="newPersona.distrito_persona"
                class="form-control"
                id="distrito"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="direccion">Dirección</label>
              <input
                type="text"
                v-model="newPersona.direccion_persona"
                class="form-control"
                id="direccion"
              />
            </div>

            <!-- Datos de la tabla clientes -->
            <div class="form-group col-md-4">
              <label for="numero_celular_referencia"
                >Número de Referencia</label
              >
              <input
                type="text"
                v-model="newCliente.numero_celular_referencia"
                class="form-control"
                id="numero_celular_referencia"
                maxlength="15"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="centro_estudios_cliente">Centro de Estudios</label>
              <input
                type="text"
                v-model="newCliente.centro_estudios_cliente"
                class="form-control"
                id="centro_estudios_cliente"
                maxlength="100"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="area_cliente">Área</label>
              <select
                v-model="newCliente.area"
                class="form-control"
                id="area_cliente"
                required
              >
                <option value="">Seleccione</option>
                <option value="Empresariales">Empresariales</option>
                <option value="Biomedicas">Biomédicas</option>
                <option value="Sociales">Sociales</option>
                <option value="Ingenierías">Ingenierías</option>
                <option value="Soporte">Soporte</option>
                <option value="Estadística">Estadística</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="nivel_cliente">Nivel</label>
              <select
                v-model="newCliente.nivel"
                class="form-control"
                id="nivel_cliente"
                required
              >
                <option value="">Seleccione</option>
                <option value="Estudiante">Estudiante</option>
                <option value="Pregrado">Pregrado</option>
                <option value="Postgrado">Postgrado</option>
                <option value="Doctorado">Doctorado</option>
                <option value="Técnico">Técnico</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="especialidad_cliente">Especialidad</label>
              <input
                type="text"
                v-model="newCliente.especialidad"
                class="form-control"
                id="especialidad_cliente"
                maxlength="50"
              />
            </div>

            <!-- Botón de guardar -->
            <div class="form-group col-md-12">
              <button type="submit" class="btn btn-primary">Guardar</button>
            </div>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>
  <script>
import ClienteService from "../../services/ClienteService";
import PersonaService from "../../services/PersonaService";
import $ from "jquery";
import Modal from "./Modal.vue";
import Swal from "sweetalert2";

export default {
  name: "Cliente",
  components: {
    Modal,
  },
  data() {
    return {
      nombre: "Cliente",
      showEditModal: false,
      newPersona: {
        nombres_completos_persona: "",
        dni_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
        provincia_persona: "",
        distrito_persona: "",
        direccion_persona: "",
      },
      newCliente: {
        numero_celular_referencia: "",
        centro_estudios_cliente: "",
        area: "",
        nivel: "",
        especialidad: "",
      },

      clientes: [],
    };
  },
  created() {
    this.fetchClientes();
  },
  methods: {
    async fetchClientes() {
      try {
        this.cargando = true;
        const response = await ClienteService.getClientes();
        this.clientes = response.data.data;
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 20, // Mostrar 20 filas por página
          });
        });
      } catch (error) {
        console.error("Error fetching clientes:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Clientes.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async updatePersonaCliente() {
      try {
        // Actualizar datos de persona asociados al cliente
        const personaResponse = await PersonaService.updatePersona(
          this.selectedCliente.persona.id,
          this.newPersona
        );
        console.log(personaResponse);

        // Asegurarse de que el ID de la persona actualizada esté presente en los datos del cliente
        this.newCliente.id_personas = personaResponse.data.persona.id;
        console.log(this.newCliente);

        // Actualizar los datos del cliente
        await ClienteService.updateCliente(
          this.selectedCliente.id,
          this.newCliente
        );

        // Mostrar una alerta de éxito
        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Cliente y Persona actualizados correctamente.",
        });

        // Cerrar el modal y refrescar los datos
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.log("Error al actualizar cliente/persona:", error);
      }
    },

    openEditModal(cliente) {
      console.log(cliente);

      this.selectedCliente = cliente;

      if (cliente) {
        // Si se pasa un cliente, se rellenan los datos de la persona y el cliente asociado
        this.newPersona = {
          nombres_completos_persona: cliente.persona.nombres_completos_persona,
          celular_persona: cliente.persona.celular_persona,
          email_persona: cliente.persona.email_persona,
          departamento_persona: cliente.persona.departamento_persona,
          provincia_persona: cliente.persona.provincia_persona,
          distrito_persona: cliente.persona.distrito_persona,
          dni_persona: cliente.persona.dni_persona,
          direccion_persona: cliente.persona.direccion_persona,
          cumpleanos: cliente.persona.cumpleanos,
        };

        this.newCliente = {
          numero_celular_referencia: cliente.numero_celular_referencia,
          centro_estudios_cliente: cliente.centro_estudios_cliente,
          area: cliente.area, // AREA del cliente
          nivel: cliente.nivel, // NIVEL del cliente
          especialidad: cliente.especialidad,
        };
      } else {
        // Si no se pasa un cliente, se inicializan los valores en blanco
        this.newPersona = {
          nombres_completos_persona: "",
          celular_persona: "",
          email_persona: "",
          departamento_persona: "",
          provincia_persona: "",
          distrito_persona: "",
          dni_persona: "",
          direccion_persona: "",
        };

        this.newCliente = {
          numero_celular_referencia: "",
          centro_estudios_cliente: "",
          area: "",
          nivel: "",
          especialidad: "",
        };
      }

      // Mostrar el modal de edición
      this.showEditModal = true;
    },
    closeModal() {
      this.showEditModal = false;
      this.newPersona = {
        nombres_completos_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
        provincia_persona: "",
        distrito_persona: "",
        dni_persona: "",
        direccion_persona: "",
      };

      this.newCliente = {
        numero_celular_referencia: "",
        centro_estudios_cliente: "",
        area: "",
        nivel: "",
        especialidad: "",
      };
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ClienteService.deleteEmpleado($id);
          this.refreshData();
        }
      });
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesCliente", params: { id } });
    },
    refreshData() {
      this.$router.go();
    },
  },
};
</script>
  