import { createRouter, createWebHistory } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
// import Tables from "../views/Tables.vue";
// import Billing from "../views/Billing.vue";
// import VirtualReality from "../views/VirtualReality.vue";
// import RTL from "../views/Rtl.vue";
// import Profile from "../views/Profile.vue";
// import Signup from "../views/Signup.vue";
// import Signin from "../views/Signin.vue";
import Lead from "../views/dashboard/Lead.vue";
import Empleado from "../views/dashboard/Empleado.vue";
import DetallesLead from "../views/dashboard/DetallesLead.vue";
import DetallesEmpleado from "../views/dashboard/DetallesEmpleado.vue";
import Cotizaciones from "../views/dashboard/Cotizaciones.vue";
import DetalleCotizacion from "../views/dashboard/DetalleCotizacion.vue";
import Ventas from "../views/dashboard/Ventas.vue";
import DetalleVenta from "../views/dashboard/DetalleVenta.vue";
import AreaComercial from "../views/dashboard/AreaComercial.vue";
import AreaComercialDetalles from "../views/dashboard/AreaComercialDetalles.vue";
import AreaAcademica from "../views/dashboard/AreaAcademica.vue";
import AreaAcademicaDetalles from "../views/dashboard/AreaAcademicaDetalles.vue";
import Clientes from "../views/dashboard/Clientes.vue";
import DetallesCliente from "../views/dashboard/DetallesCliente.vue";
import Proyectos from "../views/dashboard/Proyectos.vue";
import Servicios from "../views/dashboard/Servicios.vue";
import DetallesProyecto from "../views/dashboard/DetallesProyecto.vue";
import DetallesServicios from "../views/dashboard/DetallesServicios.vue";
import Login from "../views/dashboard/Login.vue";
import Home from "../views/dashboard/Home.vue";
const routes = [
  {
    path: "/",
    name: "Home",
    redirect: "/inicio",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
  },
  //Home
  {
    path: "/inicio",
    name: "Inicio",
    component: Home,
    meta: {
      auth: true,
    },
  },

  //Ventas
  {
    path: "/leads",
    name: "Lead",
    component: Lead,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']

    },
  },
  {
    path: "/leads/:id",
    name: "DetallesLead",
    component: DetallesLead,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/area-comercial",
    name: "AreaComercial",
    component: AreaComercial,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/area-comercial/:id",
    name: "AreaComercialDetalles",
    component: AreaComercialDetalles,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/cotizaciones",
    name: "Cotizaciones",
    component: Cotizaciones,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/cotizaciones/:id",
    name: "DetallesCotizaciones",
    component: DetalleCotizacion,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/ventas",
    name: "Ventas",
    component: Ventas,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },
  {
    path: "/ventas/:id",
    name: "DetallesVentas",
    component: DetalleVenta,
    meta: {
      auth: true,
      requiresArea:['Administrador','Ventas']
    },
  },


  //Academico
  {
    path: "/area-academica",
    name: "AreaAcademica",
    component: AreaAcademica,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },
  {
    path: "/area-academica/:id",
    name: "AreaAcademicaDetalles",
    component: AreaAcademicaDetalles,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },
  {
    path: "/clientes",
    name: "Clientes",
    component: Clientes,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },
  {
    path: "/clientes/:id",
    name: "DetallesCliente",
    component: DetallesCliente,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },
  {
    path: "/proyectos",
    name: "Proyectos",
    component: Proyectos,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },
  {
    path: "/proyectos/:id",
    name: "DetallesProyecto",
    component: DetallesProyecto,
    meta: {
      auth: true,
      requiresArea:['Administrador','Academico']
    },
  },


  //Administrador
  {
    path: "/empleados",
    name: "Empleados",
    component: Empleado,
    meta: {
      auth: true,
      requiresArea:['Administrador']
    },
  },
  {
    path: "/empleados/:id",
    name: "DetallesEmpleado",
    component: DetallesEmpleado,
    meta: {
      auth: true,
      requiresArea:['Administrador']
    },
  },
  
  {
    path: "/servicios",
    name: "Servicios",
    component: Servicios,
    meta: {
      auth: true,
      requiresArea:['Administrador']
    },
  },
  {
    path: "/servicios/:id",
    name: "DetallesServicios",
    component: DetallesServicios,
    meta: {
      auth: true,
      requiresArea:['Administrador']
    },
  },

  //General
  {
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      auth: false,
    },
  },

  // {
  //   path: "/tables",
  //   name: "Tables",
  //   component: Tables,
  // },

  // {
  //   path: "/billing",
  //   name: "Billing",
  //   component: Billing,
  // },
  // {
  //   path: "/virtual-reality",
  //   name: "Virtual Reality",
  //   component: VirtualReality,
  // },
  // {
  //   path: "/rtl-page",
  //   name: "RTL",
  //   component: RTL,
  // },
  // {
  //   path: "/profile",
  //   name: "Profile",
  //   component: Profile,
  // },
  // {
  //   path: "/signin",
  //   name: "Signin",
  //   component: Signin,
  // },
  // {
  //   path: "/signup",
  //   name: "Signup",
  //   component: Signup,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = !!localStorage.getItem('authToken');
  const user = JSON.parse(localStorage.getItem('user'));

  // Verificar si la ruta requiere autenticación
  if (to.matched.some(record => record.meta.auth)) {
      // Si no está autenticado, redirige al login
      if (!isAuthenticated) {
          next({ name: 'Login' });
      } else if (!user) {
          // Si el usuario no está definido, redirige al login
          next({ name: 'Login' });
      } else {
          // Verificar si la ruta requiere un área específica
          if (to.matched.some(record => record.meta.requiresArea)) {
              const allowedAreas = to.meta.requiresArea;
              // Verificar si el área del usuario está en las áreas permitidas
              if (!allowedAreas.includes(user.empleado.area_empleados)) {
                  next({ path: '/' }); // Redirigir a la página principal o a una ruta de acceso denegado
              } else {
                  next(); // Permitir el acceso si tiene el área adecuada
              }
          } else {
              next(); // Si la ruta no requiere un área específica, continuar
          }
      }
  } else {
      next(); // Si la ruta no requiere autenticación, continuar
  }
});



export default router;
