import apiClient from '../axios';

export default {

 getReportesClientes(){
    return apiClient.get('/reportes_clientes')
 },

async getReporteCliente(id) {
   return apiClient.get(`/reportes_clientes/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createReporteCliente(reporteClienteData) {
   return apiClient.post('/reportes_clientes', reporteClienteData);
},

//Editar
updateReporteCliente(id, reporteClienteData) {
   return apiClient.put(`/reportes_clientes/${id}`, reporteClienteData);
},
//eliminar
deleteReporteCliente(id) {
   return apiClient.get(`/reportes_clientes/${id}/eliminar`);
}
};