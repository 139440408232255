import apiClient from '../axios';

export default {

 getCronogramas(){
    return apiClient.get('/cronogramas')
 },

async getCronograma(id) {
   return apiClient.get(`/cronogramas/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createCronograma(cronogramaData) {
   return apiClient.post('/cronogramas', cronogramaData);
},

//Editar
updateCronograma(id, cronogramaData) {
   return apiClient.patch(`/cronogramas/${id}`, cronogramaData);
},
//eliminar
deleteCronograma(id) {
   return apiClient.get(`/cronogramas/${id}/eliminar`);
}
};