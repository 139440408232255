<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Empleados</h4>
          <!-- Abrir modal de creación de Lead -->
          <button @click="openCreateModal" class="btn btn-primary">
            Nuevo Usuario
          </button>
        </div>
        <div class="card-body">
          <div class="table-responsive">
            <table
              ref="itemtabla"
              class="table table-borderless table-hover tabla-tamano table-fluid"
            >
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col">DNI</th>
                  <th scope="col">Nombre y Apellidos</th>
                  <th scope="col">Area</th>
                  <th scope="col">Cargo</th>
                  <th scope="col">Codigo Empleado</th>
                  <th scope="col">Celular</th>
                  <th scope="col">Correo</th>
                  <th scope="col">N° de Cuenta</th>
                  <th scope="col">Sistema de Pension</th>
                  <th scope="col">AFP</th>
                  <th scope="col">Departamento</th>
                  <th scope="col">Provincia</th>
                  <th scope="col">Distrito</th>
                  <th scope="col">Dirección</th>
                  <th scope="col">Centro de Estudios</th>
                  <th scope="col">Especialidad</th>
                  <th scope="col">Inicio de Contrato</th>
                  <th scope="col">Fin de Contrato</th>
                  <th scope="col">Jornada Laboral</th>
                  <th scope="col">Modalidad de Contrato</th>
                  <th scope="col">Salario</th>
                  <th scope="col">Cumpleaños</th>
                  <th scope="col">Status</th>
                </tr>
              </thead>
              <tbody v-if="empleados && empleados.length">
                <tr v-for="empleado in empleados" :key="empleado.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(empleado.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditModal(empleado)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(empleado.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    {{ empleado.persona.dni_persona }}
                  </td>
                  <td>
                    {{ empleado.persona.nombres_completos_persona }}
                  </td>
                  <td>
                    {{ empleado.area_empleados }}
                  </td>
                  <td>
                    {{ empleado.cargo }}
                  </td>
                  <td>
                    {{ empleado.codigo_empleado + formatCode(empleado.id) }}
                  </td>
                  <td>
                    {{ empleado.persona.celular_persona }}
                  </td>
                  <td>
                    {{ empleado.persona.email_persona }}
                  </td>
                  <td>
                    {{ empleado.numero_cuenta_bancaria_empleado }}
                  </td>
                  <td>
                    {{ empleado.sistema_pensiones_empleado }}
                  </td>
                  <td>
                    {{ empleado.afp_empleado }}
                  </td>
                  <td>
                    {{ empleado.persona.departamento_persona }}
                  </td>
                  <td>
                    {{ empleado.persona.provincia_persona }}
                  </td>
                  <td>
                    {{ empleado.persona.distrito_persona }}
                  </td>
                  <td>
                    {{ empleado.persona.direccion_persona }}
                  </td>
                  <td>
                    {{ empleado.centro_estudios_empleado }}
                  </td>
                  <td>
                    {{ empleado.profesion_empleado }}
                  </td>
                  <td>
                    {{ empleado.inicio_contrato_empleado }}
                  </td>
                  <td>
                    {{ empleado.fin_contrato_empleado }}
                  </td>
                  <td>
                    {{ empleado.jornada_laboral_empleado }}
                  </td>
                  <td>
                    {{ empleado.modalidad_contrato_empleado }}
                  </td>
                  <td>
                    {{ "S/." + empleado.salario }}
                  </td>
                  <td>
                    {{ empleado.persona?.cumpleanos || "Sin registrar" }}
                  </td>
                  <td>
                    {{ empleado.estado_emplreado }}
                  </td>
                </tr>
              </tbody>
              <tbody v-else>
                <tr>
                  <td colspan="23" class="text-center">
                    No hay empleados para mostrar
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Formulario para crear nuevo empleado -->
    <Modal :isVisible="showCreateModal" @close="closeModal">
      <div>
        <form @submit.prevent="createEmpleado" class="row g-3">
          <div class="form-row row g-3">
            <div class="form-group col-md-4">
              <label for="nombre">Nombre y Apellidos</label>
              <input
                type="text"
                v-model="newPersona.nombres_completos_persona"
                class="form-control"
                id="nombre"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="dni">DNI</label>
              <input
                type="text"
                v-model="newPersona.dni_persona"
                class="form-control"
                id="dni"
                maxlength="8"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="cumpleanos" class="form-label">Cumpleaños:</label>
              <input
                v-model="newPersona.cumpleanos"
                type="date"
                id="cumpleanos"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="celular">Celular</label>
              <input
                type="text"
                v-model="newPersona.celular_persona"
                class="form-control"
                id="celular"
                maxlength="9"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="correo">Correo</label>
              <input
                type="email"
                v-model="newPersona.email_persona"
                class="form-control"
                id="correo"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="password">Contraseña</label>
              <input
                type="password"
                v-model="newPersona.password"
                class="form-control"
                id="password"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="departamento">Departamento</label>
              <input
                type="text"
                v-model="newPersona.departamento_persona"
                class="form-control"
                id="departamento"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="provincia">Provincia</label>
              <input
                type="text"
                v-model="newPersona.provincia_persona"
                class="form-control"
                id="provincia"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="distrito">Distrito</label>
              <input
                type="text"
                v-model="newPersona.distrito_persona"
                class="form-control"
                id="distrito"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="direccion">Dirección</label>
              <input
                type="text"
                v-model="newPersona.direccion_persona"
                class="form-control"
                id="direccion"
              />
            </div>

            <div class="form-group col-md-4">
              <label for="area">Área</label>
              <select
                v-model="newEmpleado.area_empleados"
                class="form-control"
                id="area"
                required
                @change="clearCargo"
              >
                <option value="">Seleccione</option>
                <option value="Ventas">Ventas</option>
                <option value="Academico">Académico</option>
                <option value="Administrador">Administrador</option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="cargo">Cargo</label>
              <select
                v-model="newEmpleado.cargo"
                class="form-control"
                id="cargo"
                required
              >
                <option value="">Seleccione</option>
                <option
                  v-for="cargo in filteredCargos"
                  :key="cargo"
                  :value="cargo"
                >
                  {{ cargo }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="especialidad">Especialidad</label>
              <select
                v-model="newEmpleado.especialidad"
                class="form-control"
                id="especialidad"
                required
              >
                <option value="">Seleccione</option>
                <option value="Empresariales">Empresariales</option>
                <option value="Biomédica">Biomédica</option>
                <option value="Sociales">Sociales</option>
                <option value="Ingenierías">Ingenierías</option>
                <option value="Soporte y Estadística">
                  Soporte y Estadística
                </option>
                <option value="Otro">Otro</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="salario">Salario</label>
              <input
                type="text"
                v-model="newEmpleado.salario"
                class="form-control"
                id="salario"
              />
            </div>

            <div class="form-group col-md-4">
              <label for="cuenta">N° de Cuenta</label>
              <input
                type="text"
                v-model="newEmpleado.numero_cuenta_bancaria_empleado"
                class="form-control"
                id="cuenta"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="sistemaPension">Sistema de Pensión</label>
              <select
                v-model="newEmpleado.sistema_pensiones_empleado"
                class="form-control"
                id="sistemaPension"
                required
              >
                <option value="">Seleccione</option>
                <option value="AFP">AFP</option>
                <option value="OPN">OPN</option>
                <option value="RH">RH</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="afp">AFP</label>
              <select
                v-model="newEmpleado.afp_empleado"
                class="form-control"
                id="afp"
              >
                <option value="">Seleccione</option>
                <option value="PRIMA">PRIMA</option>
                <option value="PROFUTURO">PROFUTURO</option>
                <option value="HABITAT">HABITAT</option>
                <option value="INTEGRA">INTEGRA</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="centroEstudios">Centro de Estudios</label>
              <input
                type="text"
                v-model="newEmpleado.centro_estudios_empleado"
                class="form-control"
                id="centroEstudios"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="profesion">Profesión</label>
              <input
                type="text"
                v-model="newEmpleado.profesion_empleado"
                class="form-control"
                id="profesion"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inicioContrato">Inicio de Contrato</label>
              <input
                type="date"
                v-model="newEmpleado.inicio_contrato_empleado"
                class="form-control"
                id="inicioContrato"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="finContrato">Fin de Contrato</label>
              <input
                type="date"
                v-model="newEmpleado.fin_contrato_empleado"
                class="form-control"
                id="finContrato"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="jornadaLaboral">Jornada Laboral</label>
              <select
                v-model="newEmpleado.jornada_laboral_empleado"
                class="form-control"
                id="jornadaLaboral"
                required
              >
                <option value="">Seleccione</option>
                <option value="FULL TIME">FULL TIME</option>
                <option value="PARTTIME">PARTTIME</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="modalidadContrato">Modalidad de Contrato</label>
              <select
                v-model="newEmpleado.modalidad_contrato_empleado"
                class="form-control"
                id="modalidadContrato"
                required
              >
                <option value="">Seleccione</option>
                <option value="SERVICIO ESPECÍFICO">SERVICIO ESPECÍFICO</option>
                <option value="NECESIDADES DE MERCADO">
                  NECESIDADES DE MERCADO
                </option>
                <option value="LOCACIÓN">LOCACIÓN</option>
                <option value="PRÁCTICAS">PRÁCTICAS</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="estado">Status</label>
              <select
                v-model="newEmpleado.estado_emplreado"
                class="form-control"
                id="estado"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
                <option value="VACACIONES">VACACIONES</option>
                <option value="DESCANSO">DESCANSO</option>
              </select>
            </div>
          </div>
          <div class="mt-04">
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Formulario para editar empleado -->
    <Modal :isVisible="showEditModal" @close="closeModal">
      <div>
        <form @submit.prevent="updateEmpleado" class="row g-3">
          <div class="form-row row g-3">
            <div class="form-group col-md-4">
              <label for="nombre">Nombre y Apellidos</label>
              <input
                type="text"
                v-model="newPersona.nombres_completos_persona"
                class="form-control"
                id="nombre"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="dni">DNI</label>
              <input
                type="text"
                v-model="newPersona.dni_persona"
                class="form-control"
                id="dni"
                required
                maxlength="8"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="cumpleanos" class="form-label">Cumpleaños:</label>
              <input
                v-model="newPersona.cumpleanos"
                type="date"
                id="cumpleanos"
                class="form-control"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="celular">Celular</label>
              <input
                type="text"
                v-model="newPersona.celular_persona"
                class="form-control"
                id="celular"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="correo">Correo</label>
              <input
                type="email"
                v-model="newPersona.email_persona"
                class="form-control"
                id="correo"
                required
              />
            </div>
            <div class="form-group col-md-4">
              <label for="password">Contraseña</label>
              <input
                type="password"
                v-model="newPersona.password"
                class="form-control"
                id="password"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="departamento">Departamento</label>
              <input
                type="text"
                v-model="newPersona.departamento_persona"
                class="form-control"
                id="departamento"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="provincia">Provincia</label>
              <input
                type="text"
                v-model="newPersona.provincia_persona"
                class="form-control"
                id="provincia"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="distrito">Distrito</label>
              <input
                type="text"
                v-model="newPersona.distrito_persona"
                class="form-control"
                id="distrito"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="direccion">Dirección</label>
              <input
                type="text"
                v-model="newPersona.direccion_persona"
                class="form-control"
                id="direccion"
              />
            </div>

            <div class="form-group col-md-4">
              <label for="area">Área</label>
              <select
                v-model="newEmpleado.area_empleados"
                class="form-control"
                id="area"
                required
                @change="clearCargo"
              >
                <option value="">Seleccione</option>
                <option value="Ventas">Ventas</option>
                <option value="Academico">Académico</option>
                <option value="Administrador">Administrador</option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="cargo">Cargo</label>
              <select
                v-model="newEmpleado.cargo"
                class="form-control"
                id="cargo"
                required
              >
                <option value="">Seleccione</option>
                <option
                  v-for="cargo in filteredCargos"
                  :key="cargo"
                  :value="cargo"
                >
                  {{ cargo }}
                </option>
              </select>
            </div>

            <div class="form-group col-md-4">
              <label for="especialidad">Especialidad</label>
              <select
                v-model="newEmpleado.especialidad"
                class="form-control"
                id="especialidad"
                required
              >
                <option value="">Seleccione</option>
                <option value="Empresariales">Empresariales</option>
                <option value="Biomédica">Biomédica</option>
                <option value="Sociales">Sociales</option>
                <option value="Ingenierías">Ingenierías</option>
                <option value="Soporte y Estadística">
                  Soporte y Estadística
                </option>
                <option value="Otro">Otro</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="salario">Salario</label>
              <input
                type="text"
                v-model="newEmpleado.salario"
                class="form-control"
                id="salario"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="cuenta">N° de Cuenta</label>
              <input
                type="text"
                v-model="newEmpleado.numero_cuenta_bancaria_empleado"
                class="form-control"
                id="cuenta"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="sistemaPension">Sistema de Pensión</label>
              <select
                v-model="newEmpleado.sistema_pensiones_empleado"
                class="form-control"
                id="sistemaPension"
                required
              >
                <option value="">Seleccione</option>
                <option value="AFP">AFP</option>
                <option value="OPN">OPN</option>
                <option value="RH">RH</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="afp">AFP</label>
              <select
                v-model="newEmpleado.afp_empleado"
                class="form-control"
                id="afp"
              >
                <option value="">Seleccione</option>
                <option value="PRIMA">PRIMA</option>
                <option value="PROFUTURO">PROFUTURO</option>
                <option value="HABITAT">HABITAT</option>
                <option value="INTEGRA">INTEGRA</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="centroEstudios">Centro de Estudios</label>
              <input
                type="text"
                v-model="newEmpleado.centro_estudios_empleado"
                class="form-control"
                id="centroEstudios"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="profesion">Profesión</label>
              <input
                type="text"
                v-model="newEmpleado.profesion_empleado"
                class="form-control"
                id="profesion"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="inicioContrato">Inicio de Contrato</label>
              <input
                type="date"
                v-model="newEmpleado.inicio_contrato_empleado"
                class="form-control"
                id="inicioContrato"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="finContrato">Fin de Contrato</label>
              <input
                type="date"
                v-model="newEmpleado.fin_contrato_empleado"
                class="form-control"
                id="finContrato"
              />
            </div>
            <div class="form-group col-md-4">
              <label for="jornadaLaboral">Jornada Laboral</label>
              <select
                v-model="newEmpleado.jornada_laboral_empleado"
                class="form-control"
                id="jornadaLaboral"
                required
              >
                <option value="">Seleccione</option>
                <option value="FULL TIME">FULL TIME</option>
                <option value="PARTTIME">PARTTIME</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="modalidadContrato">Modalidad de Contrato</label>
              <select
                v-model="newEmpleado.modalidad_contrato_empleado"
                class="form-control"
                id="modalidadContrato"
                required
              >
                <option value="">Seleccione</option>
                <option value="SERVICIO ESPECÍFICO">SERVICIO ESPECÍFICO</option>
                <option value="NECESIDADES DE MERCADO">
                  NECESIDADES DE MERCADO
                </option>
                <option value="LOCACIÓN">LOCACIÓN</option>
                <option value="PRÁCTICAS">PRÁCTICAS</option>
              </select>
            </div>
            <div class="form-group col-md-4">
              <label for="estado_emplreado">Status</label>
              <select
                v-model="newEmpleado.estado_emplreado"
                class="form-control"
                id="estado_emplreado"
              >
                <option value="ACTIVO">ACTIVO</option>
                <option value="INACTIVO">INACTIVO</option>
                <option value="VACACIONES">VACACIONES</option>
                <option value="DESCANSO">DESCANSO</option>
              </select>
            </div>
          </div>
          <div class="mt-04">
            <button type="submit" class="btn btn-primary">Guardar</button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import EmpleadoService from "../../services/EmpleadoService";
import PersonaService from "../../services/PersonaService";
import $ from "jquery";
import Modal from "./Modal.vue";
import Swal from "sweetalert2";

export default {
  name: "Empleado",
  components: {
    Modal,
  },
  data() {
    return {
      nombre: "Empleado",
      cargando: false,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      selectedEmpleado: null,
      empleados: [],
      newPersona: {
        nombres_completos_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
        provincia_persona: "",
        distrito_persona: "",
        dni_persona: "",
        direccion_persona: "",
        password: "",
        cumpleanos: "",
      },
      newEmpleado: {
        id_personas: null,
        area_empleados: "",
        cargo: "",
        especialidad: "",
        numero_cuenta_bancaria_empleado: "",
        sistema_pensiones_empleado: "",
        afp_empleado: "",
        centro_estudios_empleado: "",
        profesion_empleado: "",
        inicio_contrato_empleado: "",
        fin_contrato_empleado: "",
        jornada_laboral_empleado: "",
        modalidad_contrato_empleado: "",
        estado_emplreado: "",
        salario: null,
      },
      cargos: {
        Ventas: [
          "Jefa Comercial",
          "Atención al cliente",
          "Ejecutiva de Ventas",
          "Ejecutiva de Cobranzas",
          "Asistente Comercial",
          "Practicante",
          "Soporte",
        ],
        Academico: [
          "Jefe de Investigación",
          "Asesor Senior",
          "Asesor Junior",
          "Asistente Academico",
          "Practicante",
          "Soporte",
        ],
        Administrador: ["Administrador"],
      },
    };
  },
  created() {
    this.fetchEmpleados();
  },
  computed: {
    filteredCargos() {
      return this.newEmpleado.area_empleados
        ? this.cargos[this.newEmpleado.area_empleados]
        : [];
    },
  },
  methods: {
    async fetchEmpleados() {
      try {
        this.cargando = true;
        const response = await EmpleadoService.getEmpleados();
        this.empleados = response.data.data;

        this.$nextTick(() => {
          const table = $(this.$refs.itemtabla);

          if ($.fn.dataTable.isDataTable(table)) {
            table.DataTable().destroy();
          }

          // Inicializar DataTables solo si hay empleados
          if (this.empleados.length > 0) {
            table.DataTable({
              order: [[0, "desc"]],
              pageLength: 20,
            });
          }
        });
      } catch (error) {
        console.error("Error fetching empleados:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Empleados.",
        });
      } finally {
        this.cargando = false;
      }
    },
    formatCode(code) {
      const formattedCode = code.toString().padStart(6, "0");
      return `${formattedCode}`;
    },

    refreshData() {
      this.$router.go();
    },

    verDetalle(id) {
      this.$router.push({ name: "DetallesEmpleado", params: { id } });
    },

    async createEmpleado() {
      try {
        if (this.newEmpleado.area_empleados === "Administrador") {
          this.newEmpleado.codigo_empleado = "ADMIN";
        } else {
          this.newEmpleado.codigo_empleado =
            this.newEmpleado.area_empleados === "Ventas" ? "COM" : "INV";
        }

        const personaResponse = await PersonaService.createPersona(
          this.newPersona
        );
        this.newEmpleado.id_personas = personaResponse.data.data.id;
        await EmpleadoService.createEmpleado(this.newEmpleado);

        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.log(error);
      }
    },
    async updateEmpleado() {
      try {
        const personaResponse = await PersonaService.updatePersona(
          this.selectedEmpleado.persona.id,
          this.newPersona
        );
        console.log(personaResponse);

        this.newEmpleado.id_personas = personaResponse.data.persona.id;
        console.log(this.newEmpleado);

        await EmpleadoService.updateEmpleado(
          this.selectedEmpleado.id,
          this.newEmpleado
        );
        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Lead actualizado!!",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.log("fail");
      }
    },
    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(empleado) {
      console.log(empleado);

      this.selectedEmpleado = empleado;

      if (empleado) {
        this.newPersona = {
          nombres_completos_persona: empleado.persona.nombres_completos_persona,
          celular_persona: empleado.persona.celular_persona,
          email_persona: empleado.persona.email_persona,
          departamento_persona: empleado.persona.departamento_persona,
          provincia_persona: empleado.persona.provincia_persona,
          distrito_persona: empleado.persona.distrito_persona,
          dni_persona: empleado.persona.dni_persona,
          direccion_persona: empleado.persona.direccion_persona,
          cumpleanos: empleado.persona.cumpleanos,
        };

        this.newEmpleado = {
          area_empleados: empleado.area_empleados,
          cargo: empleado.cargo,
          especialidad: empleado.especialidad,
          numero_cuenta_bancaria_empleado:
            empleado.numero_cuenta_bancaria_empleado,
          sistema_pensiones_empleado: empleado.sistema_pensiones_empleado,
          afp_empleado: empleado.afp_empleado,
          centro_estudios_empleado: empleado.centro_estudios_empleado,
          profesion_empleado: empleado.profesion_empleado,
          inicio_contrato_empleado: empleado.inicio_contrato_empleado,
          fin_contrato_empleado: empleado.fin_contrato_empleado,
          jornada_laboral_empleado: empleado.jornada_laboral_empleado,
          modalidad_contrato_empleado: empleado.modalidad_contrato_empleado,
          estado_emplreado: empleado.estado_emplreado,
        };
      } else {
        // Valores por defecto en caso de crear un nuevo empleado
        this.newPersona = {
          nombres_completos_persona: "",
          celular_persona: "",
          email_persona: "",
          departamento_persona: "",
          provincia_persona: "",
          distrito_persona: "",
          dni_persona: "",
          direccion_persona: "",
          password: "",
        };

        this.newEmpleado = {
          area_empleados: "",
          cargo: "",
          especialidad: "",
          numero_cuenta_bancaria_empleado: "",
          sistema_pensiones_empleado: "",
          afp_empleado: "",
          centro_estudios_empleado: "",
          profesion_empleado: "",
          inicio_contrato_empleado: "",
          fin_contrato_empleado: "",
          jornada_laboral_empleado: "",
          modalidad_contrato_empleado: "",
          estado_emplreado: "",
        };
      }

      this.showEditModal = true;
    },
    clearCargo() {
      this.newEmpleado.cargo = ""; // Limpiar el cargo cuando se cambia el área
    },
    closeModal() {
      this.showCreateModal = false;
      this.showEditModal = false;
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          EmpleadoService.deleteEmpleado($id);
          this.refreshData();
        }
      });
    },
  },
};
</script>

<style lang="">
</style>