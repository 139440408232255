<template>
  <div>
    <div class="card p-5">
      <div class="card-head">
        <h3>Calendario de seguimiento</h3>
      </div>
      <div class="card-body">
        <Calendario
          :fechasPago="calendarioCuotas"
          :seguimientoComercial="calendarioSeguimientos"
          :tareasProyecto="calendariosTareaProyecto"
          :cumpleanios="calendarioCumpleanios"
          :enviosCliente="calendarioEnviosClientes"
          :finContratos="calendarioFinContrato"
        />
      </div>
    </div>
  </div>
</template>
  
  <script>
import CronogramaService from "../../services/CronogramaService";
import EmpleadoService from "../../services/EmpleadoService";
import EnvioClienteService from "../../services/EnvioClienteService";
import PersonaService from "../../services/PersonaService";
import SeguimientoService from "../../services/SeguimientoService";
import TareaProyectoService from "../../services/TareaProyectoService";
import Calendario from "./Calendario.vue";

export default {
  components: {
    Calendario,
  },
  data() {
    return {
      calendarioCuotas: [],
      calendarioSeguimientos: [],
      calendariosTareaProyecto: [],
      calendarioCumpleanios: [],
      calendarioEnviosClientes: [],
      calendarioFinContrato: [],
    };
  },
  created() {
    const user = JSON.parse(localStorage.getItem("user"));

    if (
      user.empleado.area_empleados === "Administrador" ||
      user.empleado.area_empleados === "Ventas"
    ) {
      //Ventas
      this.fetchPagos();
      this.fetchSeguimiento();
    }
    if (
      user.empleado.area_empleados === "Administrador" ||
      user.empleado.area_empleados === "Academico"
    ) {
      //Academico
      this.fetchaTareasProyecto();
    }
    if (
      user.empleado.cargo === "Jefe de Investigación" ||
      user.empleado.area_empleados === "Administrador"
    ) {
      this.fetchEnviosClientes();
    }
    //Cumpleanios
    this.fetchCumpleanios();

    //Fin de Contratos:
    this.fetchEmpleados();
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user")) || null;
    },
  },

  methods: {
    //Datos para Ventas
    async fetchPagos() {
      try {
        const responseCronogramas = await CronogramaService.getCronogramas();
        const cronogramas = responseCronogramas.data.data;

        if (
          this.user &&
          (this.user.empleado.cargo === "Jefa Comercial" ||
            this.user.empleado.cargo === "Administrador")
        ) {
          this.calendarioCuotas = cronogramas.filter(
            (cronograma) => cronograma.cotizacion.venta != null
          );
        } else {
          this.calendarioCuotas = cronogramas.filter(
            (cronograma) =>
              cronograma.cotizacion.venta != null &&
              cronograma.cotizacion.id_empleados === this.user.empleado.id
          );
        }
      } catch (error) {
        console.log("Error al obtener los cronogramas:", error);
      }
    },

    async fetchSeguimiento() {
      try {
        const responseSeguimiento = await SeguimientoService.getSeguimiento();
        const seguimientos = responseSeguimiento.data.data;

        // Verificamos si el usuario existe y su cargo
        if (
          this.user &&
          (this.user.empleado.cargo === "Jefa Comercial" ||
            this.user.empleado.cargo === "Administrador")
        ) {
          this.calendarioSeguimientos = seguimientos;
        } else {
          this.calendarioSeguimientos = seguimientos.filter(
            (seguimiento) => seguimiento.empleado.id === this.user.empleado.id
          );
        }
      } catch (error) {
        console.log("Error al obtener los seguimientos:", error);
      }
    },

    //Datos para Academico
    async fetchaTareasProyecto() {
      try {
        const responseTareasProyecto =
          await TareaProyectoService.getTareaProyectos();
        const tareasProyecto = responseTareasProyecto.data.data;
        if (
          this.user &&
          (this.user.empleado.cargo === "Jefe de Investigación" ||
            this.user.empleado.cargo === "Administrador")
        ) {
          this.calendariosTareaProyecto = tareasProyecto;
        } else {
          this.calendariosTareaProyecto = tareasProyecto.filter(
            (tarea) =>
              tarea.empleados_proyectos.id_empleados === this.user.empleado.id
          );
        }
      } catch (error) {
        console.log(error);
      }
    },

    async fetchEnviosClientes() {
      try {
        const responsteEnviosClientes =
          await EnvioClienteService.getEnvioClientes();
        this.calendarioEnviosClientes = responsteEnviosClientes.data.data;
      } catch (error) {
        console.log(error);
      }
    },
    //Datos para cumpleaños
    async fetchCumpleanios() {
      try {
        const responsePersonas = await PersonaService.getPersonas();
        const fechasCumpleanios = responsePersonas.data.data;
        this.calendarioCumpleanios = fechasCumpleanios;
      } catch (error) {
        console.log(error);
      }
    },

    async fetchEmpleados() {
      try {
        const responseFinContrato = await EmpleadoService.getEmpleados();
        const empleados = responseFinContrato.data.data;

        const cargo = this.user.empleado.cargo;
        switch (cargo) {
          case "Administrador":
            this.calendarioFinContrato = empleados;
            break;
          case "Jefa Comercial":
            this.calendarioFinContrato = empleados.filter(
              (contrato) => contrato.area_empleados === "Ventas"
            );
            break;
          case "Jefe de Investigación":
            this.calendarioFinContrato = empleados.filter(
              (contrato) => contrato.area_empleados === "Academico"
            );
            break;
          default:
            break;
        }
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
  
  <style>
</style>
  