<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h4>Detalles del Proyecto</h4>
          <BackButton text="Atrás" />
        </div>

        <div class="card-body lead-details-container">
          <div class="row align-items-center">
            <div class="col-4 d-flex flex-column justify-content-between">
              <!-- Usamos d-flex y flex-column para organizar el contenido -->
              <div>
                <div v-if="selectedProyecto">
                  <p>
                    <strong>Nombre Grupal:</strong>
                    {{ selectedProyecto.nombre_grupal }}
                  </p>
                  <p>
                    <strong>Servicio:</strong>
                    {{
                      selectedProyecto && selectedProyecto.servicio
                        ? selectedProyecto.servicio.nombre_servicio
                        : "No disponible"
                    }}
                  </p>
                  <p>
                    <strong>Título de Investigación:</strong>
                    {{ selectedProyecto.titulo_investigacion }}
                  </p>
                  <p>
                    <strong>Fecha Recepción Contrato:</strong>
                    {{ selectedProyecto.fecha_recepcion_contrato || "-" }}
                  </p>
                  <p>
                    <strong>Estado del Proyecto:</strong>
                    {{ selectedProyecto.estado_proyecto || "-" }}
                  </p>
                  <p>
                    <strong>Jefe de Proyecto:</strong>
                    {{ jefeDeAsesores }}
                  </p>
                </div>

                <div v-else>
                  <p>Cargando detalles del Proyecto...</p>
                </div>
              </div>

              <div class="mt-auto" v-if="isUserAuthorized">
                <button
                  @click="openCreateEmpleadoProyecto"
                  class="btn btn-primary me-4"
                >
                  Asignar Asesor
                </button>
                <button @click="openCreateModal" class="btn btn-primary">
                  Nueva Tarea
                </button>
              </div>
            </div>

            <div class="col-8 d-flex flex-column">
              <div class="table-responsive flex-grow-1" v-if="isUserAuthorized">
                <table
                  class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
                  v-if="cronogramasFiltrados.length > 0"
                >
                  <caption>
                    Seguimiento de pagos
                  </caption>
                  <thead class="text-center">
                    <tr>
                      <th>Cuota</th>
                      <th>Fecha Programada</th>
                      <th>Fecha Real</th>
                      <th>Porcentaje a Pagar</th>
                      <th>Porcentaje Pagado</th>
                      <th>Estado</th>
                    </tr>
                  </thead>
                  <tbody class="text-center">
                    <tr
                      v-for="cronograma in cronogramasFiltrados"
                      :key="cronograma.id"
                    >
                      <td>{{ cronograma.cuota }}</td>
                      <td>{{ cronograma.fecha_programada }}</td>
                      <td>{{ cronograma.fecha_real || "No disponible" }}</td>
                      <td>
                        {{
                          cronograma.porcentaje_asignado + "%" || "No Pagado"
                        }}
                      </td>
                      <td>
                        {{
                          cronograma.porcentaje_real !== null &&
                          cronograma.porcentaje_real !== undefined
                            ? cronograma.porcentaje_real + "%"
                            : "No Pagado"
                        }}
                      </td>
                      <td>
                        <EstadoCronogramaColor
                          :text="cronograma.estado_cronograma"
                          style="border-radius: 15px"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>

                <p v-else class="text-center">Cargando cronograma...</p>
              </div>
              <p v-else class="text-center"></p>
            </div>
          </div>
        </div>

        <div class="card-body">
          <!-- Tabla de tareas -->
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
              v-if="cronogramasFiltrados.length > 0"
            >
              <caption>
                Seguimiento de Tareas
              </caption>
              <thead class="text-center">
                <tr>
                  <th></th>
                  <th>Asesor</th>
                  <th>Tarea Asignada</th>
                  <th>Estado de Tarea</th>
                  <th>Fecha de Inicio</th>
                  <th>Plazo</th>
                  <th>Fecha de Finalización</th>
                  <th>Fecha Real</th>
                  <th>Porcentaje Relativo</th>
                  <th>Descuento</th>
                  <th>Motivo</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="tarea in tareasProyecto" :key="tarea.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                      v-if="isUserAuthorized"
                    >
                      <button
                        @click="openEditModal(tarea)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(tarea.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                    <div v-else>
                      <i
                        class="fas fa-info-circle"
                        style="color: green"
                        aria-hidden="true"
                      ></i>
                    </div>
                  </td>
                  <td>
                    {{
                      tarea.empleados_proyectos.empleado.persona
                        .nombres_completos_persona
                    }}
                  </td>
                  <td>{{ tarea.asignacion_tareas_proyectos }}</td>
                  <td>{{ tarea.estado_tareas_proyecto }}</td>
                  <td>{{ tarea.fecha_inicio }}</td>
                  <td>{{ tarea.dias }}</td>
                  <td>{{ tarea.fecha_fin_programado }}</td>
                  <td>{{ tarea.fecha_fin_real }}</td>
                  <td>
                    {{ tarea.valor_final ? tarea.valor_final + "%" : "" }}
                  </td>
                  <td>
                    {{
                      formatDescuento(
                        tarea.rendimiento_trabajador.length > 0
                          ? tarea.rendimiento_trabajador[0].descuento
                          : null
                      )
                    }}
                  </td>

                  <td>{{ tarea.motivo }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center">Cargando tareas...</p>
          </div>
          <br />

          <!-- Tabla de Envios -->
          <div class="table-responsive" v-if="isUserAuthorized">
            <div class="mt-auto">
              <button
                @click="openModalForCreation"
                class="btn btn-primary me-4"
              >
                Crear Reporte
              </button>
            </div>

            <table
              class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
              v-if="fetchEnvios.length > 0"
            >
              <caption>
                Seguimiento de envios
              </caption>
              <thead class="text-center">
                <tr>
                  <th></th>
                  <th>Fecha de Envio</th>
                  <th>Estado de Reporte</th>
                  <th>Asunto Correo</th>
                  <th>Tipo de Envio</th>
                  <th>Contenido</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="envio in fetchEnvios" :key="envio.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                      v-if="isUserAuthorized"
                    >
                      <button
                        @click="openModalForEditing(envio)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacionReporte(envio.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>
                    {{ envio.fecha_envio }}
                  </td>
                  <td>
                    {{ envio.estado_reporte }}
                  </td>
                  <td>
                    {{ envio.asunto_correo }}
                  </td>
                  <td>
                    {{ envio.tipo_envio }}
                  </td>
                  <td>
                    <ul>
                      <li
                        v-for="reporte in envio.reportes_clientes"
                        :key="reporte.id"
                      >
                        {{ reporte.tareaProyecto?.asignacion_tareas_proyectos }}
                      </li>
                    </ul>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center">Cargando...</p>
          </div>
        </div>
      </div>
    </div>

    <!-- Editar Tarea de Proyecto -->
    <Modal :isVisible="showEditTareaModal" @close="closeModal">
      <div>
        <h4>Editar Tarea del Proyecto</h4>
      </div>
      <form @submit.prevent="guardarTarea">
        <div class="row">

          <div class="col">
            <!-- Fecha Fin Programado -->
            <div>
              <label for="fecha_fin_programado" class="form-label"
                >Fecha Fin Programado:</label
              >
              <input
                v-model="newTarea.fecha_fin_programado"
                type="date"
                id="fecha_fin_programado"
                class="form-control"
                readonly
              />
            </div>
            <!-- Fecha Fin Real -->
            <div>
              <label for="fecha_fin_real" class="form-label"
                >Fecha Fin Real:</label
              >
              <input
                v-model="newTarea.fecha_fin_real"
                type="date"
                id="fecha_fin_real"
                class="form-control"
              />
            </div>
            <!-- Estado de la Tarea -->
            <div>
              <label for="estado_tareas_proyecto" class="form-label"
                >Estado:</label
              >
              <select
                v-model="newTarea.estado_tareas_proyecto"
                id="estado_tareas_proyecto"
                class="form-control"
              >
                <option value="Sin iniciar">Sin iniciar</option>
                <option value="En proceso">En proceso</option>
                <option value="Observado">Observado</option>
                <option value="Terminado">Terminado</option>
              </select>
            </div>
            <!-- Motivo -->
            <div>
              <label for="motivo" class="form-label">Motivo:</label>
              <select
                v-model="newTarea.motivo"
                id="motivo"
                class="form-control"
                @change="updateDescuento"
              >
                <option value="" disabled>Selecciona un motivo</option>
                <option
                  v-for="item in motivos"
                  :key="item.motivo"
                  :value="item.motivo"
                >
                  {{ item.motivo }}
                </option>
              </select>
            </div>

            <!-- Descuento (%) -->
            <div>
              <label for="descuento" class="form-label">Descuento (%):</label>
              <input
                v-model.number="newRendimientoTrabajador.descuento"
                type="number"
                step="0.01"
                id="descuento"
                class="form-control"
              />
            </div>
          </div>
        </div>

        <!-- Botón de Guardar Tarea -->
        <div class="mt-4">
          <button type="submit" class="btn btn-primary">Guardar Tarea</button>
        </div>
      </form>
    </Modal>

    <!-- CrearTarea -->
    <Modal :isVisible="showCreateTareaModal" @close="closeModal">
      <div>
        <h4>Crear Tarea</h4>
        <form @submit.prevent="createTarea">
          <!-- Tarea -->
          <div class="row">

            <!-- Asignación de Empleados -->
            <div class="col">
              <label for="asignacion_empleados_proyectos" class="form-label">
                Asignación de Asesor:
              </label>
              <select
                v-model="newTarea.id_empleados_proyectos"
                id="asignacion_empleados_proyectos"
                class="form-control"
                required
              >
                <option value="" disabled>Seleccione un asesor</option>
                <option
                  v-for="empleadoProyecto in filteredEmpleadosProyectos"
                  :key="empleadoProyecto.id"
                  :value="empleadoProyecto.id"
                >
                  {{
                    empleadoProyecto.empleado.persona
                      .nombres_completos_persona +
                    " - " +
                    empleadoProyecto.rol
                  }}
                </option>
              </select>
            </div>
          </div>

          <!-- Formulario de ingreso de Datis -->
          <div>
            <!-- Botón para agregar una nueva tarea -->
            <div class="mt-3">
              <button @click="agregarTarea" class="btn btn-success">
                <i class="fas fa-plus"></i>
              </button>
            </div>

            <!-- Iterar sobre las tareas para mostrar el formulario para cada una -->
            <div v-for="(tarea, index) in tareas" :key="index" class="row">
              <!-- Asignación de Tareas -->
              <div class="col">
                <label for="asignacion_tareas_proyectos" class="form-label"
                  >Asignación de Tareas:</label
                >
                <select
                  v-model="tarea.asignacion_tareas_proyectos"
                  id="asignacion_tareas_proyectos"
                  class="form-control"
                  required
                >
                  <option value="" disabled>Seleccione una asignación</option>
                  <option
                    v-for="asignacion in asignaciones"
                    :key="asignacion.id"
                    :value="asignacion.id"
                  >
                    {{ asignacion.nombre_asignacion }}
                  </option>
                </select>
              </div>

              <!-- Fecha de Inicio -->
              <div class="col">
                <label for="fecha_inicio" class="form-label"
                  >Fecha de Inicio:</label
                >
                <input
                  v-model="tarea.fecha_inicio"
                  type="date"
                  id="fecha_inicio"
                  class="form-control"
                  @change="updateFechaFinProgramado2(index)"
                  required
                />
              </div>

              <!-- Días -->
              <div class="col">
                <label for="dias" class="form-label">Días:</label>
                <input
                  v-model.number="tarea.dias"
                  type="number"
                  id="dias"
                  class="form-control"
                  @change="updateFechaFinProgramado2(index)"
                  required
                />
              </div>

              <!-- Fecha Fin Programado -->
              <div class="col">
                <label for="fecha_fin_programado" class="form-label"
                  >Fecha Fin Programado:</label
                >
                <input
                  v-model="tarea.fecha_fin_programado"
                  type="date"
                  id="fecha_fin_programado"
                  class="form-control"
                  disabled
                />
              </div>

              <!-- Botón para eliminar la tarea -->
              <div class="col">
                <button @click="eliminarTarea(index)" class="btn btn-danger">
                  <i class="fas fa-trash-alt"></i>
                </button>
              </div>
            </div>
          </div>

          <!-- Botón para guardar -->
          <button type="submit" class="btn btn-primary mt-3">
            Crear Tarea
          </button>
        </form>
      </div>
    </Modal>

    <!-- Seleccionar Asesores -->
    <Modal :isVisible="showCreateEmpleadoProyecto" @close="closeModal">
      <div>
        <h4>Asignar Asesores</h4>
        <form @submit.prevent="createEmpleadoProyecto">
          <!-- Asignación de Empleados -->
          <div class="col">
            <label for="asignacion_empleados_proyectos" class="form-label">
              Asignación de Asesor:
            </label>
            <select
              v-model="newEmpleadoProyecto.id_empleados"
              id="asignacion_empleados_proyectos"
              class="form-control"
              required
            >
              <option value="" disabled>Seleccione un asesor</option>
              <option
                v-for="empleado in filteredEmpleados"
                :key="empleado.id"
                :value="empleado.id"
              >
                {{
                  empleado.persona.nombres_completos_persona +
                  " - " +
                  empleado.cargo
                }}
              </option>
            </select>
          </div>
          <!-- Botón para guardar -->
          <button type="submit" class="btn btn-primary mt-3">Asignar</button>
        </form>
      </div>
    </Modal>

    <!-- Modal para Crear o Editar Reporte -->
    <Modal :isVisible="showCreateReporteModal" @close="closeModal">
      <div>
        <!-- Título cambia según el modo -->
        <h4>{{ isEditing ? "Editar Reporte" : "Crear Reporte" }}</h4>
        <form
          @submit.prevent="
            isEditing ? updateReporteCliente() : createReporteCliente()
          "
        >
          <!-- Seccion Envio Cliente -->
          <div class="row">
            <!-- Fecha de Envio -->
            <div class="col">
              <label for="fecha_envio" class="form-label"
                >Fecha de Envio:</label
              >
              <input
                v-model="newEnvioCliente.fecha_envio"
                type="date"
                id="fecha_envio"
                class="form-control"
                required
              />
            </div>
            <!-- Estado Reporte -->
            <div class="col">
              <label for="estado_reporte" class="form-label"
                >Estado Reporte:</label
              >
              <select
                v-model="newEnvioCliente.estado_reporte"
                id="estado_reporte"
                class="form-control"
                required
              >
                <option value="" disabled>Seleccione un estado</option>
                <option value="Enviado">Enviado</option>
                <option value="Sin enviar">Sin enviar</option>
              </select>
            </div>
          </div>

          <div class="row">
            <!-- Asunto correo -->
            <div class="col">
              <label for="asunto_correo" class="form-label"
                >Asunto Correo:</label
              >
              <input
                v-model="newEnvioCliente.asunto_correo"
                type="text"
                id="asunto_correo"
                class="form-control"
                required
              />
            </div>

            <!-- Tipo Envio -->
            <div class="col">
              <label for="tipo_envio" class="form-label">Tipo de Envio:</label>
              <select
                v-model="newEnvioCliente.tipo_envio"
                id="tipo_envio"
                class="form-control"
                required
              >
                <option value="" disabled>Seleccione un tipo de envío</option>
                <option value="Envios Ordinarios">Envios Ordinarios</option>
                <option value="Envios Semanales">Envios Semanales</option>
                <option value="Envios de una Seccion">
                  Envios de una Seccion
                </option>
              </select>
            </div>
          </div>

          <div class="row" v-if="!isEditing">
            <!-- Selección de Tareas Proyecto (multi-selección) -->
            <div class="col">
              <label for="id_tareas_proyectos" class="form-label"
                >Tareas:</label
              >
              <select
                v-model="selectedTareas"
                id="id_tareas_proyectos"
                class="form-control"
                multiple
              >
                <option value="" disabled>Seleccione asignaciones</option>
                <option
                  v-for="tarea in tareasProyecto"
                  :key="tarea.id"
                  :value="tarea.id"
                >
                  {{ tarea?.asignacion_tareas_proyectos || "-" }}
                </option>
              </select>
            </div>
          </div>

          <!-- Botón cambia según el modo -->
          <button type="submit" class="btn btn-primary mt-3">
            {{ isEditing ? "Actualizar Reporte" : "Crear Reporte" }}
          </button>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import ProyectoService from "../../services/ProyectoService";
import TareaProyectoService from "../../services/TareaProyectoService";
import $ from "jquery";
import Modal from "./Modal.vue";
import BackButton from "./BackButton.vue";
import AsignacionService from "../../services/AsignacionService";
import EmpleadoProyectoService from "../../services/EmpleadoProyectoService";
import ReportesClienteService from "../../services/ReportesClienteService";
import RendimientoTrabajadoresService from "../../services/RendimientoTrabajadoresService";
import EmpleadoService from "../../services/EmpleadoService";
import CronogramaService from "../../services/CronogramaService";
import Swal from "sweetalert2";
import EstadoCronogramaColor from "./EstadoCronogramaColor.vue";
import EnvioClienteService from "../../services/EnvioClienteService";

export default {
  name: "DetallesProyecto",
  components: {
    Modal,
    BackButton,
    EstadoCronogramaColor,
  },

  data() {
    return {
      cargando: false,
      showEditTareaModal: false,
      showCreateTareaModal: false,
      showCreateEmpleadoProyecto: false,
      showCreateReporteModal: false,
      selectedTareaId: null,
      selectedTarea: null,
      isEditing: false,
      selectedAsignaciones: [],
      reportesCliente: [],
      selectedProyecto: {
        nombre_grupal: "",
        titulo_investigacion: "",
        fecha_recepcion_contrato: "",
        estado_proyecto: "",
      },
      proyectos: {},
      tareasProyecto: [],
      tareas: [],
      filteredEmpleadosProyectos: [],
      filteredEmpleados: [],
      empleadosProyectos: [],
      cronogramasFiltrados: [],
      newTarea: {
        id_empleados_proyectos: null,
        fase_empleados_proyectos: "Proyecto",
        asignacion_tareas_proyectos: "",
        fecha_inicio: null,
        dias: 0,
        fecha_fin_programado: null,
        fecha_fin_real: null,
        estado_tareas_proyecto: "Sin iniciar",
        motivo: "",
        porcentaje_descuento: 0.0,
        valor_final: 0.0,
      },

      //aqui
      newEnvioCliente: {
        fecha_envio: "",
        estado_reporte: "",
        asunto_correo: "",
        tipo_envio: "",
        id_proyecto: "",
      },
      selectedTareas: [],
      newReporteCliente: {
        id_tareas_proyectos: null,
        id_envio_cliente: null,
      },
      //aqui
      fetchEnvios: [],
      rendimientosTrabajadores: [],
      newRendimientoTrabajador: {
        id_tareas_proyectos: null,
        id_empleados: null,
        descuento: 0.0,
      },
      newEmpleadoProyecto: {
        id_empleados: null,
        id_proyectos: null,
        rol: "Asesor",
      },
      motivos: [
        { motivo: "Mala Redaccion 1", descuento: 3 },
        { motivo: "Mala Redaccion 2", descuento: 6 },
        { motivo: "Mala Redaccion 3", descuento: 8 },
        { motivo: "Fallas Ortograficas 1", descuento: 2 },
        { motivo: "Fallas Ortograficas 2", descuento: 5 },
        { motivo: "Fallas Ortograficas 3", descuento: 7 },
        { motivo: "Porcentaje de Similitud 1", descuento: 8 },
        { motivo: "Porcentaje de Similitud 2", descuento: 10 },
        { motivo: "Porcentaje de Similitud 3", descuento: 12 },
        { motivo: "Porcentaje de Similitud 4", descuento: 15 },
        { motivo: "Fallas de Fondo", descuento: 7 },
        { motivo: "Fallas de Forma", descuento: 5 },
        { motivo: "Incompleto 1", descuento: 5 },
        { motivo: "Incompleto 2", descuento: 8 },
        { motivo: "Incompleto 3", descuento: 12 },
        { motivo: "Incompleto 4", descuento: 15 },
        { motivo: "Varios", descuento: 0 },
      ],

      feriadosPeru: [
        "01-01", // Año Nuevo
        "04-17", // Jueves Santo
        "04-18", // Viernes Santo
        "05-01", // Día del Trabajo
        "06-07", // Batalla de Arica y día de la bandera
        "06-29", // San Pedro y San Pablo
        "07-23", // Día de la Fuerza Aérea del Perú
        "07-28", // Fiestas Patrias
        "07-29", // Fiestas Patrias
        "08-06", // Batalla de Junín
        "08-30", // Santa Rosa de Lima
        "10-08", // Combate de Angamos
        "11-01", // Día de Todos los Santos
        "12-08", // Inmaculada Concepción
        "12-09", // Batalla de Ayacucho
        "12-25", // Navidad

        // Domingos desde el 13 de octubre de 2024 hasta el 12 de octubre de 2025
        "10-13",
        "10-20",
        "10-27",
        "11-03",
        "11-10",
        "11-17",
        "11-24",
        "12-01",
        "12-08",
        "12-15",
        "12-22",
        "12-29",
        "01-05",
        "01-12",
        "01-19",
        "01-26",
        "02-02",
        "02-09",
        "02-16",
        "02-23",
        "03-01",
        "03-08",
        "03-15",
        "03-22",
        "03-29",
        "04-05",
        "04-12",
        "04-19",
        "04-26",
        "05-03",
        "05-10",
        "05-17",
        "05-24",
        "05-31",
        "06-07",
        "06-14",
        "06-21",
        "06-28",
        "07-05",
        "07-12",
        "07-19",
        "07-26",
        "08-02",
        "08-09",
        "08-16",
        "08-23",
        "08-30",
        "09-06",
        "09-13",
      ],
    };
  },
  watch: {
    "newTarea.fecha_inicio": function () {
      this.updateFechaFinProgramado();
    },

    "newTarea.dias": function () {
      this.updateFechaFinProgramado();
    },
  },
  computed: {
    jefeDeAsesores() {
      const jefe = this.filteredEmpleadosProyectos.find(
        (empleado) => empleado.rol === "Jefe de Asesores"
      );
      return jefe
        ? jefe.empleado.persona.nombres_completos_persona
        : "No asignado";
    },
    isUserAuthorized() {
      const user = JSON.parse(localStorage.getItem("user"));
      return (
        user &&
        (user.empleado.area_empleados === "Administrador" ||
          user.empleado.cargo === "Jefe de Investigación")
      );
    },
  },

  created() {
    this.fetchProyecto();
    this.fetchEmpleadosProyectos();
    this.fetchRendimientoTrabajadores();
    this.fetchEmpleados();
    this.fetchEstadoVentas();
    this.fetchTareasProyecto();
    this.fetchEnviosClientes();
  },
  methods: {
    async fetchTareasProyecto() {
      try {
        this.cargado = true;
        const id_proyecto = this.$route.params.id;
        const responseTareasProyecto =
          await TareaProyectoService.getTareaProyectos();

        // Obtener el usuario del localStorage
        const user = JSON.parse(localStorage.getItem("user"));

        // Filtrar tareas según el rol del usuario
        if (
          user.empleado.area_empleados === "Administrador" ||
          user.empleado.cargo === "Jefe de Investigación"
        ) {
          // Cargar todas las tareas si el usuario es Administrador o Jefe de Investigación
          this.tareasProyecto = responseTareasProyecto.data.data.filter(
            (tarea) => tarea.empleados_proyectos.proyecto.id == id_proyecto
          );
        } else {
          // Cargar solo las tareas asignadas al empleado y del proyecto en la ruta actual
          this.tareasProyecto = responseTareasProyecto.data.data.filter(
            (tarea) =>
              tarea.empleados_proyectos.empleado.id == user.empleado.id &&
              tarea.empleados_proyectos.proyecto.id == id_proyecto
          );
        }

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 20, // Mostrar 20 filas por página
          });
        });
      } catch (error) {
        console.error("Error fetching tareas:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las Tareas.",
        });
      } finally {
        this.cargando = false;
      }
    },

    async fetchEmpleadosProyectos() {
      try {
        const id_proyecto = this.$route.params.id;
        const responseEmpleadosProyectos =
          await EmpleadoProyectoService.getEmpleadosProyectos();
        this.empleadosProyectos = responseEmpleadosProyectos.data.data;
        this.filteredEmpleadosProyectos =
          responseEmpleadosProyectos.data.data.filter(
            (empleado) => empleado.id_proyectos == id_proyecto
          );
      } catch (error) {
        console.error("Error fetching asignaciones:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las asignaciones.",
        });
      }
    },

    async fetchProyecto() {
      try {
        const proyectoId = this.$route.params.id;
        this.cargando = true;
        const response = await ProyectoService.getProyecto(proyectoId);
        this.selectedProyecto = response.data;

        if (this.selectedProyecto && this.selectedProyecto.id) {
          await this.fetchAsignaciones();
        } else {
          console.warn("selectedProyecto no tiene un ID válido.");
        }
      } catch (error) {
        console.error("Error fetching proyecto details:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los detalles del proyecto.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async fetchAsignaciones() {
      try {
        const responseAsignacion = await AsignacionService.getAsignaciones();

        this.asignaciones = responseAsignacion.data.data.filter(
          (asignacion) =>
            asignacion.id_servicios == this.selectedProyecto.id_servicios
        );
      } catch (error) {
        console.error("Error fetching asignaciones:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar las asignaciones.",
        });
      }
    },

    async fetchEmpleados() {
      try {
        const responseEmpleados = await EmpleadoService.getEmpleados();

        this.filteredEmpleados = responseEmpleados.data.data.filter(
          (empleado) =>
            empleado.area_empleados === "Academico" &&
            empleado.estado_emplreado === "ACTIVO"
        );
      } catch (error) {
        console.error("Error fetching reportes de cliente:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los reportes de cliente.",
        });
      }
    },

    async fetchEstadoVentas() {
      try {
        const id_proyecto = this.$route.params.id;
        const responseProyecto = await ProyectoService.getProyecto(id_proyecto);
        const id_cot = responseProyecto.data.venta.id_cotizaciones;

        const cronogramaResponse = await CronogramaService.getCronogramas();
        this.cronogramasFiltrados = cronogramaResponse.data.data.filter(
          (cronograma) => cronograma.id_cotizaciones === id_cot
        );
      } catch (error) {
        console.error("Error fetching cronogramas:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los cronogramas.",
        });
      }
    },

    async fetchEnviosClientes() {
      try {
        const id_proyecto = this.$route.params.id;
        const responseEnvios = await EnvioClienteService.getEnvioClientes();
        this.fetchEnvios = responseEnvios.data.data.filter((envio) => {
          const reportes = envio.reportes_clientes || [];
          if (reportes.length === 0) {
            return false;
          }

          return reportes.some((reporte) => {
            if (
              reporte.tareaProyecto &&
              reporte.tareaProyecto.empleados_proyectos
            ) {
              const proyectoId =
                reporte.tareaProyecto.empleados_proyectos.id_proyectos;
              return String(proyectoId) === String(id_proyecto);
            }
            return false;
          });
        });
      } catch (error) {
        console.error("Error fetching envios:", error);
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los envios.",
        });
      }
    },

    async createReporteCliente() {
      try {
        this.newEnvioCliente.id_proyecto = this.$route.params.id;
        console.log(this.newEnvioCliente);
        const responseEnvioCliente =
          await EnvioClienteService.createEnvioCliente(this.newEnvioCliente);

        const id_envioCliente = responseEnvioCliente.data.data.id;

        this.newReporteCliente.id_envio_cliente = id_envioCliente;

        for (let i = 0; i < this.selectedTareas.length; i++) {
          this.newReporteCliente.id_tareas_proyectos = this.selectedTareas[i];

          const responseReporte =
            await ReportesClienteService.createReporteCliente(
              this.newReporteCliente
            );
          console.log(responseReporte);
        }

        // Mostrar mensaje de éxito
        this.$swal({
          icon: "success",
          title: "Reporte Guardado!",
          text: "El reporte ha sido guardado exitosamente.",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error al crear el reporte de cliente:", error);

        // Mostrar mensaje de error al usuario
        this.$swal({
          icon: "error",
          title: "Error!",
          text: "Hubo un problema al guardar el reporte. Por favor, intenta nuevamente.",
        });
      }
    },

    async updateReporteCliente() {
      try {
        const responseEnvioCliente =
          await EnvioClienteService.updateEnvioCliente(
            this.newEnvioCliente.id,
            this.newEnvioCliente
          );
        console.log(responseEnvioCliente);

        this.$swal({
          icon: "success",
          title: "Reporte actualizado!",
          text: "El reporte ha sido actualizado!.",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error al actualizar:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al realizar la actualizacion. Por favor, intenta nuevamente.",
        });
      }
    },

    updateFechaFinProgramado() {
      if (this.newTarea.fecha_inicio && this.newTarea.dias) {
        const inicio = new Date(this.newTarea.fecha_inicio);
        inicio.setDate(inicio.getDate() + this.newTarea.dias);
        this.newTarea.fecha_fin_programado = inicio.toISOString().split("T")[0]; // Formato YYYY-MM-DD
      } else {
        this.newTarea.fecha_fin_programado = null;
      }
    },

    async guardarTarea() {
      try {
        this.newTarea.fase_empleados_proyectos = "Proyecto",
        await TareaProyectoService.updateTareaProyecto(
          this.selectedTarea.id,
          this.newTarea
        );
        await this.createOrUpdateRendimientoTrabajador();
        this.$swal({
          icon: "success",
          title: "¡Tarea Guardada!",
          text: "La tarea ha sido guardada exitosamente.",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error guardando la tarea:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al guardar la tarea. Por favor, intenta nuevamente.",
        });
      }
    },
    async createOrUpdateRendimientoTrabajador() {
      try {
        // Verificar si existe un rendimiento de trabajador para la tarea seleccionada

        const rendimientoExistente = this.rendimientosTrabajadores.find(
          (rendimiento) =>
            rendimiento.id_tareas_proyectos === this.selectedTarea.id
        );

        this.newRendimientoTrabajador.id_tareas_proyectos =
          this.selectedTarea.id;
        this.newRendimientoTrabajador.id_empleados =
          this.selectedTarea.empleados_proyectos.id_empleados;

        if (this.newRendimientoTrabajador.descuento >= 1) {
          this.newRendimientoTrabajador.descuento /= 100;
        }
        if (rendimientoExistente) {
          // Si existe un rendimiento, actualízalo
          await RendimientoTrabajadoresService.updateRendimientoTrabajador(
            rendimientoExistente.id,
            this.newRendimientoTrabajador
          );
          console.log(
            "Rendimiento de Trabajador actualizado:",
            this.newRendimientoTrabajador
          );
        } else {
          // Si no existe un rendimiento, crea uno nuevo
          this.newRendimientoTrabajador.id_tareas_proyectos =
            this.selectedTarea.id;
          await RendimientoTrabajadoresService.createRendimientoTrabajador(
            this.newRendimientoTrabajador
          );
          console.log(
            "Rendimiento de Trabajador creado:",
            this.newRendimientoTrabajador
          );
        }

        // Cerrar el modal o realizar otra acción si es necesario
        this.closeModal();
      } catch (error) {
        console.error(
          "Error al gestionar el rendimiento de trabajador:",
          error
        );
        // Mostrar notificación de error
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al gestionar el rendimiento del trabajador.",
        });
      }
    },

    async fetchRendimientoTrabajadores() {
      try {
        const responseRendimientoTrabajadores =
          await RendimientoTrabajadoresService.getRendimientoTrabajadores();
        this.rendimientosTrabajadores =
          responseRendimientoTrabajadores.data.data;
      } catch (error) {
        console.error("Error fetching rendimiento de trabajadores:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los rendimientos de trabajadores.",
        });
      }
    },
    getDescuentoTarea(idTarea) {
      const rendimiento = this.rendimientosTrabajadores.find(
        (rendimiento) => rendimiento.id_tareas_proyectos === idTarea
      );
      return rendimiento ? rendimiento.descuento : null;
    },

    refreshData() {
      this.$router.go();
    },
    openCreateModal() {
      this.showCreateTareaModal = true;
    },
    formatDescuento(descuento) {
      if (descuento) {
        return `${(descuento * 100).toFixed(0)}%`;
      }
      return "Sin descuento";
    },

    openEditModal(tarea) {
      this.selectedTarea = tarea;

      if (tarea) {
        this.newTarea = {
          id_empleados_proyectos: tarea.id_empleados_proyectos,
          asignacion_tareas_proyectos: tarea.asignacion_tareas_proyectos,
          fecha_inicio: tarea.fecha_inicio,
          dias: tarea.dias,
          fecha_fin_programado: tarea.fecha_fin_programado,
          fecha_fin_real: tarea.fecha_fin_real,
          estado_tareas_proyecto: tarea.estado_tareas_proyecto,
          motivo: tarea.motivo,
        };

        // Comprobamos que rendimiento_trabajador tiene al menos un elemento antes de acceder a descuento
        this.newRendimientoTrabajador.descuento =
          tarea.rendimiento_trabajador.length > 0
            ? tarea.rendimiento_trabajador[0].descuento * 100
            : 0.0; // Valor por defecto si no hay descuento
      } else {
        this.newTarea = {
          id_empleados_proyectos: null,
          fase_empleados_proyectos: "",
          asignacion_tareas_proyectos: "",
          fecha_inicio: null,
          dias: 0,
          fecha_fin_programado: null,
          fecha_fin_real: null,
          estado_tareas_proyecto: "",
          motivo: "",
        };
      }

      this.showEditTareaModal = true;
    },

    openCreateEmpleadoProyecto() {
      this.showCreateEmpleadoProyecto = true;
    },
    openModalForCreation() {
      this.isEditing = false;
      this.showCreateReporteModal = true;
    },

    openModalForEditing(envio) {
      this.isEditing = true;
      this.newEnvioCliente = { ...envio };
      this.showCreateReporteModal = true;
    },

    async createEmpleadoProyecto() {
      try {
        const id_proyecto = this.$route.params.id;
        this.newEmpleadoProyecto.id_proyectos = id_proyecto; // Corrige para establecer el id_proyecto
        await EmpleadoProyectoService.createEmpleadoProyecto(
          this.newEmpleadoProyecto
        );
        this.$swal({
          icon: "success",
          title: "Empleado Asignado!",
          text: "El empleado ha sido asignado exitosamente.",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error en la asignación:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al realizar la asignación. Por favor, intenta nuevamente.",
        });
      }
    },

    closeModal() {
      this.showEditTareaModal = false;
      this.showCreateTareaModal = false;
      this.showCreateEmpleadoProyecto = false;
      this.showCreateReporteModal = false;
      this.newTarea = {
        id_empleados_proyectos: null,
        asignacion_tareas_proyectos: "",
        fecha_inicio: null,
        dias: 0,
        fecha_fin_programado: null,
        fecha_fin_real: null,
        estado_tareas_proyecto: "",
        motivo: "",
        porcentaje_descuento: 0.0,
      };
    },
    confirmarEliminacion(id) {
      Swal.fire({
        title: "¿Estás seguro de eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          TareaProyectoService.deleteTareaProyecto(id);
          this.refreshData();
        }
      });
    },
    confirmarEliminacionReporte(id) {
      Swal.fire({
        title: "¿Estás seguro de eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          EnvioClienteService.deleteEnvioCliente(id);
          this.refreshData();
        }
      });
    },
    agregarTarea() {
      // Agrega una nueva tarea vacía al array de tareas
      this.tareas.push({
        asignacion_tareas_proyectos: "",
        fecha_inicio: "",
        dias: 0,
        fecha_fin_programado: "",
      });
    },
    eliminarTarea(index) {
      // Elimina la tarea en el índice especificado
      this.tareas.splice(index, 1);
    },
    updateFechaFinProgramado2(index) {
      const tarea = this.tareas[index];

      // Verificar que hay una fecha de inicio válida y días mayores a 0
      if (tarea.fecha_inicio && tarea.dias > 0) {
        console.log(tarea.fecha_inicio); // Imprime la fecha original
        let fechaInicio = new Date(`${tarea.fecha_inicio}T00:00:00`); // Fuerza a UTC
        console.log(fechaInicio); // Imprime en UTC

        // Función para formatear la fecha y obtener MM-DD
        const formatFecha = (fecha) => {
          const mes = String(fecha.getMonth() + 1).padStart(2, "0"); // Obtener mes
          const dia = String(fecha.getDate()).padStart(2, "0"); // Obtener día
          return `${mes}-${dia}`;
        };

        // Función para verificar si la fecha es un feriado
        const esFeriado = (fecha) =>
          this.feriadosPeru.includes(formatFecha(fecha));

        let diasRestantes = tarea.dias;

        // Iterar día por día sumando solo los días hábiles (no feriados)
        while (diasRestantes > 0) {
          fechaInicio.setDate(fechaInicio.getDate() + 1); // Sumar un día

          // Si la nueva fecha no es feriado, restamos un día de los días restantes
          if (!esFeriado(fechaInicio)) {
            diasRestantes--;
          }
          // Si es feriado, continuamos al siguiente día
        }

        // Actualizar la fecha de fin programado
        tarea.fecha_fin_programado = fechaInicio.toISOString().slice(0, 10);
      }
    },
    updateDescuento() {
      const selectedMotivo = this.motivos.find(
        (item) => item.motivo === this.newTarea.motivo
      );
      if (selectedMotivo) {
        this.newRendimientoTrabajador.descuento = selectedMotivo.descuento;
      } else {
        this.newRendimientoTrabajador.descuento = 0; // Valor por defecto si no hay motivo seleccionado
      }
    },

    async createTarea() {
      try {
        console.log("Tareas guardadas:", this.tareas);
        console.log("Formulario nuevas tareas:", this.newTarea);

        for (let i = 0; i < this.tareas.length; i++) {
          const id_asignacion = Number(
            this.tareas[i].asignacion_tareas_proyectos
          );

          const asignacion_seleccionada = this.asignaciones.filter(
            (asignacion) => asignacion.id === id_asignacion
          );
          this.newTarea.asignacion_tareas_proyectos =
            asignacion_seleccionada[0].nombre_asignacion;
          this.newTarea.valor_final = asignacion_seleccionada[0].porcentaje;
          this.newTarea.dias = this.tareas[i].dias;
          this.newTarea.fecha_fin_programado =
            this.tareas[i].fecha_fin_programado;
          this.newTarea.fecha_inicio = this.tareas[i].fecha_inicio;
          await TareaProyectoService.createTareaProyecto(this.newTarea);
        }

        this.$swal({
          icon: "success",
          title: "¡Tarea Creada!",
          text: "La tarea ha sido creada exitosamente.",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error creando la tarea:", error);

        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al crear la tarea. Por favor, intenta nuevamente.",
        });
      }
    },
  },
};
</script>

<style scoped>
.lead-details-container {
  background-color: white;
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}

.table-column-cuota {
  width: 5%; /* 5% de la tabla */
}
.table-column-fecha {
  width: 20%; /* 20% de la tabla */
}
.table-column-porcentaje {
  width: 15%; /* 15% de la tabla */
}
.table-column-estado {
  width: 25%; /* 25% de la tabla */
}
</style>