import apiClient from '../axios';

export default {

    getPersonas() {
        return apiClient.get('/personas');
    },

    getPersona(id) {
        return apiClient.get(`/personas/${id}`)
            .then(response => {
                if (response && response.data) {
                    return response.data;
                } else {
                    throw new Error('No se encontraron datos de la persona');
                }
            })
            .catch(error => {
                console.error('Error al obtener a la persona', error);
                throw error;
            });
    },

    // Método para crear una nueva persona
    createPersona(personaData) {
        return apiClient.post('/personas', personaData);
    },

    //Metodo para actualizar
    updatePersona(id, personaData) {
        return apiClient.put(`/personas/${id}`, personaData);
    }

    
};

