<template>
  <p :class="getBackgroundClass(text)">
    {{ text }}
  </p>
</template>
  
  <script>
export default {
  name: "ColoredText",
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    getBackgroundClass(text) {
      switch (text?.trim().toLowerCase()) {
        case "no pagado":
          return "bg-no-pagado";
        case "pagado":
          return "bg-pagado";
        case "prorroga":
          return "bg-prorroga";
        case "fuera de plazo":
          return "bg-fuera-plazo";
        case "desistido":
          return "bg-desistido";
        default:
          return "bg-default";
      }
    },
  },
};
</script>
  
  <style scoped>
.bg-no-pagado {
  background-color: #4572c5;
  color: white;
}
.bg-pagado {
  background-color: #92d14f;
  color: black;
}
.bg-prorroga {
  background-color: #ffff00;
  color: black;
}
.bg-fuera-plazo {
  background-color: #fe0000;
  color: white;
}
.bg-desistido {
  background-color: #565656;
  color: white;
}
</style>

  