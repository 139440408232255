<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h4>Detalles del Lead</h4>
          <BackButton text="Listar Leads" />
        </div>

        <div class="card-body">
          <div v-if="lead && lead.persona" class="lead-details-container">
            <p><strong>Código:</strong> {{ formatCodeLead(lead.id) }}</p>
            <p>
              <strong>Fecha de Creación:</strong>
              {{ formatDate(lead.created_at) }}
            </p>
            <p>
              <strong>Nombre y Apellidos:</strong>
              {{ lead.persona.nombres_completos_persona || "No disponible" }}
            </p>
            <p>
              <strong>Estado del Lead:</strong>
              {{ lead.estado_lead || "No disponible" }}
            </p>
            <p>
              <strong>Celular:</strong>
              {{ lead.persona.celular_persona || "No disponible" }}
            </p>
            <p>
              <strong>Email:</strong>
              {{ lead.persona.email_persona || "No disponible" }}
            </p>
            <p>
              <strong>Departamento:</strong>
              {{ lead.persona.departamento_persona || "No disponible" }}
            </p>
            <p><strong>Medio:</strong> {{ lead.medio_contacto }}</p>
          </div>

          <div v-else>
            <p>Cargando detalles del lead...</p>
          </div>
          <div class="card-body">
            <!-- Abrir modal de creación de Tarea -->
            <button
              @click="openCreateModal"
              class="btn btn-primary btn-lg me-4"
            >
              Nueva Tarea
            </button>

            <!-- Abrir modal de creación de Cotizacion -->
            <button
              @click="openCreateModalCotizacion"
              class="btn btn-primary btn-lg"
            >
              Nueva Cotizacion
            </button>
          </div>

          <!-- Tabla de seguimientos -->
          <div class="table-responsive">
            <table
              ref="itemtabla"
              class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
              v-if="tareasLead"
            >
              <caption>
                Seguimiento de Tareas
              </caption>
              <thead class="text-center">
                <tr>
                  <th>Creacion</th>
                  <th>Tarea</th>
                  <th>Fecha programada</th>
                  <th>Estado</th>
                  <th>Observación</th>
                  <th>Cotizacion</th>
                  <th>Responsable</th>
                  <th>Opciones</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="seguimiento in tareasLead" :key="seguimiento.id">
                  <td>{{ formatDate(seguimiento.created_at) }}</td>
                  <td>{{ seguimiento.tarea }}</td>
                  <td>{{ formatDate2(seguimiento.fecha_programada) }}</td>
                  <td>
                    <EstadoColor
                      :text="seguimiento.estado_tarea"
                      style="border-radius: 16px"
                    />
                  </td>
                  <td>{{ seguimiento.observacion || "-" }}</td>
                  <td>
                    <router-link
                      v-if="seguimiento.id_cotizaciones"
                      :to="`/cotizaciones/${seguimiento.id_cotizaciones}`"
                    >
                      {{ formatCotizacion(seguimiento.id_cotizaciones) }}
                    </router-link>
                    <span v-else>Sin cotización</span>
                  </td>
                  <td>
                    {{
                      seguimiento.empleado?.persona
                        ?.nombres_completos_persona || "Sin responsable"
                    }}
                  </td>

                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="openEditModal(seguimiento)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(seguimiento.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- Mensaje cuando no hay seguimientos -->
          <p v-if="lead && lead.seguimientos && lead.seguimientos.length === 0">
            No hay seguimientos disponibles.
          </p>
        </div>
      </div>
    </div>

    <!-- Modal para crear nuevas Tareas -->
    <Modal :isVisible="showCreateSeguimientoModal" @close="closeModal">
      <div>
        <h4>Datos de la nueva Tarea</h4>
        <form @submit.prevent="createSeguimiento">
          <!-- Tarea -->
          <div>
            <label for="tarea" class="form-label"> Tarea: </label>
            <input
              v-model="newSeguimiento.tarea"
              type="text"
              id="tarea"
              class="form-control"
            />
          </div>

          <!-- Estado de Tarea -->
          <div>
            <label for="estado_tarea" class="form-label">
              Estado de Tarea:
            </label>
            <div>
              <select
                v-model="newSeguimiento.estado_tarea"
                id="estado_tarea"
                class="form-control"
              >
                <option value="Sin iniciar">Sin iniciar</option>
                <option value="En proceso">En proceso</option>
                <option value="Observado">Observado</option>
                <option value="Terminado">Terminado</option>
              </select>
            </div>
          </div>

          <!-- Observacion -->
          <div>
            <label for="observacion" class="form-label"> Observacion: </label>
            <input
              v-model="newSeguimiento.observacion"
              type="text"
              id="observacion"
              class="form-control"
            />
          </div>

          <!-- Fecha programada -->
          <div>
            <label for="fecha_programada" class="form-label">
              Fecha Programada:
            </label>
            <input
              v-model="newSeguimiento.fecha_programada"
              type="date"
              id="fecha_programada"
              class="form-control"
            />
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary">Crear Tarea</button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para crear nuevas Cotizaciones -->
    <Modal :isVisible="showCreateCotizacionModal" @close="closeModal">
      <div>
        <h4>Datos de la Cotizacion</h4>
        <form @submit.prevent="createCotizacion">
          <div class="row">
            <div class="col">
              <h5>Cotizacion</h5>
              <!-- Servicio -->
              <div>
                <label for="servicio" class="form-label">Servicio:</label>
                <select
                  v-model="newCotizacion.servicio"
                  id="servicio"
                  class="form-control"
                >
                  <option value="">Seleccione una opción:</option>
                  <!-- Opciones dinámicas -->
                  <option
                    v-for="servicio in servicios"
                    :key="servicio.id"
                    :value="servicio.nombre_servicio"
                  >
                    {{ servicio.nombre_servicio }}
                  </option>
                </select>
              </div>

              <!-- Precio Real -->
              <div>
                <label for="precio_real" class="form-label">
                  Precio Real:
                </label>
                <input
                  v-model.number="newCotizacion.precio_real"
                  type="text"
                  id="precio_real"
                  class="form-control"
                />
              </div>
              <!-- Precio Asignado -->
              <div>
                <label for="precio_asignado" class="form-label">
                  Precio Asignado:
                </label>
                <input
                  v-model.number="newCotizacion.precio_asignado"
                  type="text"
                  id="precio_asignado"
                  class="form-control"
                />
              </div>
              <!-- Descuento -->
              <div>
                <label for="descuento" class="form-label"> Descuento: </label>
                <!-- Aquí usamos :value para mostrar el resultado del método -->
                <input
                  :value="descuentoCotizacion"
                  type="text"
                  id="descuento"
                  class="form-control"
                  readonly
                />
              </div>
              <!-- Numero de cuotas -->
              <div>
                <label for="numero_cuotas" class="form-label">
                  Numero de cuotas:
                </label>
                <input
                  v-model.number="newCotizacion.numero_cuotas"
                  type="text"
                  id="numero_cuotas"
                  class="form-control"
                />
              </div>

              <!-- Plazo -->
              <div>
                <label for="plazo" class="form-label"> Plazo: </label>
                <input
                  v-model.number="newCotizacion.plazo"
                  type="text"
                  id="plazo"
                  class="form-control"
                />
              </div>
              <!-- Primera fecha de pago -->
              <div>
                <label for="primera_fecha_pago" class="form-label">
                  Primera Fecha de Pago:
                </label>
                <input
                  v-model="newCotizacion.primera_fecha_pago"
                  type="date"
                  id="primera_fecha_pago"
                  class="form-control"
                />
              </div>

              <!-- Fecha Proyectada de Cierre -->
              <div>
                <label for="fecha_proyectada_cierre" class="form-label">
                  Fecha Proyectada de Cierre:
                </label>
                <input
                  :value="fechaProyectadaCierre"
                  ref="fechaProyectadaCierre"
                  type="date"
                  id="fecha_proyectada_cierre"
                  class="form-control"
                  readonly
                />
              </div>
              <!-- Estado de envio de cotizacion -->
              <div>
                <label for="estado_envio_cotizacion" class="form-label"
                  >Estado de Envio:</label
                >
                <select
                  v-model="newCotizacion.estado_envio_cotizacion"
                  id="estado_envio_cotizacion"
                  class="form-control"
                >
                  <option value="">Seleccione una opción:</option>
                  <option value="No enviado">No enviado</option>
                  <option value="Enviado por WhatsApp">
                    Enviado por WhatsApp
                  </option>
                  <option value="Enviado por Email">Enviado por Email</option>
                </select>
              </div>
              <!-- Fecha de Envio -->
              <div>
                <label for="fecha_envio" class="form-label">
                  Fecha de Envio:
                </label>
                <input
                  v-model="newCotizacion.fecha_envio"
                  type="date"
                  id="fecha_envio"
                  class="form-control"
                />
              </div>
              <button
                type="button"
                @click="generarCronograma"
                class="btn btn-secondary mt-3"
              >
                Generar Cronograma
              </button>
            </div>

            <!-- Columna para cronograma -->
            <div class="col">
              <h5>Cronograma</h5>
              <!-- Grupos de cronogramas generados dinámicamente -->
              <div v-for="(cronograma, index) in newCronograma" :key="index">
                <h6>Cuota No {{ cronograma.cuota }}</h6>

                <div>
                  <label for="fecha_programada">Fecha Programada:</label>
                  <input
                    v-model="cronograma.fecha_programada"
                    type="date"
                    class="form-control"
                  />
                </div>
                <div class="row">
                  <div class="col">
                    <label for="porcentaje_asignado">Porcentaje:</label>
                    <div class="input-group">
                      <input
                        v-model.number="cronograma.porcentaje_asignado"
                        type="number"
                        class="form-control"
                        step="0.01"
                        min="0"
                      />
                      <span class="input-group-text">%</span>
                    </div>
                  </div>
                  <div class="col">
                    <label for="monto_pagar">Monto a Pagar:</label>
                    <input
                      v-model.number="cronograma.monto_pagar"
                      type="number"
                      class="form-control"
                      step="0.01"
                      min="0"
                      readonly
                    />
                  </div>
                </div>

                <div>
                  <label for="estado_cronograma">Estado del Cronograma:</label>
                  <input
                    v-model="cronograma.estado_cronograma"
                    type="text"
                    class="form-control"
                    readonly
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button type="submit" class="btn btn-primary">
              Crear Cotizacion
            </button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para modificar Tareas -->
    <Modal :isVisible="showUpdateSeguimientoModal" @close="closeModal">
      <div>
        <h4>Datos de la nueva Tarea</h4>
        <form @submit.prevent="updateSeguimiento">
          <!-- Tarea -->
          <div>
            <label for="tarea" class="form-label"> Tarea: </label>
            <input
              v-model="newSeguimiento.tarea"
              type="text"
              id="tarea"
              class="form-control"
            />
          </div>

          <!-- Estado de Tarea -->
          <div>
            <label for="estado_tarea" class="form-label">
              Estado de Tarea:
            </label>
            <div>
              <select
                v-model="newSeguimiento.estado_tarea"
                id="estado_tarea"
                class="form-control"
              >
                <option value="Sin iniciar">Sin iniciar</option>
                <option value="En proceso">En proceso</option>
                <option value="Observado">Observado</option>
                <option value="Terminado">Terminado</option>
              </select>
            </div>
          </div>

          <!-- Observacion -->
          <div>
            <label for="observacion" class="form-label"> Observacion: </label>
            <input
              v-model="newSeguimiento.observacion"
              type="text"
              id="observacion"
              class="form-control"
            />
          </div>

          <!-- Fecha programada -->
          <div>
            <label for="fecha_programada" class="form-label">
              Fecha Programada:
            </label>
            <input
              v-model="newSeguimiento.fecha_programada"
              type="date"
              id="fecha_programada"
              class="form-control"
            />
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary">
              Actualizar Tarea
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>
  
<script>
import LeadService from "../../services/LeadService";
import CotizacionService from "../../services/CotizacionService";
import BackButton from "./BackButton.vue";
import SeguimientoService from "../../services/SeguimientoService";
import EstadoColor from "./EstadoColor.vue";
import Modal from "./Modal.vue";
import $ from "jquery";
import Swal from "sweetalert2";
import CronogramaService from "../../services/CronogramaService";
import ServiciosService from "../../services/ServiciosService";

export default {
  name: "DetallesLead",
  components: {
    BackButton,
    Modal,
    EstadoColor,
  },
  data() {
    return {
      lead: null,
      showCreateSeguimientoModal: false,
      showUpdateSeguimientoModal: false,
      showCreateCotizacionModal: false,
      newSeguimiento: {
        id_leads: null,
        id_empleados: null,
        tarea: "",
        estado_tarea: "",
        fecha_programada: "",
      },
      newTareaCotizacion: {
        id_leads: null,
        id_empleados: null,
        tarea: "Cotizacion",
        estado_tarea: "En proceso",
        observacion: "Cotizacion Generada",
        fecha_programada: new Date().toLocaleDateString("en-CA", {
          timeZone: "America/Lima",
        }),
        fecha_registro: "",
      },
      newCotizacion: {
        fecha_envio: "",
        precio_real: 0,
        precio_asignado: 0,
        estado_envio_cotizacion: "",
        fecha_proyectada_cierre: "",
        numero_cuotas: 0,
        primera_fecha_pago: "",
        plazo: 0,
        servicio: "",
      },
      newCronograma: [],
      cronogramaObj: {},
      cronogramaJSON: "",
      servicios: [],
      tareasLead: [],
      feriadosPeru: [
        "01-01", // Año Nuevo
        "04-17", // Jueves Santo
        "04-18", // Viernes Santo
        "05-01", // Día del Trabajo
        "06-07", // Batalla de Arica y día de la bandera
        "06-29", // San Pedro y San Pablo
        "07-23", // Día de la Fuerza Aérea del Perú
        "07-28", // Fiestas Patrias
        "07-29", // Fiestas Patrias
        "08-06", // Batalla de Junín
        "08-30", // Santa Rosa de Lima
        "10-08", // Combate de Angamos
        "11-01", // Día de Todos los Santos
        "12-08", // Inmaculada Concepción
        "12-09", // Batalla de Ayacucho
        "12-25", // Navidad

        // Domingos desde el 13 de octubre de 2024 hasta el 12 de octubre de 2025
        "10-13",
        "10-20",
        "10-27",
        "11-03",
        "11-10",
        "11-17",
        "11-24",
        "12-01",
        "12-08",
        "12-15",
        "12-22",
        "12-29",
        "01-05",
        "01-12",
        "01-19",
        "01-26",
        "02-02",
        "02-09",
        "02-16",
        "02-23",
        "03-01",
        "03-08",
        "03-15",
        "03-22",
        "03-29",
        "04-05",
        "04-12",
        "04-19",
        "04-26",
        "05-03",
        "05-10",
        "05-17",
        "05-24",
        "05-31",
        "06-07",
        "06-14",
        "06-21",
        "06-28",
        "07-05",
        "07-12",
        "07-19",
        "07-26",
        "08-02",
        "08-09",
        "08-16",
        "08-23",
        "08-30",
        "09-06",
        "09-13",
      ],
    };
  },
  computed: {
    descuentoCotizacion() {
      const { precio_asignado, precio_real } = this.newCotizacion;
      if (!precio_real || !precio_asignado) {
        return "0%";
      }
      const descuento = (1 - precio_asignado / precio_real) * 100;
      return `${descuento.toFixed(2)}%`;
    },
    fechaProyectadaCierre() {
      const { primera_fecha_pago, plazo, numero_cuotas } = this.newCotizacion;

      if (!primera_fecha_pago || !plazo || !numero_cuotas) {
        return "";
      }

      const fechaPago = new Date(primera_fecha_pago);
      fechaPago.setDate(fechaPago.getDate() + plazo * numero_cuotas);

      const year = fechaPago.getFullYear();
      const month = String(fechaPago.getMonth() + 1).padStart(2, "0");
      const day = String(fechaPago.getDate()).padStart(2, "0");

      return `${year}-${month}-${day}`;
    },
  },
  watch: {
    newCronograma: {
      handler(newVal) {
        newVal.forEach((cronograma) => {
          // Si el porcentaje asignado es menor que 1, multiplicar por 100
          if (cronograma.porcentaje_asignado < 1) {
            cronograma.porcentaje_asignado *= 100;
          }
          // Calcular el monto a pagar
          cronograma.monto_pagar =
            (cronograma.porcentaje_asignado / 100) *
            this.newCotizacion.precio_asignado;
        });
      },
      deep: true,
    },
  },
  created() {
    this.fetchLeadDetails();
    this.fetchSeguimientos();
    this.dia();
  },
  methods: {
    dia() {
      new Date().toLocaleDateString("en-CA", {
        timeZone: "America/Lima",
      });
    },
    async fetchLeadDetails() {
      try {
        const leadId = this.$route.params.id;
        this.cargando = true;
        const response = await LeadService.getLead(leadId);
        this.lead = response.data;

        this.tareasLead = this.lead.seguimientos.filter(
          (tarea) => tarea.desactivado === 0
        );
        console.log('Hola mundo!!');
        
        console.log(this.tareasLead);

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 10,
          });
        });
      } catch (error) {
        console.error("Error fetching lead details:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los detalles del lead.",
        });
      }
    },
    async fetchSeguimientos() {
      try {
        const responseSeguimiento = await ServiciosService.getServicios();
        this.servicios = responseSeguimiento.data.data;
      } catch (error) {
        console.error("Error fetching lead details:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los detalles del lead.",
        });
      }
    },
    refreshData() {
      this.$router.go();
    },
    async createSeguimiento() {
      try {
        const id_leads = this.$route.params.id;
        this.newSeguimiento.id_leads = id_leads;
        const user = JSON.parse(localStorage.getItem("user"));
        this.newSeguimiento.id_empleados = user.empleado.id;
        await SeguimientoService.createSeguimiento(this.newSeguimiento);

        this.$swal({
          icon: "success",
          title: "Creación exitosa!",
          text: "Tarea creada!",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error en la creación",
        });
      }
    },
    async updateSeguimiento() {
      try {
        const id_leads = this.$route.params.id;
        this.newSeguimiento.id_leads = id_leads;

        await SeguimientoService.updateSeguimiento(
          this.selectedSeguimiento.id,
          this.newSeguimiento
        );

        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Lead actualizado!",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        if (error.response && error.response.data) {
          this.formErrors = error.response.data.errors;
        }
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar",
        });
      }
    },

    generarCronograma() {
      this.newCronograma = [];

      // Validar que haya una fecha de pago válida
      if (
        !this.newCotizacion.primera_fecha_pago ||
        isNaN(new Date(this.newCotizacion.primera_fecha_pago))
      ) {
        console.error("Fecha de pago no válida");
        return;
      }

      let fechaProgramada = new Date(this.newCotizacion.primera_fecha_pago);
      const intervaloDias = this.newCotizacion.plazo;

      // Función para convertir la fecha a la zona horaria de Perú (UTC-5)
      const convertirHoraPeru = (fecha) => {
        const utcFecha = fecha.getTime() + fecha.getTimezoneOffset() * 60000; // Convertir a UTC
        const horaPeru = new Date(utcFecha + -5 * 3600000); // Ajustar a UTC-5
        return horaPeru;
      };

      // Función para verificar si la fecha es un feriado (incluyendo domingos manualmente)
      const esFeriado = (fecha) => {
        const diaMes = fecha.toISOString().substring(5, 10); // Extraer MM-DD
        return this.feriadosPeru.includes(diaMes); // Verificar si la fecha está en la lista de feriadosPeru
      };

      // Función para ajustar la fecha si cae en feriado
      const ajustarFecha = (fecha) => {
        const fechaEnPeru = convertirHoraPeru(fecha);
        console.log(
          `Verificando fecha: ${fechaEnPeru.toISOString().substring(0, 10)}`
        ); // Log para depuración
        while (esFeriado(fechaEnPeru)) {
          console.log(
            `Ajustando fecha: ${fechaEnPeru
              .toISOString()
              .substring(0, 10)} es feriado o domingo`
          );
          fechaEnPeru.setDate(fechaEnPeru.getDate() + 1); // Mover al siguiente día hábil
        }
        return fechaEnPeru;
      };

      // Iterar sobre cada cuota y ajustar la fecha desde la cuota anterior
      for (let i = 1; i <= this.newCotizacion.numero_cuotas; i++) {
        // Ajustar la fecha si cae en feriado (incluidos domingos)
        const fechaAjustada = ajustarFecha(new Date(fechaProgramada));

        // Verificar que la fecha ajustada no sea inválida
        if (!isNaN(fechaAjustada.getTime())) {
          this.newCronograma.push({
            id_cotizaciones: "",
            cuota: i,
            fecha_programada: fechaAjustada.toISOString().substring(0, 10), // YYYY-MM-DD
            estado_cronograma: "No Pagado",
          });

          // Sumar el intervalo a la fecha ajustada para calcular la próxima cuota
          fechaProgramada = new Date(fechaAjustada);
          fechaProgramada.setDate(fechaProgramada.getDate() + intervaloDias); // Avanzar el plazo para la siguiente cuota
        } else {
          console.error(`Fecha programada no válida para la cuota ${i}`);
        }
      }

      this.cronogramaJSON = JSON.stringify(
        { cronogramas: this.newCronograma },
        null,
        2
      );
    },

    async createCotizacion() {
      try {
        const user = JSON.parse(localStorage.getItem("user"));
        const id_lead = this.$route.params.id;
        this.newTareaCotizacion.id_leads = id_lead;
        this.newTareaCotizacion.id_empleados = user.empleado.id;

        this.newCotizacion.fecha_proyectada_cierre =
          this.$refs.fechaProyectadaCierre.value;

        this.newCotizacion.id_empleados = user.empleado.id;

        const cotizacionResponse = await CotizacionService.createCotizacion(
          this.newCotizacion
        );

        const id_cotizacion = cotizacionResponse.data.data.id;
        this.newTareaCotizacion.id_cotizaciones = id_cotizacion;
        //Actualizar id_cotizacion
        const cronogramaObj = {
          cronogramas: this.newCronograma,
        };
        cronogramaObj.cronogramas = cronogramaObj.cronogramas.map(
          (cronograma) => ({
            ...cronograma,
            id_cotizaciones: id_cotizacion, // Asignar el ID de cotización generado
          })
        );
        this.cronogramaJSON = JSON.stringify(cronogramaObj, null, 2);

        await CronogramaService.createCronograma(this.cronogramaJSON);

        await SeguimientoService.createSeguimiento(this.newTareaCotizacion);

        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error al crear la cotización", error);
      }
    },

    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      };

      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },
    formatDate2(date) {
      if (!date) return "";
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima",
      };
      const fecha = new Date(`${date}T00:00:00`);
      if (isNaN(fecha)) return "";

      return new Intl.DateTimeFormat("es-PE", options).format(fecha);
    },
    formatCotizacion(id_cotizacion) {
      const formattedCode = id_cotizacion.toString().padStart(6, "0");
      return `COT${formattedCode}`;
    },

    formatCodeLead(codeLead) {
      const formattedCode = codeLead.toString().padStart(6, "0");
      return `L${formattedCode}`;
    },
    openEditModal(seguimiento) {
      this.selectedSeguimiento = seguimiento;
      if (seguimiento) {
        this.newSeguimiento = {
          tarea: seguimiento.tarea,
          estado_tarea: seguimiento.estado_tarea,
          observacion: seguimiento.observacion,
          fecha_programada: seguimiento.fecha_programada,
        };
      } else {
        this.newSeguimiento = {
          tarea: "",
          estado_tarea: "",
          observacion: "",
          fecha_programada: "",
        };
      }

      this.showUpdateSeguimientoModal = true;
    },
    openCreateModal() {
      this.showCreateSeguimientoModal = true;
    },
    openCreateModalCotizacion() {
      this.showCreateCotizacionModal = true;
    },
    closeModal() {
      this.showCreateSeguimientoModal = false;
      this.showUpdateSeguimientoModal = false;
      this.showCreateCotizacionModal = false;
      this.newSeguimiento = {
        tarea: "",
        estado_tarea: "",
        observacion: "",
        fecha_programada: "",
      };
      this.newCotizacion = {
        newCotizacion: {
          fecha_envio: "",
          precio_real: 0,
          precio_asignado: 0,
          estado_envio_cotizacion: "",
          fecha_proyectada_cierre: "",
          numero_cuotas: 0,
          primera_fecha_pago: "",
          plazo: 0,
          servicio: "",
        },
      };
    },
    async confirmarEliminacion(id) {
      console.log(id);
      const result = await Swal.fire({
        title: "¿Estás seguro de eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      });

      if (result.isConfirmed) {
        try {
          await SeguimientoService.deleteSeguimiento(id);
          this.refreshData();
        } catch (error) {
          console.error("Error al eliminar:", error);
          Swal.fire(
            "Error",
            "Hubo un problema al eliminar el seguimiento",
            "error"
          );
        }
      }
    },
  },
};
</script>

  
<style scoped>
.lead-details-container {
  background-color: white;
  width: 60%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
  