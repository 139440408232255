import apiClient from '../axios';

export default {

    getSeguimiento() {
        return apiClient.get('/seguimientos');
    },

    // Método para crear una nueva persona
    createSeguimiento(data) {
        return apiClient.post('/seguimientos', data);
    },

    //Metodo para actualizar
    updateSeguimiento(id, personaData) {
        return apiClient.put(`/seguimientos/${id}`, personaData);
    },

    //Metodo para eliminar
    deleteSeguimiento(id) {
        return apiClient.get(`/seguimientos/${id}/eliminar`);
    }
};
