<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Servicios</h4>
          <!-- Abrir modal de creación de Servicios -->
          <button @click="openCraeatedModal" class="btn btn-primary">
            Nuevo Servicio
          </button>
        </div>

        <div class="card-body">
          <!-- Tabla de servicios -->
          <div class="table-responsive">
            <table
              v-if="servicios.length > 0"
              ref="itemtabla"
              class="table table-borderless table-hover tabla-tamano table-fluid mt-3"
            >
              <caption>
                Servicios
              </caption>
              <thead class="text-center">
                <tr>
                  <th></th>
                  <th>Nombre</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="servicio in servicios" :key="servicio.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(servicio.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditModal(servicio)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(servicio.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ servicio.nombre_servicio || " - " }}</td>
                </tr>
              </tbody>
            </table>

            <!-- Mensaje cuando no hay servicios disponibles -->
            <p v-else class="text-center">
              No hay servicios disponibles para esta cotización.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para el formulario de modificar Servicio -->
    <Modal :isVisible="showModalEditServicio" @close="closeModal">
      <div>
        <h4>Editar Servicio</h4>

        <form @submit.prevent="updateServicio">
          <!-- Nombre del Servicio -->
          <div>
            <label for="nombre_servicio" class="form-label"
              >Nombre del Servicio:</label
            >
            <select
              v-model="newServicio.nombre_servicio"
              id="nombre_servicio"
              class="form-control"
            >
              <option value="Asesoria">Asesoria</option>
              <option value="Proyecto + Informe">Proyecto + Informe</option>
              <option value="Procesamiento y analisis de datos">
                Procesamiento y analisis de datos
              </option>
              <option value="Proyecto">Proyecto</option>
              <option value="Informe">Informe</option>
              <option value="Trabajo de Suficiencia Profesional">
                Trabajo de Suficiencia Profesional
              </option>
              <option value="Modificacion de Proyecto + Informe">
                Modificación de Proyecto + Informe
              </option>
              <option value="Parafraseo">Parafraseo</option>
              <option value="Plan de Negocios">Plan de Negocios</option>
              <option value="Proyecto de Inversion">
                Proyecto de Inversión
              </option>
              <option value="Estudio de Prefactibilidad">
                Estudio de Prefactibilidad
              </option>
              <option value="Propuesta">Propuesta</option>
              <option value="Revision Sistematica">Revisión Sistematica</option>
              <option value="Tesina">Tesina</option>
              <option value="Articulo Cientifico">Artículo Científico</option>
              <option value="Plan Estrategico">Plan Estratégico</option>
              <option value="Monografia">Monografía</option>
              <option value="Trabajo de Investigacion">
                Trabajo de Investigación
              </option>
              <option value="Otros">Otros</option>
              <option value="Plan de Tesis">Plan de Tesis</option>
              <option value="Simulacion de datos">Simulación de datos</option>
              <option value="Articulo">Artículo</option>
              <option value="Articulo Original">Artículo Original</option>
              <option value="Articulo de Revision">Artículo de Revisión</option>
              <option value="Trabajo Academico">Trabajo Académico</option>
            </select>
          </div>

          <!-- Botón de Actualizar -->
          <div class="mt-4">
            <button type="submit" class="btn btn-primary">
              Actualizar Servicio
            </button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para el formulario de crear Servicio -->
    <Modal :isVisible="showModalCreateServicio" @close="closeModal">
      <div>
        <h4>Crear Nuevo Servicio</h4>

        <form @submit.prevent="createServicio">
          <!-- Nombre del Servicio -->
          <div>
            <label for="nombre_servicio" class="form-label"
              >Nombre del Servicio:</label
            >
            <input
              v-model="newServicio.nombre_servicio"
              type="text"
              id="nombre_servicio"
              class="form-control"
            />
          </div>

          <!-- Botón de Crear -->
          <div class="mt-4">
            <button type="submit" class="btn btn-success">
              Crear Servicio
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>

<script>
import ServiciosService from "../../services/ServiciosService";
import Modal from "./Modal.vue";
import $ from "jquery";
import Swal from "sweetalert2";

export default {
  components: {
    Modal,
  },
  data() {
    return {
      cargando: false,
      showModalEditServicio: false,
      showModalCreateServicio: false,
      selectedServicio: {},
      newServicio: {
        nombre_servicio: "",
      },
      servicios: [],
    };
  },
  created() {
    this.fetchServicios();
  },
  methods: {
    async fetchServicios() {
      try {
        this.cargando = true;
        const servicios = await ServiciosService.getServicios();
        this.servicios = servicios.data.data;
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 20, // Mostrar 20 filas por página
          });
        });
      } catch (error) {
        this.formErrors = error.response.data.errors;
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Leads.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async createServicio() {
      try {
        const servicioResponse = await ServiciosService.createServicio(
          this.newServicio
        );
        console.log(servicioResponse);
        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al guardar",
        });
      }
    },
    async updateServicio() {
      try {
        const servicioResponse = await ServiciosService.updateServicio(
          this.selectedServicio.id,
          this.newServicio
        );
        console.log(servicioResponse);
        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Lead actualizado!!",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al actualizar",
        });
      }
    },
    openCraeatedModal() {
      this.showModalCreateServicio = true;
    },
    openEditModal(servicio) {
      this.selectedServicio = servicio;
      (this.newServicio = {
        nombre_servicio: servicio.nombre_servicio,
      }),
        (this.showModalEditServicio = true);
    },
    closeModal() {
      this.showModalEditServicio = false;
      this.showModalCreateServicio = false;
      this.newServicio = {
        nombre_servicio: "",
      };
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesServicios", params: { id } });
    },
    refreshData() {
      this.$router.go();
    },

    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          ServiciosService.deleteServicio($id);
          this.refreshData();
        }
      });
    },
  },
};
</script>

<style scoped>
</style>
