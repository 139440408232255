<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Leads</h4>
          <!-- Abrir modal de creación de Lead -->
          <button @click="openCraeatedModal" class="btn btn-primary">
            Nuevo Lead
          </button>
        </div>
        <!-- Listado de Leads -->
        <div class="card-body">
          <div class="table-responsive">
            <table
              class="table table-borderless table-hover tabla-tamano table-fluid"
              ref="itemtabla"
            >
              <thead class="text-center">
                <tr>
                  <th scope="col"></th>
                  <th scope="col">Codigo</th>
                  <th scope="col">Fecha de Creación</th>
                  <th scope="col">Nombre y Apellidos</th>
                  <th scope="col">Estado Lead</th>
                  <th scope="col">Celular</th>
                  <th scope="col">E-mail</th>
                  <th scope="col">Departamento</th>
                  <th scope="col">Medio</th>
                  <th scope="col">Tarea</th>
                  <th scope="col">Estado</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="lead in leads" :key="lead.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="verDetalle(lead.id)"
                        class="btn btn-success"
                      >
                        <i class="far fa-eye" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="openEditLeadModal(lead)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(lead.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ formatCodeLead(lead.id) }}</td>
                  <td>{{ formatDate(lead.created_at) }}</td>

                  <!-- Comprobar si lead.persona está definido antes de acceder a las propiedades -->
                  <td>
                    {{
                      lead.persona
                        ? lead.persona.nombres_completos_persona
                        : "No disponible"
                    }}
                  </td>
                  <td>
                    {{ lead.persona ? lead.estado_lead : "No disponible" }}
                  </td>
                  <td>
                    {{
                      lead.persona
                        ? lead.persona.celular_persona
                        : "No disponible"
                    }}
                  </td>
                  <td>
                    {{
                      lead.persona
                        ? lead.persona.email_persona
                        : "No disponible"
                    }}
                  </td>
                  <td>
                    {{
                      lead.persona
                        ? lead.persona.departamento_persona
                        : "No disponible"
                    }}
                  </td>

                  <td>
                    <MedioColor
                      :text="lead.medio_contacto"
                      style="border-radius: 15px"
                    />
                  </td>

                  <!-- Comprobar si existen seguimientos antes de obtener la tarea -->
                  <td>
                    <div
                      v-if="lead.seguimientos && lead.seguimientos.length > 0"
                    >
                      {{ getLatestSeguimiento(lead.seguimientos).tarea || " " }}
                    </div>
                    <div v-else>-</div>
                  </td>

                  <!-- Comprobar si existen seguimientos antes de obtener el estado -->
                  <td>
                    <div
                      v-if="lead.seguimientos && lead.seguimientos.length > 0"
                    >
                      <EstadoColor
                        :text="
                          getLatestSeguimiento(lead.seguimientos).estado_tarea
                        "
                        style="border-radius: 15px"
                      />
                    </div>
                    <div v-else>-</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para el formulario de nuevo Lead -->
    <Modal :isVisible="showCreateLeadModal" @close="closeModal">
      <div>
        <h4>Datos Personales</h4>
        <form @submit.prevent="createPersonaYLead">
          <div>
            <label for="nombres_completos_persona" class="form-label"
              >Nombre Completo:</label
            >
            <input
              v-model="newPersona.nombres_completos_persona"
              type="text"
              id="nombres_completos_persona"
              class="form-control"
            />
            <div class="text-danger">
              {{ formErrors.nombres_completos_persona }}
            </div>
          </div>
          <div>
            <label for="celular_persona" class="form-label">Celular:</label>
            <input
              v-model="newPersona.celular_persona"
              type="text"
              id="celular_persona"
              class="form-control"
              maxlength="9"
            />
          </div>
          <div>
            <label for="email_persona" class="form-label">Email:</label>
            <input
              v-model="newPersona.email_persona"
              type="email"
              id="email_persona"
              class="form-control"
            />
          </div>
          <div>
            <label for="departamento_persona" class="form-label"
              >Departamento:</label
            >
            <input
              v-model="newPersona.departamento_persona"
              type="text"
              id="departamento_persona"
              class="form-control"
            />
          </div>
          <label for="medio_contacto" class="form-label"
            >Medio de Contacto:</label
          >
          <div>
            <select
              v-model="newLead.medio_contacto"
              id="medio_contacto"
              class="form-control"
            >
              <option value="WHATSAPP">WhatsApp</option>
              <option value="MESSENGER">Messenger</option>
              <option value="FACEBOOK">Facebook</option>
              <option value="TIKTOK">Tiktok</option>
              <option value="INSTAGRAM">Instagram</option>
              <option value="LLAMADA ENTRANTE">Llamada Entrante</option>
              <option value="BASE PROPIA">Base Propia</option>
              <option value="GMAIL">Gmail</option>
              <option value="RECOMENDACIONES">Recomendaciones</option>
              <option value="TRATO DIRECTO">Trato Directo</option>
              <option value="OTRO">Otro</option>
            </select>
          </div>
          <!-- Estado del Lead -->
          <label for="estado_lead" class="form-label">Estado del Lead:</label>
          <div>
            <select
              v-model="newLead.estado_lead"
              id="estado_lead"
              class="form-control"
            >
              <option value="Nuevo">Nuevo</option>
              <option value="Analizara propuesta">Analizara propuesta</option>
              <option value="No responde">No responde</option>
              <option value="Numero no existe">Numero no existe</option>
              <option value="Venta">Venta</option>
              <option value="Carrera no disponible">
                Carrera no disponible
              </option>
              <option value="Otros">Otros</option>
            </select>
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary">Crear</button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para el formulario de modificar Lead -->
    <Modal :isVisible="showModalEditLead" @close="closeModal">
      <div>
        <h4>Editar Datos</h4>

        <form @submit.prevent="updatePersonaYLead">
          <!-- Nombre Completo -->
          <div>
            <label for="nombres_completos_persona" class="form-label"
              >Nombre Completo:</label
            >
            <input
              v-model="newPersona.nombres_completos_persona"
              type="text"
              id="nombres_completos_persona"
              class="form-control"
            />
          </div>

          <!-- Celular -->
          <div>
            <label for="celular_persona" class="form-label">Celular:</label>
            <input
              v-model="newPersona.celular_persona"
              type="text"
              id="celular_persona"
              class="form-control"
            />
          </div>

          <!-- Email -->
          <div>
            <label for="email_persona" class="form-label">Email:</label>
            <input
              v-model="newPersona.email_persona"
              type="email"
              id="email_persona"
              class="form-control"
            />
          </div>

          <!-- Departamento -->
          <div>
            <label for="departamento_persona" class="form-label"
              >Departamento:</label
            >
            <input
              v-model="newPersona.departamento_persona"
              type="text"
              id="departamento_persona"
              class="form-control"
            />
          </div>

          <!-- Medio de Contacto -->
          <div>
            <label for="medio_contacto" class="form-label"
              >Medio de Contacto:</label
            >
            <select
              v-model="newLead.medio_contacto"
              id="medio_contacto"
              class="form-control"
            >
              <option value="WHATSAPP">WhatsApp</option>
              <option value="MESSENGER">Messenger</option>
              <option value="FACEBOOK">Facebook</option>
              <option value="TIKTOK">Tiktok</option>
              <option value="INSTAGRAM">Instagram</option>
              <option value="LLAMADA ENTRANTE">Llamada Entrante</option>
              <option value="BASE PROPIA">Base Propia</option>
              <option value="GMAIL">Gmail</option>
              <option value="RECOMENDACIONES">Recomendaciones</option>
              <option value="TRATO DIRECTO">Trato Directo</option>
              <option value="OTRO">Otro</option>
            </select>
          </div>
          <!-- Estado del Lead -->
          <label for="estado_lead" class="form-label">Estado del Lead:</label>
          <div>
            <select
              v-model="newLead.estado_lead"
              id="estado_lead"
              class="form-control"
            >
              <option value="Nuevo">Nuevo</option>
              <option value="Analizara propuesta">Analizara propuesta</option>
              <option value="No responde">No responde</option>
              <option value="Numero no existe">Numero no existe</option>
              <option value="Venta">Venta</option>
              <option value="Carrera no disponible">
                Carrera no disponible
              </option>
              <option value="Otros">Otros</option>
            </select>
          </div>

          <!-- Botón de Actualizar -->
          <div class="mt-4">
            <button type="submit" class="btn btn-primary">Actualizar</button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para Eliminar Lead -->
    <Modal :isVisible="showDeleteLeadModal" @close="closeModal">
      <div></div>
    </Modal>
  </div>
</template>
  
<script>
import LeadService from "../../services/LeadService";
import PersonaService from "../../services/PersonaService";
import SeguimientoService from "../../services/SeguimientoService";
import Modal from "./Modal.vue";
import MedioColor from "./MedioColor.vue";
import $ from "jquery";
import Swal from "sweetalert2";
import { obtenerFechaHoy } from "../../Functions/Utilies";
import EstadoColor from "./EstadoColor.vue";

export default {
  name: "Lead",
  components: {
    Modal,
    MedioColor,
    EstadoColor,
  },
  data() {
    return {
      nombre: "Los Leads",
      cargando: false,
      leads: [],
      selectedLead: null,
      showModalEditLead: false,
      showCreateLeadModal: false,
      showDeleteLeadModal: false,
      personaCreada: false,
      formErrors: {},
      newPersona: {
        nombres_completos_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
      },
      newLead: {
        id_personas: null,
        medio_contacto: "",
        estado_lead: "",
      },
      newSeguimiento: {
        id_leads: null,
        id_empleados: null,
        tarea: "Contactar",
        estado_tarea: "Sin iniciar",
        observacion: "Recién agregado",
        fecha_programada: "",
        fecha_registro: "",
      },
    };
  },
  created() {
    this.fetchLeads();
  },
  computed: {},
  methods: {
    // Fetch leads from the API
    async fetchLeads() {
      try {
        this.cargando = true;
        const user = JSON.parse(localStorage.getItem("user"));
        const response = await LeadService.getLeads();

        if (
          user.empleado.cargo === "Administrador" ||
          user.empleado.cargo === "Jefa Comercial"
        ) {
          this.leads = response.data.data;
        } else {
          this.leads = response.data.data.filter((lead) =>
            lead.seguimientos.some(
              (seguimiento) => seguimiento.id_empleados === user.empleado.id
            )
          );
        }

        this.$nextTick(() => {
          if (this.$refs.itemtabla) {
            if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
              $(this.$refs.itemtabla).DataTable().destroy();
            }
            this.dataTable = $(this.$refs.itemtabla).DataTable({
              order: [[0, "desc"]],
              pageLength: 20,
            });
          } else {
            console.error("Tabla no disponible");
          }
        });
      } catch (error) {
        this.formErrors = error.response.data.errors;
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Leads.",
        });
      } finally {
        this.cargando = false;
      }
    },

    refreshData() {
      this.$router.go();
    },

    // Abrir los modales
    openEditLeadModal(lead) {
      this.selectedLead = lead; // Asigna el lead seleccionado
      if (lead.persona) {
        this.newPersona = {
          nombres_completos_persona: lead.persona.nombres_completos_persona,
          celular_persona: lead.persona.celular_persona,
          email_persona: lead.persona.email_persona,
          departamento_persona: lead.persona.departamento_persona,
        };
      } else {
        this.newPersona = {
          nombres_completos_persona: "",
          celular_persona: "",
          email_persona: "",
          departamento_persona: "",
        };
      }
      this.newLead.medio_contacto = lead.medio_contacto;
      this.newLead.estado_lead = lead.estado_lead;
      this.showModalEditLead = true;
    },

    openCraeatedModal() {
      this.showCreateLeadModal = true;
    },

    openDeleteLeadModal() {
      this.showDeleteLeadModal = true;
    },

    // Close the modal
    closeModal() {
      this.showModalEditLead = false;
      this.showCreateLeadModal = false;
      this.showDeleteLeadModal = false;

      // Limpia los datos del formulario
      this.newPersona = {
        nombres_completos_persona: "",
        celular_persona: "",
        email_persona: "",
        departamento_persona: "",
      };
      this.newLead = {
        id_personas: null,
        medio_contacto: "",
        fecha_registro: null,
        fecha_programada: null,
      };
    },

    async createPersonaYLead() {
      try {
        const personaResponse = await PersonaService.createPersona(
          this.newPersona
        );
        this.newLead.id_personas = personaResponse.data.data.id;

        const leadResponse = await LeadService.createLead(this.newLead);
        const createdLead = leadResponse.data.data;
        const user = JSON.parse(localStorage.getItem("user"));

        this.newSeguimiento.id_leads = createdLead.id;
        this.newSeguimiento.id_empleados = user.empleado.id;
        this.newSeguimiento.fecha_programada = obtenerFechaHoy();

        await SeguimientoService.createSeguimiento(this.newSeguimiento);

        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        if (error.response && error.response.data) {
          this.formErrors = error.response.data.errors;
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al guardar",
          });
        } else {
          console.error("Error:", error);
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al guardar",
          });
        }
      }
    },
    async updatePersonaYLead() {
      try {
        const personaResponse = await PersonaService.updatePersona(
          this.selectedLead.persona.id,
          this.newPersona
        );
        this.newLead.id_personas = personaResponse.data.persona.id;

        await LeadService.updateLead(this.selectedLead.id, this.newLead);

        this.$swal({
          icon: "success",
          title: "¡Actualizado!",
          text: "Lead actualizado!!",
        });

        this.closeModal();
        this.refreshData();
      } catch (error) {
        if (error.response && error.response.data) {
          this.formErrors = error.response.data.errors;
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al actualizar",
          });
        } else {
          console.error("Error:", error);
          this.$swal({
            icon: "error",
            title: "Error",
            text: "Hubo un error al actualizar",
          });
        }
      }
    },

    // View lead details
    verDetalle(id) {
      this.$router.push({ name: "DetallesLead", params: { id } });
    },

    // Función para formatear la fecha en formato DD/MM/YYYY
    formatDate(date) {
      const options = {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        timeZone: "America/Lima", // Asegura que use la zona horaria de Lima
      };
      return new Intl.DateTimeFormat("es-PE", options).format(new Date(date));
    },

    // Función para formatear el código del lead
    formatCodeLead(codeLead) {
      const formattedCode = codeLead.toString().padStart(6, "0");
      return `L${formattedCode}`;
    },

    getLatestSeguimiento(seguimientos) {
      if (!seguimientos || seguimientos.length === 0) {
        return {};
      }
      return seguimientos.reduce((max, seguimiento) =>
        seguimiento.id > max.id ? seguimiento : max
      );
    },

    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          LeadService.deleteLead($id);
          this.refreshData();
        }
      });
    },
  },
};
</script>
  
<style scoped>
</style>
  