<template>
  <div>
    <div class="container">
      <div class="card">
        <div class="card-header">
          <h4>Detalle Empleado</h4>
          <BackButton text="Listar Empleados" />
        </div>
        <div class="card-body">
          <div
            v-if="empleado && empleado.persona"
            class="empleado-details-container"
          >
            <div class="row">
              <div class="col">
                <p>
                  <strong>Codigo Empleado:</strong>
                  {{ empleado.codigo_empleado + formatCode(empleado.id) }}
                </p>
                <p>
                  <strong>Nombre:</strong>
                  {{ empleado.persona.nombres_completos_persona }}
                </p>
                <p>
                  <strong>Cumpleaños:</strong> {{ empleado.persona.cumpleanos }}
                </p>
                <p><strong>Area:</strong> {{ empleado.area_empleados }}</p>
                <p><strong>Cargo:</strong> {{ empleado.cargo }}</p>
                <p>
                  <strong>Celular:</strong>
                  {{ empleado.persona.celular_persona }}
                </p>
                <p>
                  <strong>Correo:</strong> {{ empleado.persona.email_persona }}
                </p>
                <p><strong>Salario:</strong> {{ "S/." + empleado.salario }}</p>
                <p>
                  <strong>N° de Cuenta:</strong>
                  {{ empleado.numero_cuenta_bancaria_empleado }}
                </p>
                <p>
                  <strong>Sistema de Pension:</strong>
                  {{ empleado.sistema_pensiones_empleado }}
                </p>
                <p><strong>AFP:</strong> {{ empleado.afp_empleado }}</p>
              </div>
              <div class="col">
                <p>
                  <strong>Departamento:</strong>
                  {{ empleado.persona.departamento_persona }}
                </p>
                <p>
                  <strong>Provincia:</strong>
                  {{ empleado.persona.provincia_persona }}
                </p>
                <p>
                  <strong>Distrito:</strong>
                  {{ empleado.persona.distrito_persona }}
                </p>
                <p>
                  <strong>Dirección:</strong>
                  {{ empleado.persona.direccion_persona }}
                </p>
                <p>
                  <strong>Centro de Estudios:</strong>
                  {{ empleado.centro_estudios_empleado }}
                </p>
                <p>
                  <strong>Especialidad:</strong>
                  {{ empleado.profesion_empleado }}
                </p>
                <p>
                  <strong>Inicio de Contrato:</strong>
                  {{ empleado.inicio_contrato_empleado }}
                </p>
                <p>
                  <strong>Fin de Contrato:</strong>
                  {{ empleado.fin_contrato_empleado }}
                </p>
                <p>
                  <strong>Jornada Laboral:</strong>
                  {{ empleado.jornada_laboral_empleado }}
                </p>
                <p>
                  <strong>Modalidad de Contrato:</strong>
                  {{ empleado.modalidad_contrato_empleado }}
                </p>

                <p><strong>Status:</strong> {{ empleado.estado_emplreado }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import EmpleadoService from "../../services/EmpleadoService";
import BackButton from "./BackButton.vue";
//import PersonaService from "../../services/PersonaService";
import $ from "jquery";
//import Modal from "./Modal.vue";
import Swal from "sweetalert2";

export default {
  name: "Empleado",
  components: {
    BackButton,
    //Modal,
  },
  data() {
    return {
      nombre: "Empleado",
      cargando: false,
      empleado: null,
      showCreateModal: false,
      showEditModal: false,
      showDeleteModal: false,
      rendimientos: [],
    };
  },
  created() {
    this.fetchDetailsEmpleado();
  },
  methods: {
    async fetchDetailsEmpleado() {
      try {
        const empleadoId = this.$route.params.id;
        this.cargando = true;
        const response = await EmpleadoService.getEmpleado(empleadoId);
        this.empleado = response.data;

        // Asigna los datos a la propiedad
        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 20, // Mostrar 20 filas por página
          });
        });
      } catch (error) {
        console.error("Error fetching empleados:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los Empleados.",
        });
      } finally {
        this.cargando = false;
      }
    },
    refreshData() {
      this.$router.go();
    },

    openCreateModal() {
      this.showCreateModal = true;
    },
    openEditModal(empleado) {
      console.log(empleado);
    },
    closeModal() {
      this.showCreateModal = false;
      this.showEditModal = false;
    },
    formatCode(code) {
      const formattedCode = code.toString().padStart(6, "0");
      return `${formattedCode}`;
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          EmpleadoService.deleteEmpleado($id);
          this.refreshData();
        }
      });
    },
  },
};
</script>

<style lang="">
</style>