import apiClient from '../axios';

export default {
    //Metodo para retornar leads
    getLeads(){
        return apiClient.get('/leads')
    },

    //Metodo para retornar lead por id
    getLead(id) {
        return apiClient.get(`/leads/${id}`)
            .then(response => {
                if (response && response.data) {
                    return response.data;
                } else {
                    throw new Error('No se encontraron datos del lead');
                }
            })
            .catch(error => {
                console.error('Error al obtener el lead:', error);
                throw error;
            });
    },

    // Método para crear un nuevo Lead
    createLead(leadData) {
        return apiClient.post('/leads', leadData);
    },

    //Metodo para editar un Lead
    updateLead(id, leadData) {
        return apiClient.put(`/leads/${id}`, leadData);
    },

    //Metodo para eliminar un Lead
    deleteLead(id) {
        return apiClient.get(`/leads/${id}/eliminar`);
    }



    // updateLead(id, leadData) {
    //     return apiClient.post(`/leads/${id}`, leadData, {
    //         headers: {
    //             'Content-Type': 'multipart/form-data'
    //         }
    //     });
    // },
    // desactivarPremio(id) {
    //     return apiClient.put(`/premios/desactivar/${id}`);
    // },

// //Ejemplo
//   getPremios() {
//     return apiClient.get('/premios');
//   },
//   getPremiosActivos() {
//     return apiClient.get('/premios/activos');
//   },
//   getPremio(id) {
//     return apiClient.get(`/premios/${id}`);
//   },
//   createPremio(data) {
//     return apiClient.post('/premios', data, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });
//   },
//   updatePremio(id, data) {
//     return apiClient.post(`/premios/${id}`, data, {
//       headers: {
//         'Content-Type': 'multipart/form-data'
//       }
//     });
//   },
//   desactivarPremio(id) {
//     return apiClient.put(`/premios/desactivar/${id}`);
//   },
};