<template>
  <div class="calendar">
    <div class="header">
      <button @click="prevMonth" type="button" class="btn btn-outline-primary">
        Anterior
      </button>
      <h2>{{ monthYear }}</h2>
      <button @click="nextMonth" type="button" class="btn btn-outline-primary">
        Siguiente
      </button>
    </div>
    <table>
      <thead>
        <tr>
          <th v-for="day in days" :key="day">{{ day }}</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="week in weeks" :key="week">
          <td v-for="date in week" :key="date ? date.getTime() : null">
            <div class="date" v-if="date">{{ date.getDate() }}</div>

            <!-- Cuotas -->
            <div
              v-for="(activity, index) in getCobrosForDate(date)"
              :key="activity.cotizacion.venta.id"
              class="activity"
              :class="getVentaClass(activity.estado_cronograma)"
              @click="redirectToVentas(activity.cotizacion.venta.id)"
              @mouseover="showTooltip(index)"
              @mouseleave="hideTooltip"
            >
              <i class="fas fa-money-bill-wave" aria-hidden="true"></i>
              {{ formatCodeVenta(activity.cotizacion.venta.id) }}

              <!-- Tooltip al pasar el mouse -->
              <div v-if="tooltipIndex === index" class="tooltiptext">
                Nombre:
                {{
                  activity.cotizacion?.venta?.cliente?.persona
                    ?.nombres_completos_persona
                }}
                <br />
                Servicio: {{ activity.cotizacion.servicio }}
              </div>
            </div>

            <!-- Seguimiento Leads -->
            <div
              v-for="(seguimiento, index) in getSeguimientoForDate(date)"
              :key="seguimiento.lead.id"
              class="activity"
              :class="getSeguimientoClass(seguimiento.estado_tarea)"
              @click="redirectToLeads(seguimiento.lead.id)"
              @mouseover="showSeguimientoTooltip(index)"
              @mouseleave="hideSeguimientoTooltip"
            >
              <i class="ni ni-single-02 text-sm opacity-10"></i>
              {{ formatCodeLead(seguimiento.lead.id) }}

              <!-- Tooltip al pasar el mouse -->
              <div v-if="seguimientoTooltipIndex === index" class="tooltiptext">
                Lead: {{ seguimiento.lead.persona.nombres_completos_persona }}
                <br />
                Estado: {{ seguimiento.estado_tarea }}
                <br />
                Tarea:
                {{ seguimiento.tarea }}
              </div>
            </div>

            <!-- Tareas de Proyecto -->
            <div
              v-for="(tarea, index) in getTareasProyectoForDate(date)"
              :key="tarea.id"
              class="activity"
              :class="getTareaProyectoClass(tarea.estado_tareas_proyecto)"
              @click="
                redirectToProyecto(tarea.empleados_proyectos.id_proyectos)
              "
              @mouseover="showTareaTooltip(index)"
              @mouseleave="hideTareaTooltip"
            >
              <i class="fas fa-book text-sm"></i>
              {{ formatCodeProyecto(tarea.empleados_proyectos.id_proyectos) }}

              <!-- Tooltip al pasar el mouse -->
              <div v-if="tareaTooltipIndex === index" class="tooltiptext">
                Tarea: {{ tarea.asignacion_tareas_proyectos }}
                <br />
                Estado:{{ tarea.estado_tareas_proyecto }}
                <br />
                Fecha Termino:{{ tarea.fecha_fin_programado || "N/A" }}
              </div>
            </div>

            <!-- Fin de Contrato -->
            <div
              v-for="(finContratos, index) in getFinContratoForDate(date)"
              :key="finContratos.id"
              class="activity activity-yellow"
              @click="redirectToEmpleado(finContratos)"
              @mouseover="showTareaTooltip(index)"
              @mouseleave="hideTareaTooltip"
            >
              <i class="fas fa-file-contract text-sm"></i>
              {{ formatCodeEmpleado(finContratos) }}

              <!-- Tooltip al pasar el mouse -->
              <div v-if="tareaTooltipIndex === index" class="tooltiptext">
                Nombre: {{ finContratos.persona.nombres_completos_persona }}
                <br />
                Área:{{ finContratos.area_empleados }}
                <br />
                Cargo:{{ finContratos.cargo }}
                <br />
                Fecha Termino:{{ finContratos.fin_contrato_empleado || "N/A" }}
              </div>
            </div>

            <!-- Tareas de Envío -->
            <div
              v-for="(envio, index) in getEnviosClientesForDate(date)"
              :key="envio.id"
              class="activity"
              :class="getEnvioClass(envio.estado_reporte)"
              @click="redirectToProyecto(envio.id_proyecto)"
              @mouseover="showEnvioTooltip(index)"
              @mouseleave="hideEnvioTooltip"
            >
              <i class="fas fa-envelope text-sm"></i>
              {{ formatCodeProyecto(envio.id_proyecto) }}

              <div v-if="envioTooltipIndex === index" class="tooltiptext">
                Envío: {{ envio.asunto_correo || "N/A" }}
                <br />
                Estado:{{ envio.estado_reporte || "N/A" }}
                <br />
                Fecha:{{ envio.fecha_envio || "N/A" }}
              </div>
            </div>

            <!-- Cumpleaños -->
            <div
              v-for="cumple in getCumpleaniosForDate(date)"
              :key="cumple.id"
              class="activity cumpleanios"
              @click="mostrarCumpleanios(cumple)"
            >
              <i class="fa fa-birthday-cake" aria-hidden="true"></i>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- Modal para visualizar Cumpleañero -->
    <Modal :isVisible="showViewCumpleanieroModal" @close="closeModal">
      <div class="cumpleaniero-card">
        <h4>Feliz Cumpleaños!</h4>
        <div class="card-content">
          <div class="field">
            <strong>Nombre:</strong> {{ newCumpleaniero.nombre }}
          </div>
          <div class="field">
            <strong>Fecha de Cumpleaños:</strong>
            {{ formatFecha(newCumpleaniero.fecha) }}
          </div>
          <div class="field">
            <strong>Área:</strong> {{ newCumpleaniero.area }}
          </div>
        </div>
        <div class="mt-4">
          <button class="btn btn-secondary" @click="closeModal">Cerrar</button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>
import Modal from "./Modal.vue";

export default {
  props: {
    fechasPago: {
      type: Array,
    },
    seguimientoComercial: {
      type: Array,
    },
    tareasProyecto: {
      type: Array,
    },
    cumpleanios: {
      type: Array,
    },
    enviosCliente: {
      type: Array,
    },
    finContratos: {
      type: Array,
    },
  },
  components: {
    Modal,
  },
  data() {
    return {
      days: [
        "Domingo",
        "Lunes",
        "Martes",
        "Miercoles",
        "Jueves",
        "Viernes",
        "Sabado",
      ],
      currentDate: new Date(), // Fecha actual
      newCumpleaniero: {
        nombre: "",
        fecha: "",
        area: "",
      },
      showViewCumpleanieroModal: false,
      tooltipIndex: null,
      seguimientoTooltipIndex: null,
      tareaTooltipIndex: null,
      envioTooltipIndex: null,
    };
  },
  computed: {
    monthYear() {
      const options = { year: "numeric", month: "long" };
      const formattedDate = this.currentDate.toLocaleDateString(
        "es-ES",
        options
      );

      // Capitaliza la primera letra del mes
      return formattedDate.charAt(0).toUpperCase() + formattedDate.slice(1);
    },

    weeks() {
      const startOfMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        1
      );
      const endOfMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        0
      );
      const weeks = [];
      let week = [];

      // Ajustar el primer día para incluir días del mes anterior si no es domingo
      const firstDayOfWeek = startOfMonth.getDay(); // Día de la semana (0 = Domingo, 1 = Lunes, ...)
      const daysInPreviousMonth = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth(),
        0
      ).getDate();

      // Llenar la primera semana con días del mes anterior
      for (let i = firstDayOfWeek; i > 0; i--) {
        week.push(
          new Date(
            startOfMonth.getFullYear(),
            startOfMonth.getMonth() - 1,
            daysInPreviousMonth - i + 1
          )
        );
      }

      // Llenar el mes actual
      for (
        let day = startOfMonth;
        day <= endOfMonth;
        day.setDate(day.getDate() + 1)
      ) {
        if (week.length === 7) {
          weeks.push(week);
          week = [];
        }
        week.push(new Date(day)); // Crear una nueva instancia
      }

      // Agregar la última semana si queda algún día
      if (week.length) {
        weeks.push(week);
      }

      // Eliminar los días del siguiente mes
      const lastWeek = weeks[weeks.length - 1];
      while (lastWeek.length < 7) {
        lastWeek.push(null); // Rellena con null si no hay más días
      }

      // Devolver solo las fechas que pertenecen al mes actual
      return weeks.map((week) =>
        week.map((date) =>
          date && date.getMonth() === this.currentDate.getMonth() ? date : null
        )
      );
    },
  },
  methods: {
    getCobrosForDate(date) {
      // Filtra las actividades que coincidan con la fecha actual
      if (!date) return [];
      return this.fechasPago.filter(
        (activity) =>
          new Date(`${activity.fecha_programada}T00:00:00`).toDateString() ===
          date.toDateString()
      );
    },

    getSeguimientoForDate(date) {
      // Filtra las actividades que coincidan con la fecha actual
      if (!date) return [];
      return this.seguimientoComercial.filter(
        (seguimiento) =>
          new Date(
            `${seguimiento.fecha_programada}T00:00:00`
          ).toDateString() === date.toDateString()
      );
    },
    getTareasProyectoForDate(date) {
      // Filtra las actividades que coincidan con la fecha actual
      if (!date) return [];
      return this.tareasProyecto.filter(
        (tarea) =>
          new Date(`${tarea.fecha_fin_programado}T00:00:00`).toDateString() ===
          date.toDateString()
      );
    },
    getEnviosClientesForDate(date) {
      // Filtra las actividades que coincidan con la fecha actual
      if (!date) return [];
      return this.enviosCliente.filter(
        (envio) =>
          new Date(`${envio.fecha_envio}T00:00:00`).toDateString() ===
          date.toDateString()
      );
    },
    getCumpleaniosForDate(date) {
      // Filtra las actividades que coincidan en mes y día
      if (!date) return [];
      const monthDay = `${date.getMonth() + 1}-${date.getDate()}`; // Formato MM-DD

      return this.cumpleanios.filter((cumple) => {
        const cumpleMonthDay = `${
          new Date(`${cumple.cumpleanos}T00:00:00`).getMonth() + 1
        }-${new Date(`${cumple.cumpleanos}T00:00:00`).getDate()}`;
        return cumpleMonthDay === monthDay;
      });
    },

    getFinContratoForDate(date) {
      // Filtra las actividades que coincidan con la fecha actual
      if (!date) return [];
      return this.finContratos.filter(
        (fin) =>
          new Date(`${fin.fin_contrato_empleado}T00:00:00`).toDateString() ===
          date.toDateString()
      );
    },

    getVentaClass(estado) {
      switch (estado) {
        case "No Pagado":
          return "activity-red";
        case "Fuera de Plazo":
          return "activity-red";
        case "Pagado":
          return "activity-green";
        case "Prorroga":
          return "activity-red";
        case "Desistido":
          return "activity-black";
        default:
          return ""; // Clase por defecto si no coincide
      }
    },
    getSeguimientoClass(estado) {
      switch (estado) {
        case "Sin iniciar":
          return "activity-red";
        case "Observado":
          return "activity-red";
        case "Terminado":
          return "activity-green";
        case "En proceso":
          return "activity-red";
        default:
          return ""; // Clase por defecto si no coincide
      }
    },
    getEnvioClass(estado) {
      switch (estado) {
        case "Enviado":
          return "activity-green";
        case "Sin enviar":
          return "activity-red";
        default:
          return ""; // Clase por defecto si no coincide
      }
    },
    getTareaProyectoClass(estado) {
      switch (estado) {
        case "Sin iniciar":
          return "activity-red";
        case "Observado":
          return "activity-red";
        case "Terminado":
          return "activity-green";
        case "En proceso":
          return "activity-red";
        default:
          return ""; // Clase por defecto si no coincide
      }
    },
    mostrarCumpleanios(datos) {
      if (datos) {
        this.newCumpleaniero = {
          nombre: datos.nombres_completos_persona,
          fecha: datos.cumpleanos,
        };
        if (datos.cliente) {
          this.newCumpleaniero.area = "Cliente";
        } else {
          this.newCumpleaniero.area = datos.empleado.cargo;
        }
      }
      this.showViewCumpleanieroModal = true;
    },
    formatFecha(fecha) {
      const options = { year: "numeric", month: "long", day: "numeric" };
      return new Date(`${fecha}T00:00:00`).toLocaleDateString("es-PE", options);
    },
    formatCodeLead(codeLead) {
      const formattedCode = codeLead.toString().padStart(4, "0");
      return `L${formattedCode}`;
    },
    formatCodeVenta(codeVenta) {
      return `V${codeVenta.toString().padStart(4, "0")}`;
    },
    formatCodeProyecto(id_proyecto) {
      const formattedCode = id_proyecto.toString().padStart(4, "0");
      return `P${formattedCode}`;
    },
    formatCodeEmpleado(data) {
      if (
        !data ||
        typeof data.id === "undefined" ||
        typeof data.codigo_empleado === "undefined"
      ) {
        return ""; // Devuelve una cadena vacía si los datos son inválidos
      }
      const formattedCode = data.id.toString().padStart(4, "0");
      return `${data.codigo_empleado}${formattedCode}`;
    },

    closeModal() {
      this.showViewCumpleanieroModal = false;
    },
    redirectToVentas(id) {
      this.$router.push(`/ventas/${id}`);
    },

    redirectToLeads(id) {
      this.$router.push(`/leads/${id}`);
    },
    redirectToProyecto(id) {
      this.$router.push(`/proyectos/${id}`);
    },

    redirectToEmpleado(data) {
      if (data.area_empleados === "Ventas") {
        this.$router.push(`/area-comercial/${data.id}`);
      } else if (data.area_empleados === "Academico") {
        this.$router.push(`/area-academica/${data.id}`);
      } else {
        this.$router.push(`/empleados/${data.id}`);
      }
    },
    prevMonth() {
      // Retrocede un mes
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() - 1,
        1
      ); // Nueva instancia
    },
    nextMonth() {
      // Avanza un mes
      this.currentDate = new Date(
        this.currentDate.getFullYear(),
        this.currentDate.getMonth() + 1,
        1
      ); // Nueva instancia
    },
    showTooltip(index) {
      this.tooltipIndex = index; // Muestra el tooltip para el elemento actual
    },
    hideTooltip() {
      this.tooltipIndex = null; // Oculta el tooltip cuando se quita el mouse
    },
    showSeguimientoTooltip(index) {
      this.seguimientoTooltipIndex = index; // Muestra el tooltip para el seguimiento actual
    },
    hideSeguimientoTooltip() {
      this.seguimientoTooltipIndex = null; // Oculta el tooltip cuando se quita el mouse
    },
    showTareaTooltip(index) {
      this.tareaTooltipIndex = index; // Establece el índice de la tarea actual
    },
    hideTareaTooltip() {
      this.tareaTooltipIndex = null; // Oculta el tooltip
    },
    showEnvioTooltip(index) {
      this.envioTooltipIndex = index;
    },
    hideEnvioTooltip() {
      this.envioTooltipIndex = null;
    },
  },
};
</script>

<style scoped>
.calendar {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed; /* Asegura que todas las celdas tengan el mismo ancho */
}

th,
td {
  border: 1px solid #ddd;
  text-align: center;
  padding: 8px;
  width: 14.2857%; /* Ajuste para que haya 7 columnas */
}
td {
  height: 200px; /* Establece una altura uniforme para todas las celdas */
  align-content: baseline;
}

.date {
  font-weight: bold;
}

.activity {
  background-color: #f0f0f0;
  border-radius: 10px;
  margin: 5px 0;
  padding: 4px; /* Ajustado para más espacio en la actividad */
  cursor: pointer; /* Cambia el cursor para indicar que es clickeable */
  transition: background-color 0.3s; /* Transición suave al cambiar el color */
}

.activity:hover {
  background-color: #e0e0e0;
  color: black; /* Cambia el color al pasar el mouse */
}

.activity-red {
  background-color: red;
  color: white; /* Cambia el color del texto para mejor legibilidad */
}

.activity-green {
  background-color: green;
  color: white; /* Opcional */
}

.activity-yellow {
  background-color: yellow;
  color: black; /* Opcional */
}

.activity-black {
  background-color: black;
  color: white; /* Opcional */
}

.activity-blue {
  background-color: #4572c5;
  color: white; /* Opcional */
}

.fa-birthday-cake {
  font-size: 24px; /* Ajusta el tamaño del ícono */
  color: #f39c12; /* Cambia el color del ícono según tus preferencias */
}

.cumpleaniero-card {
  padding: 20px;
  border: 2px solid #f2b91e; /* Color dorado */
  background-color: #fff3cd; /* Fondo amarillo claro */
  border-radius: 10px;
  text-align: center;
}

.card-content {
  margin: 15px 0;
}

.field {
  margin: 10px 0;
  font-size: 1.2em;
}
.tooltiptext {
  visibility: hidden;
  width: 240px;
  background-color: black;
  color: #fff;
  text-align: start;
  border-radius: 12px;
  padding: 10px;
  position: absolute;
  z-index: 1;
}

.activity:hover .tooltiptext {
  visibility: visible; /* Muestra el tooltip al pasar el mouse */
}
</style>

