import apiClient from '../axios';

export default {
  // Método para iniciar sesión
  async login(credentials) {
    return apiClient.post('/login', credentials)
      .then(response => {
        if (response && response.data) {
          return response.data; // Retorna los datos de usuario y token
        } else {
          throw new Error('No se pudo iniciar sesión');
        }
      })
      .catch(error => {
        console.error('Error en el inicio de sesión', error);
        throw error;
      });
  },

  // Método para cerrar sesión
  async logout() {
    return apiClient.post('/logout', {}, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('authToken')}` // Asegúrate de que este token sea válido
      }
    })
    .then(response => {
      // Eliminar las claves de almacenamiento local
      localStorage.removeItem('authToken');
      localStorage.removeItem('user');
  
      return response.data; // Retorna el mensaje de éxito de logout
    })
    .catch(error => {
      console.error('Error al cerrar sesión', error);
      throw error;
    });
  },
  
  
  

  // Método para recuperar la contraseña
  async recuperarPassword(email) {
    return apiClient.post('/password/reset', { email })
      .then(response => {
        return response.data; // Retorna la confirmación del backend
      })
      .catch(error => {
        console.error('Error al recuperar contraseña', error);
        throw error;
      });
  }
};
