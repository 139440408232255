import apiClient from '../axios';

export default {

 getEnvioClientes(){
    return apiClient.get('/envios_clientes')
 },

async getEnvioCliente(id) {
   return apiClient.get(`/envios_clientes/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createEnvioCliente(reporteClienteData) {
   return apiClient.post('/envios_clientes', reporteClienteData);
},

//Editar
updateEnvioCliente(id, reporteClienteData) {
   return apiClient.put(`/envios_clientes/${id}`, reporteClienteData);
},
//eliminar
deleteEnvioCliente(id) {
   return apiClient.get(`/envios_clientes/${id}/eliminar`);
}
};