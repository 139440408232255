import apiClient from '../axios';

export default {

 getEmpleadosProyectos(){
    return apiClient.get('/empleados_proyectos')
 },

async getEmpleadoProyecto(id) {
   return apiClient.get(`/empleados_proyectos/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createEmpleadoProyecto(empleadoProyectoData) {
   return apiClient.post('/empleados_proyectos', empleadoProyectoData);
},

//Editar
updateEmpleadoProyecto(id, empleadoProyectoData) {
   return apiClient.put(`/empleados_proyectos/${id}`, empleadoProyectoData);
},
//eliminar
deleteEmpleadoProyecto(id) {
   return apiClient.get(`/empleados_proyectos/${id}/eliminar`);
}
};