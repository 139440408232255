import apiClient from '../axios';

export default {

 getRendimientoTrabajadores(){
    return apiClient.get('/rendimientos_trabajadores')
 },

async getRendimientoTrabajador(id) {
   return apiClient.get(`/rendimientos_trabajadores/${id}`)
       .then(response => {
           if (response && response.data) {
               return response.data;
           } else {
               throw new Error('No se encontraron datos.');
           }
       })
       .catch(error => {
           console.error('Error al obtenerlos datos:', error);
           throw error;
       });
},

 createRendimientoTrabajador(rendimientoTrabajadorData) {
   return apiClient.post('/rendimientos_trabajadores', rendimientoTrabajadorData);
},

//Editar
updateRendimientoTrabajador(id, rendimientoTrabajadorData) {
   return apiClient.patch(`/rendimientos_trabajadores/${id}`, rendimientoTrabajadorData);
},
//eliminar
deleteRendimientoTrabajador(id) {
   return apiClient.get(`/rendimientos_trabajadores/${id}/eliminar`);
}
};