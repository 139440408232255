import apiClient from '../axios';

export default {

    getClientes() {
        return apiClient.get('/clientes');
    },

    getCliente(id) {
        return apiClient.get(`/clientes/${id}`)
            .then(response => {
                if (response && response.data) {
                    return response.data;
                } else {
                    throw new Error('No se encontraron datos del cliente');
                }
            })
            .catch(error => {
                console.error('Error al obtener al cliente', error);
                throw error;
            });
    },

    // Método para crear un nuevo cliente
    createCliente(clienteData) {
        return apiClient.post('/clientes', clienteData);
    },

    //Metodo para actualizar
    updateCliente(id, clienteData) {
        return apiClient.put(`/clientes/${id}`, clienteData);
    }

    
};

