<template>
  <div>
    <div class="container-fluid">
      <div class="card">
        <div class="card-header">
          <h4>Detalles del Servicio</h4>
          <BackButton text="Listar Servicios" />
        </div>
        <div class="card-body">
          <div v-if="servicio" class="lead-details-container">
            <p>
              <strong>Nombre del Servicio:</strong>
              {{ servicio.nombre_servicio }}
            </p>
            <p>
              <strong>Peso total asignaciones:</strong>
              {{ totalPorcentaje }}%
            </p>
          </div>

          <div v-else>
            <p>Cargando detalles del lead...</p>
          </div>
          <!-- Abrir modal de creación de Asignaciones -->
          <button @click="openCraeatedModal" class="btn btn-primary">
            Nueva Asignacion
          </button>
        </div>
        <div class="card-body">
          <!-- Tabla de tareas -->
          <div class="table-responsive">
            <table
              ref="itemtabla"
              class="table table-light table-borderless table-hover mt-3"
              v-if="asignaciones.length > 0"
            >
              <caption>
                Asignaciones
              </caption>
              <thead class="text-center">
                <tr>
                  <th></th>
                  <th>Nombre de Asignacion</th>
                  <th>Porcentaje</th>
                </tr>
              </thead>
              <tbody class="text-center">
                <tr v-for="asignacion in asignaciones" :key="asignacion.id">
                  <td>
                    <div
                      class="btn-group"
                      role="group"
                      aria-label="botones-crud"
                    >
                      <button
                        @click="openEditModal(asignacion)"
                        class="btn btn-info"
                      >
                        <i class="far fa-edit" aria-hidden="true"></i>
                      </button>
                      <button
                        @click="confirmarEliminacion(asignacion.id)"
                        class="btn btn-danger"
                      >
                        <i class="far fa-trash-alt" aria-hidden="true"></i>
                      </button>
                    </div>
                  </td>
                  <td>{{ asignacion.nombre_asignacion }}</td>
                  <td>
                    {{
                      asignacion.porcentaje !== null &&
                      asignacion.porcentaje !== undefined
                        ? asignacion.porcentaje + "%"
                        : ""
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-center">
              No hay asignaciones disponibles para este servicio.
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal para crear nuevas Asignaciones -->
    <Modal :isVisible="showCreateAsignacionModal" @close="closeModal">
      <div>
        <h4>Datos de la nueva Asignación</h4>
        <form @submit.prevent="createAsignacion">
          <div class="row">
            <!-- Nombre de la Asignación -->
            <div class="col">
              <label for="nombre_asignacion" class="form-label"
                >Nombre de la Asignación:</label
              >
              <input
                v-model="newAsignacion.nombre_asignacion"
                type="text"
                id="nombre_asignacion"
                class="form-control"
                required
              />
            </div>
            <!-- Porcentaje de la Asignación -->
            <div class="col">
              <label for="porcentaje" class="form-label"
                >Porcentaje asignado:</label
              >
              <div class="input-group">
                <input
                  v-model.number="newAsignacion.porcentaje"
                  type="text"
                  id="porcentaje"
                  class="form-control"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary">
              Crear Asignación
            </button>
          </div>
        </form>
      </div>
    </Modal>

    <!-- Modal para editar Asignaciones -->
    <Modal :isVisible="showEditAsignacionModal" @close="closeModal">
      <div>
        <h4>Editar Asignación</h4>
        <form @submit.prevent="updateAsignacion">
          <div class="row">
            <!-- Nombre de la Asignación -->
            <div class="col">
              <label for="nombre_asignacion" class="form-label"
                >Nombre de la Asignación:</label
              >
              <input
                v-model="selectedAsignacion.nombre_asignacion"
                type="text"
                id="nombre_asignacion"
                class="form-control"
                required
              />
            </div>
            <!-- Porcentaje de la Asignación -->
            <div class="col">
              <label for="porcentaje" class="form-label"
                >Porcentaje asignado:</label
              >
              <div class="input-group">
                <input
                  v-model.number="selectedAsignacion.porcentaje"
                  type="text"
                  id="porcentaje"
                  class="form-control"
                />
                <span class="input-group-text">%</span>
              </div>
            </div>
          </div>

          <div class="mt-4">
            <button type="submit" class="btn btn-primary">
              Actualizar Asignación
            </button>
          </div>
        </form>
      </div>
    </Modal>
  </div>
</template>
  
<script>
import ServiciosService from "../../services/ServiciosService";
import $ from "jquery";
import BackButton from "./BackButton.vue";
import AsignacionService from "../../services/AsignacionService";
import Swal from "sweetalert2";
import Modal from "./Modal.vue";
export default {
  name: "DetallesServicios",
  components: {
    Modal,
    BackButton,
  },
  data() {
    return {
      cargando: false,
      servicio: {},
      asignaciones: [],
      showCreateAsignacionModal: false,
      showEditAsignacionModal: false,
      selectedAsignacion: {
        id: null,
        id_servicios: null,
        nombre_asignacion: "",
        porcentaje: 0.0,
      },
      newAsignacion: {
        id_servicios: null,
        nombre_asignacion: "",
        porcentaje: 0.0,
      },
    };
  },
  created() {
    this.fetchServicio();
    this.fetchAsignaciones();
  },

  computed: {
    totalPorcentaje() {
      return this.asignaciones.reduce((total, asignacion) => {
        return total + (Number(asignacion.porcentaje) || 0);
      }, 0);
    },
  },
  methods: {
    async fetchServicio() {
      try {
        this.cargando = true;
        const idServicio = this.$route.params.id;
        const servicioResponse = await ServiciosService.getServicio(idServicio);
        this.servicio = servicioResponse.data;
        console.log(this.servicio);
      } catch (error) {
        console.log(error);
      }
    },
    async fetchAsignaciones() {
      try {
        const id_servicio = this.$route.params.id;
        const asignacionesResponse = await AsignacionService.getAsignaciones();
        this.asignaciones = asignacionesResponse.data.data.filter(
          (asignacion) => asignacion.id_servicios == id_servicio
        );
        console.log(this.asignaciones);

        this.$nextTick(() => {
          if ($.fn.dataTable.isDataTable(this.$refs.itemtabla)) {
            $(this.$refs.itemtabla).DataTable().destroy();
          }
          this.dataTable = $(this.$refs.itemtabla).DataTable({
            order: [[0, "desc"]],
            pageLength: 10,
          });
        });
      } catch (error) {
        console.error("Error fetching datos:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un error al cargar los datos.",
        });
      } finally {
        this.cargando = false;
      }
    },
    async createAsignacion() {
      const id_servicio = this.$route.params.id;
      this.newAsignacion.id_servicios = id_servicio;
      console.log(this.newAsignacion);

      const responseAsignacion = await AsignacionService.createAsignacion(
        this.newAsignacion
      );
      console.log(responseAsignacion);
      this.$swal({
        icon: "success",
        title: "¡Éxito!",
        text: "Guardado correctamente",
      });
      this.closeModal();
      this.refreshData();
    },
    openCraeatedModal() {
      this.showCreateAsignacionModal = true;
    },
    openEditModal(asignacion) {
      this.selectedAsignacion = asignacion;
      this.selectedAsignacion = {
        id: asignacion.id,
        id_servicios: asignacion.id_servicios,
        nombre_asignacion: asignacion.nombre_asignacion,
        porcentaje: asignacion.porcentaje,
      };
      this.showEditAsignacionModal = true;
    },
    async updateAsignacion() {
      try {
        const response = await AsignacionService.updateAsignacion(
          this.selectedAsignacion.id,
          this.selectedAsignacion
        );
        console.log(response);

        this.$swal({
          icon: "success",
          title: "¡Éxito!",
          text: "Guardado correctamente",
        });
        this.closeModal();
        this.refreshData();
      } catch (error) {
        console.error("Error updating asignacion:", error);
        this.$swal({
          icon: "error",
          title: "Error",
          text: "Hubo un problema al actualizar la asignación.",
        });
      }
    },

    closeModal() {
      this.showCreateAsignacionModal = false;
      this.newAsignacion = {
        id_servicios: null,
        nombre_asignacion: "",
      };
      this.showEditAsignacionModal = false;
      this.selectedAsignacion = {
        id_servicios: null,
        nombre_asignacion: "",
      };
    },
    verDetalle(id) {
      this.$router.push({ name: "DetallesServicios", params: { id } });
    },
    refreshData() {
      this.$router.go();
    },
    confirmarEliminacion($id) {
      Swal.fire({
        title: "¿Estás de Eliminar?",
        text: "¡No podrás revertir esto!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sí, eliminar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          AsignacionService.deleteAsignacion($id);
          this.refreshData();
        }
      });
    },
  },
};
</script>

  
<style scoped>
.lead-details-container {
  background-color: white;
  width: 60%;
  border-radius: 10px;
  padding: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 20px 0;
}
caption {
  caption-side: top;
  font-size: large;
  font-weight: bold;
}
</style>
  